import React, { Component } from "react";
import { connect } from "react-redux";
// import { transactionActions } from "../actions";
import { userActions } from "../actions";
import { Link } from "react-router-dom";
// import { UserContent } from "../components";
import moment from "moment";
import { Row, Col, Label, FormGroup, Form, Badge } from "reactstrap";
import UserImage from "./userImage.jsx";

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { user: {} };

    if (this.props.users && this.props.users.length > 0) {
      var users = this.props.users.filter(el => {
        return el.id === this.props.match.params.id;
      });

      if (users.length > 0) {
        this.state = { user: users[0] };
      }
    } else if (this.props.user) {
      this.state = { user: this.props.user };
    } else {
      if (this.props.match.params && this.props.match.params.id) {
        const { dispatch } = this.props;
        dispatch(userActions.getUser(this.props.match.params.id));
      }
    }

    // this.handleOnClickRefund = this.handleOnClickRefund.bind(this);
    // this.handleOnClickCancel = this.handleOnClickCancel.bind(this);
    // this.handleOnClickComplete = this.handleOnClickComplete.bind(this);
  }

  componentWillUnmount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.users[0]) {
      this.setState({ user: nextProps.users[0] });
    }
  }

  render() {
    const { user } = this.state;
    if (!user.data) {
      return <div />;
    }

    var data = user.data;
    const timestamp = data.updatedAt;
    const formatted = moment(timestamp).format("LLLL");

    var role = "Common user";

    if (data.admin === true) {
      role = "admin";
    } else if (data.isVendor === true) {
      role = "Sponsor";
    }
    console.log(" datae urkl ->  ", data.imageURL);
    return (
      <div>
        <div className="header">
          <Row className="row-vin-padding12">
            <Col>
              <p>
                {data.imageURL && data.imageURL.indexOf("http") < 0 ? (
                  <UserImage
                    id={user.id}
                    height={120}
                    imageURL={data.imageURL}
                  />
                ) : (
                  <img
                    alt="..."
                    src={data.imageURL}
                    height="120px"
                    className="rounded-circle img-no-padding img-responsive"
                  />
                )}
              </p>
            </Col>
          </Row>
          <Row className="row-vin-padding12">
            <Col>
              <Label>{user.id}</Label>
            </Col>
          </Row>
          <Row className="row-vin-padding12">
            <Col>
              <Label>{formatted}</Label>
            </Col>
          </Row>
          <Row className="row-vin-padding12">
            <Col>
              {data.status != undefined && data.status == false && (
                <Badge color="danger">Suspended</Badge>
              )}
              {data.warning != undefined && data.warning > 0 && (
                <Badge color="warning">Warned</Badge>
              )}
            </Col>
          </Row>
        </div>

        <div className="content">
          <Form>
            <Row className="row-vin-padding12">
              <Col>
                <h5>Detail</h5>
              </Col>
            </Row>

            <Row className="row-vin-padding12">
              <Col>
                <FormGroup>
                  <Label>Display Name</Label>
                  <p className="form-control-static"> {data.displayName}</p>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Country</Label>
                  <p className="form-control-static"> {data.country}</p>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>TP</Label>
                  <p className="form-control-static">
                    {data.takePoint + data.freeTakePoint}
                  </p>
                </FormGroup>
              </Col>
            </Row>

            <Row className="row-vin-padding12">
              <Col>
                <FormGroup>
                  <Label>E-mail</Label>
                  <p className="form-control-static"> {data.email}</p>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Phone</Label>
                  <p className="form-control-static"> {data.phone}</p>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>Language</Label>
                  <p className="form-control-static">{data.lang}</p>
                </FormGroup>
              </Col>
            </Row>

            <Row className="row-vin-padding12">
              <Col>
                <FormGroup>
                  <Label>Invite Code</Label>
                  <p className="form-control-static"> {data.inviteCode}</p>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>IP</Label>
                  <p className="form-control-static"> {data.ip}</p>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>TP Logs</Label>
                  <p className="form-control-static">
                    <Link to={`/main/users/${user.id}/tpLogs`}>
                      <button className="btn btn-info btn-fill btn-wd pull-left">
                        Click here to see logs
                      </button>
                    </Link>
                  </p>
                </FormGroup>
              </Col>
            </Row>

            <Row className="row-vin-padding12">
              <Col>
                <FormGroup>
                  <Label>Role</Label>
                  <p className="form-control-static">{role}</p>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <Row className="row-vin-padding12">
            <Col>
              <Link to="/main/users">
                <button className="btn btn-info btn-fill btn-wd pull-left">
                  Back
                </button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { users } = state.users;
  return { users };
};
export default connect(mapStateToProps)(UserDetail);
