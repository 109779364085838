import { ecanOrderConstants } from '../constants';

const initialState =  {loading : false};

export function ecanOrder(state = initialState, action) {
  switch (action.type) {
    case ecanOrderConstants.GET_REQUEST:
      return {
        loading : true
      };
    case ecanOrderConstants.GET_SUCCESS:
      return {
        loading : false,
        order : action.ecanOrder
      };
    case ecanOrderConstants.GET_FAILURE:
      return {
        loading : false,
        order : {}

      };
    case ecanOrderConstants.UPDATE_REQUEST:
      return {
        loading : false,
        order : state.order
      };
    case ecanOrderConstants.UPDATE_SUCCESS:
      return {
        loading : false,
        order : action.order
      };
    case ecanOrderConstants.UPDATE_FAILURE:
      return {
        loading : false,
        order : state.order
      };

    default:
      return state
  }
}