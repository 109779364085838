import { authHeader } from "../helpers";
export const mailingService = {
  getAll,
  send,
  getJobs,
  cancelSchedule
};

export const SendType = {
  sendImediately: "sendImediately",
  testing: "testing",
  schedule: "schedule"
};

function getAll() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(host + "/api/admin/v1/officialMails", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function send(info, type) {
  var url;
  if (type == SendType.sendImediately) url = "/api/admin/v1/officialMail";
  else if (type == SendType.testing) url = "/api/admin/v1/officialMailTest";
  else if (type == SendType.schedule) {
    url = "/api/admin/v1/scheduleOfficialMail";
    info.sendAt = info.sendAt.toDate().getTime();
  } else return Promise.reject();

  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(info)
  };
  return fetch(host + url, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function getJobs() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(
    host + "/api/admin/v1/scheduledOfficialMails",
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function cancelSchedule(id) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(
    host + "/api/admin/v1/scheduleOfficialMail/" + id,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}
