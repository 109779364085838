import { authHeader } from "../helpers";
export const promoCodeService = {
  getAll,
  getUsers,
  get,
  createPromoCode
};

function getAll() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(host + "/api/admin/v1/promoCodes", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function get(id) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(host + "/api/admin/v1/promoCode/" + id, requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function getUsers(promoCodeId, country) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(
    host + `/api/admin/v1/promoCodeUsers/${promoCodeId}?country=${country}`,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function createPromoCode(code, promoCodeObj) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ code: code, info: promoCodeObj })
  };
  return fetch(host + "/api/admin/v1/promoCode", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return { result: "ok" };
    }
  );
}
