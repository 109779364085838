import React, { Component } from "react";
// import { render } from "react-dom";
import { connect } from "react-redux";
// import { transactionActions } from "../actions";
import { userActions } from "../actions";
// import { Link } from "react-router-dom";
import { UncontrolledTooltip, Row, Col, CardTitle } from "reactstrap";
import Utility from "../helpers/utility";
import UserInfo from "./userInfoItem.jsx";
import TransactionInfo from "./transactionInfo.jsx";

class UserTPLog extends Component {
  constructor(props) {
    super(props);
    this.handleCheckDetail = this.handleCheckDetail.bind(this);
    this.state = { expandedRows: [] };
    if (this.props.match.params && this.props.match.params.id) {
      const { dispatch } = this.props;
      dispatch(userActions.getUser(this.props.match.params.id));
      dispatch(userActions.getTPLogs(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logs) {
      this.setState({ logs: nextProps.logs });
    }

    if (this.props.match.params && this.props.match.params.id) {
      if (nextProps[this.props.match.params.id]) {
        this.setState({ user: nextProps[this.props.match.params.id] });
      }
    }
  }

  handleCheckDetail(e) {
    e.preventDefault();
    //onClick={this.handleCheckDetail}
  }

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  renderItem(item) {
    const clickCallback = () => this.handleRowClick(item.id);
    var tp =
      item.data.currentFreeTakePoint +
      item.data.currentTakePoint -
      (item.data.beforeFreeTakePoint + item.data.beforeTakePoint);

    const tpString = tp > 0 ? "+" + String(tp) : String(tp);

    const itemRows = [
      <tr onClick={clickCallback} key={"row-data-" + item.id}>
        <td>
          <i className="ti-arrow-circle-down" />{" "}
          {Utility.timestampToFormattedString(item.data.timestamp)}
        </td>
        <td>{item.data.action}</td>
        <td>{tpString}</td>
        <td id={item.id}>
          {item.data.currentFreeTakePoint + item.data.currentTakePoint}
          <UncontrolledTooltip placement="left" target={item.id}>
            <span>
              <label>Free TP :</label>
              {item.data.currentFreeTakePoint}
            </span>
            <br />
            <span>
              <label>Normal TP :</label>
              {item.data.currentTakePoint}
            </span>
          </UncontrolledTooltip>
        </td>
      </tr>
    ];
    if (this.state.expandedRows.includes(item.id)) {
      itemRows.push(
        <tr key={"row-expanded-" + item.id}>
          <td colSpan="4">
            <Row>
              {item.data.transaction && item.data.transaction.length > 0 && (
                <Col>
                  <TransactionInfo id={item.data.transaction} />
                </Col>
              )}
              {item.data.user && item.data.user.length > 0 && (
                <Col>
                  <UserInfo id={item.data.user} />
                </Col>
              )}
            </Row>
          </td>
        </tr>
      );
    }

    return itemRows;
  }

  render() {
    let allItemRows = [];

    if (this.state.logs) {
      this.state.logs.forEach(item => {
        const perItemRows = this.renderItem(item);
        allItemRows = allItemRows.concat(perItemRows);
      });
    }

    return (
      <div className="content table-responsive table-full-width">
        <Row>
          <Col sm="10" md="6">
            {this.state.user && (
              <>
                <CardTitle>
                  <img
                    alt="..."
                    src={this.state.user.data.imageURL}
                    height="60px"
                    className="rounded-circle img-no-padding img-responsive"
                  />
                  {this.state.user.data.displayName}'s TakePoint log
                </CardTitle>
              </>
            )}
          </Col>
          <Col md="4" />
        </Row>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Time</th>
              <th>Activity</th>
              <th>TP</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>{allItemRows}</tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const users = state.users;
  return users;
};
export default connect(mapStateToProps)(UserTPLog);
