import { promoCodeConstants } from "../constants";
import { promoCodeService } from "../services";
import { alertActions } from "./";
import { userActions } from "../actions";
// import { history } from "../helpers";
// import { banner } from "../reducers/bannerReducer";

export const promoCodeActions = {
  getAll,
  get,
  getUsers
};

function getAll() {
  return dispatch => {
    dispatch(request());
    promoCodeService.getAll().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: promoCodeConstants.GET_ALL_REQUEST };
  }
  function success(promoCodes) {
    return { type: promoCodeConstants.GET_ALL_SUCCESS, promoCodes };
  }
  function failure(error) {
    return { type: promoCodeConstants.GET_ALL_FAILURE, error };
  }
}

function get(id) {
  return dispatch => {
    dispatch(request());
    promoCodeService.get(id).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: promoCodeConstants.GET_REQUEST };
  }
  function success(promoCode) {
    return { type: promoCodeConstants.GET_SUCCESS, promoCode };
  }
  function failure(error) {
    return { type: promoCodeConstants.GET_FAILURE, error };
  }
}

function getUsers(promoCodeId, country) {
  return dispatch => {
    dispatch(request());
    console.log(" id ->  " + promoCodeId + ", country -> " + country);
    promoCodeService.getUsers(promoCodeId, country).then(
      result => {
        console.log(" getUsers result ->  ", result);
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: promoCodeConstants.GET_USERS_REQUEST };
  }
  function success(users) {
    return { type: promoCodeConstants.GET_USERS_SUCCESS, users };
  }
  function failure(error) {
    return { type: promoCodeConstants.GET_USERS_FAILURE, error };
  }
}
