import React, { Component } from "react";
import { connect } from "react-redux";
// import { transactionActions } from "../actions";
// import { userActions } from "../actions";
import { Link } from "react-router-dom";
import ExcelDownload from "../components/ExcelDownload.jsx";

//TRS1514732408280
class TransactionsTable extends Component {
  constructor(props) {
    super(props);
    this.handleCheckDetail = this.handleCheckDetail.bind(this);
  }
  handleCheckDetail(e) {
    e.preventDefault();
    //onClick={this.handleCheckDetail}
  }
  render() {
    return (
      <div className="table-responsive table-full-width">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>ID</th>
              <th>Item Name</th>
              <th>Method</th>
              <th>Country</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.transactions.map(el => (
              <tr key={el.data.numeric_id}>
                <td>{new Date(el.data.createdAt).toLocaleDateString()}</td>
                <td>{el.data.numeric_id}</td>
                <td>{el.data.item_title}</td>
                <td>{el.data.method}</td>
                <td>{el.data.country}</td>
                <td>{el.data.status}</td>
                <td>
                  <Link to={`/main/transactions/${el.data.numeric_id}`}>
                    <button className="btn btn-info btn-fill">Detail</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.props.transactions && this.props.transactions.length > 0 && (
          <ExcelDownload transactions={this.props.transactions} />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { transactions } = state.transactions;
  return {
    transactions
  };
};
export default connect(mapStateToProps)(TransactionsTable);
