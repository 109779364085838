import React, { Component } from "react";
import { render } from "react-dom";
import { sponsorActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Link } from "react-router-dom";
import ReactLoading from "react-loading";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Row,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledAlert
} from "reactstrap";

class AddressInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressInfo: JSON.parse(JSON.stringify(props.addressInfo)),
      updateEcanOrder: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.loading !== prevState.loading) {
      return { loading: nextProps.loading };
    } else if (nextProps.error !== prevState.error) {
      return { error: nextProps.error };
    } else return null;
  }

  handleDidClickSave = () => {
    this.setState({ error: null });
    var addressInfo = this.state.addressInfo;
    if (this.props.country == "TW") {
      var address =
        this.state.addressInfo.postCode +
        " " +
        this.state.addressInfo.state +
        this.state.addressInfo.locality +
        this.state.addressInfo.street;
      addressInfo.address = addressInfo.fullAddress = address;
    } else {
    }
    this.props.save(this.props.type, addressInfo, this.state.updateEcanOrder);
  };

  handleDidToggle = () => {
    this.props.toggle();
    this.setState({
      addressInfo: this.props.addressInfo,
      updateEcanOrder: false
    });
  };

  handleTextChange = e => {
    const { name, value } = e.target;
    var addressInfo = this.state.addressInfo;
    addressInfo[name] = value;
    this.setState({
      addressInfo: addressInfo
    });
  };

  handleCheckBoxChange = e => {
    const { name, value } = e.target;
    this.setState({
      updateEcanOrder: !this.state.updateEcanOrder
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.handleDidToggle}>
        {this.state.error && (
          <UncontrolledAlert color="warning" fade={false}>
            <span>
              <b>Warning - </b>
              {this.state.error}
            </span>
          </UncontrolledAlert>
        )}
        <ModalHeader toggle={this.editShippingInfoModal}>
          {this.props.title}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="fullName">Contact Name</Label>
            <Input
              type="text"
              name="fullName"
              id="contactName"
              placeholder="Enter Contact Name"
              value={this.state.addressInfo.fullName}
              minLength="2"
              onChange={this.handleTextChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Contact number</Label>
            <Input
              type="text"
              name="contactPhone"
              id="contactNumber"
              placeholder="Enter Contact Number"
              value={this.state.addressInfo.contactPhone}
              minLength="2"
              onChange={this.handleTextChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              {this.props.country == "TW"
                ? "Street (Including door number) Ex: 忠孝東路508號11樓之1"
                : "Street (Including door number) Ex: 201 N Brand Blvd."}
            </Label>
            <Input
              type="text"
              name="street"
              id="street"
              placeholder="Enter Contact Number"
              value={this.state.addressInfo.street}
              minLength="2"
              onChange={this.handleTextChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              {this.props.country == "TW"
                ? "District (Ex: 信義區)"
                : "Locality (Ex: Glendale)"}
            </Label>
            <Input
              type="text"
              name="locality"
              id="locality"
              placeholder="Enter Contact Number"
              value={this.state.addressInfo.locality}
              minLength="2"
              onChange={this.handleTextChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>PostCode</Label>
            <Input
              type="text"
              name="postCode"
              id="postCode"
              placeholder="Enter PostCode"
              value={this.state.addressInfo.postCode}
              minLength="2"
              onChange={this.handleTextChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              {this.props.country == "TW"
                ? "City (Ex: 屏東縣)"
                : "State (Ex: California)"}
            </Label>
            <Input
              type="text"
              name="state"
              id="state"
              placeholder={
                this.props.country == "TW" ? "Enter City" : "Enter State "
              }
              value={this.state.addressInfo.state}
              minLength="2"
              onChange={this.handleTextChange}
            />
          </FormGroup>
          {this.props.country == "TW" && (
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  value={this.state.updateEcanOrder}
                  onChange={this.handleCheckBoxChange}
                />{" "}
                Also update carrier order if any
                <span className="form-check-sign">
                  <span className="check" />
                </span>
              </Label>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleDidToggle}>
            Cancel
          </Button>

          <Button color="primary" onClick={this.handleDidClickSave}>
            {this.state.loading ? (
              <ReactLoading
                type="spinningBubbles"
                color="#eee"
                height={18}
                width={18}
              />
            ) : (
              "Save"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AddressInfoModal.contextTypes = {
  router: PropTypes.object.isRequired
};

export default AddressInfoModal;
