import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import firebase from "firebase/app";

class PromoCodeUserItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: props.displayName,
      uniqueID: props.uniqueID,
      inviteCode: props.inviteCode,
      id: props.key
    };
  }

  componentDidMount() {
    if (this.props.imageURL) {
      var storage = firebase.storage();
      storage
        .ref(this.props.imageURL)
        .getDownloadURL()
        .then(httpURL => {
          this.setState({ httpURL: httpURL });
        });
    }
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <>
        <tr key={this.state.id}>
          <td>
            <img
              alt="..."
              src={this.state.httpURL}
              height="60px"
              className="rounded-circle"
            />
          </td>
          <td>{this.state.displayName}</td>
          <td>{this.state.uniqueID}</td>
          <td>{this.state.inviteCode}</td>
        </tr>
      </>
    );
  }
}

PromoCodeUserItem.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(PromoCodeUserItem);
