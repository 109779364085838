import { authHeader } from "../helpers";
import firebase from "firebase";
export const sponsorService = {
  getAllApplications,
  getApplication,
  updateApplication,
  getAllSponsors,
  getSponsor,
  createSponsor,
  updateSponsor,
  downgradeSponsor,
  approvePendingInfo,
  handleUploadSuccess,
  handleUploadError,
  rejectPendingInfo,
  _getSponsorPosts,
  searchSponsor,
  getAllNPOs
};

function getAllApplications() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(host + "/api/admin/v1/sponsorApplications", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function getApplication(id) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(
    host + "/api/admin/v1/sponsorApplication/" + id,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function updateApplication(application, approved, role) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ application, approved, role })
  };
  return fetch(host + "/api/admin/v1/sponsorApplication", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return getApplication(application.data.numeric_id); //response.json();
    }
  );
}

function getAllNPOs() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(host + "/api/admin/v1/npos/", requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function getAllSponsors() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(host + "/api/admin/v1/sponsors/", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function getSponsor(id) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(host + "/api/admin/v1/sponsor/" + id, requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function createSponsor(data) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ data })
  };
  return fetch(host + "/api/admin/v1/sponsor", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

var uploadingPromiseResolve, uploadingPromiseReject;

function handleUploadSuccess(file) {
  if (uploadingPromiseResolve) {
    uploadingPromiseResolve(file);
  }
}

function handleUploadError(err) {
  if (uploadingPromiseReject) {
    uploadingPromiseReject(err);
  }
}

function approvePendingInfo(uid) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(
    host + `/api/admin/v1/sponsor/${uid}/approvePendingInfo`,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function rejectPendingInfo(uid, reason) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ reason })
  };
  return fetch(
    host + `/api/admin/v1/sponsor/${uid}/rejectPendingInfo`,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function updateSponsor(id, data) {
  var promise = new Promise((resolve, reject) => {
    resolve();
  });

  var body = {};
  if (data.sponsorLongDescription)
    body.sponsorLongDescription = data.sponsorLongDescription;

  if (data.uploadingFile) {
    // data.bannerfileUploader.props.onUploadSuccess = handleUploadSuccess;
    // data.bannerfileUploader.props.onUploadError = handleUploadError;
    promise = new Promise((resolve, reject) => {
      data.bannerfileUploader.startUpload(data.uploadingFile);
      uploadingPromiseResolve = resolve;
      uploadingPromiseReject = reject;
    });
  }

  return promise
    .then(result => {
      if (result) {
        body.sponsorBannerURL = `sponsor_banner_images/${id}/${result}`;
      }
      var host = window.location.protocol + "//" + window.location.host;
      const requestOptions = {
        method: "PUT",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ data: body })
      };
      return fetch(host + "/api/admin/v1/sponsor/" + id, requestOptions);
    })
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
}

function downgradeSponsor(id) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(host + "/api/admin/v1/sponsor/" + id, requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function searchSponsor(keyWord) {
  return getAllSponsors().then(sponsors => {
    return sponsors.filter(sponsor =>
      typeof sponsor.data.displayName === "string"
        ? sponsor.data.displayName.toLowerCase().includes(keyWord.toLowerCase())
        : false
    );
  });
}

function _getSponsorPosts(sponsorId) {
  const db = firebase.firestore();
  return db
    .collection("posts")
    .where("poster", "==", sponsorId)
    .get()
    .then(posts => {
      const postArr = [];
      posts.forEach(post => postArr.push({ id: post.id, ...post.data() }));
      return postArr;
    });
}
