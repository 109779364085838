import { mailingConstants } from "../constants";
import { mailingService } from "../services";
import { userActions } from "../actions";

export const mailingActions = {
  getAll,
  send,
  sendTest,
  getJobs,
  schedule,
  cancelSchedule
};

function getAll() {
  return dispatch => {
    dispatch(request());
    mailingService.getAll().then(
      result => {
        dispatch(success(result));
      },
      err => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: mailingConstants.GET_EMAILS_REQUEST };
  }
  function success(result) {
    return {
      type: mailingConstants.GET_EMAILS_SUCCESS,
      mails: result
    };
  }
  function failure(error) {
    return { type: mailingConstants.GET_EMAILS_FAILURE, error };
  }
}

function sendTest(info) {
  return dispatch => {
    dispatch(request());
    mailingService.send(info, "testing").then(
      result => {
        dispatch(success(result));
      },
      err => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: mailingConstants.SEND_EMAIL_TEST_REQUEST };
  }
  function success(result) {
    return {
      type: mailingConstants.SEND_EMAIL_TEST_SUCCESS,
      mail: result
    };
  }
  function failure(error) {
    return {
      type: mailingConstants.SEND_EMAIL_TEST_FAILURE,
      error
    };
  }
}

function send(info) {
  return dispatch => {
    dispatch(request());
    mailingService.send(info, "sendImediately").then(
      result => {
        dispatch(success(result));
      },
      err => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: mailingConstants.SEND_EMAIL_REQUEST };
  }
  function success(result) {
    return {
      type: mailingConstants.SEND_EMAIL_SUCCESS,
      mail: result
    };
  }
  function failure(error) {
    return { type: mailingConstants.SEND_EMAIL_FAILURE, error };
  }
}

function getJobs() {
  return dispatch => {
    dispatch(request());
    mailingService.getJobs().then(
      result => {
        dispatch(success(result));
      },
      err => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: mailingConstants.GET_SCHEDULEDJOBS_EMAIL_REQUEST };
  }
  function success(result) {
    return {
      type: mailingConstants.GET_SCHEDULEDJOBS_EMAIL_SUCCESS,
      jobs: result
    };
  }
  function failure(error) {
    return { type: mailingConstants.GET_SCHEDULEDJOBS_EMAIL_FAILURE, error };
  }
}

function schedule(info) {
  return dispatch => {
    dispatch(request());
    mailingService.send(info, "schedule").then(
      result => {
        dispatch(success(result));
      },
      err => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: mailingConstants.SCHEDULE_EMAIL_REQUEST };
  }
  function success(result) {
    return {
      type: mailingConstants.SCHEDULE_EMAIL_SUCCESS,
      job: result
    };
  }
  function failure(error) {
    return {
      type: mailingConstants.SCHEDULE_EMAIL_FAILURE,
      error
    };
  }
}

function cancelSchedule(id) {
  return dispatch => {
    dispatch(request());
    mailingService.cancelSchedule(id).then(
      result => {
        dispatch(success(result));
      },
      err => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return {
      type: mailingConstants.CANCEL_SCHEDULE_EMAIL_REQUEST
    };
  }
  function success(result) {
    return {
      type: mailingConstants.CANCEL_SCHEDULE_EMAIL_SUCCESS,
      job: result
    };
  }
  function failure(error) {
    return {
      type: mailingConstants.CANCEL_SCHEDULE_EMAIL_FAILURE,
      error
    };
  }
}
