export const transactionConstants = {
  GETALL_REQUEST: "TRANSACTIONS_GETALL_REQUEST",
  GETALL_SUCCESS: "TRANSACTIONS_GETALL_SUCCESS",
  GETALL_FAILURE: "TRANSACTIONS_GETALL_FAILURE",

  GET_REQUEST: "TRANSACTIONS_GET_REQUEST",
  GET_SUCCESS: "TRANSACTIONS_GET_SUCCESS",
  GET_FAILURE: "TRANSACTIONS_GET_FAILURE",

  CANCEL_REQUEST: "TRANSACTIONS_CANCEL_REQUEST",
  CANCEL_SUCCESS: "TRANSACTIONS_CANCEL_SUCCESS",
  CANCEL_FAILURE: "TRANSACTIONS_CANCEL_FAILURE",

  SEARCH_REQUEST: "TRANSACTIONS_SEARCH_REQUEST",
  SEARCH_SUCCESS: "TRANSACTIONS_SEARCH_SUCCESS",
  SEARCH_FAILURE: "TRANSACTIONS_SEARCH_FAILURE",

  COMPLETE_REQUEST: "TRANSACTIONS_COMPLETE_REQUEST",
  COMPLETE_SUCCESS: "TRANSACTIONS_COMPLETE_SUCCESS",
  COMPLETE_FAILURE: "TRANSACTIONS_COMPLETE_FAILURE",

  UPDATE_ADDRESSINFO_REQUEST: "TRANSACTIONS_UPDATE_ADDRESSINFO_REQUEST",
  UPDATE_ADDRESSINFO_SUCCESS: "TRANSACTIONS_UPDATE_ADDRESSINFO_SUCCESS",
  UPDATE_ADDRESSINFO_FAILURE: "TRANSACTIONS_UPDATE_ADDRESSINFO_FAILURE"
};
