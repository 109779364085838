import { paymentConstants } from '../constants';

const initialState =  {loading : false};

export function payment(state = initialState, action) {
  switch (action.type) {
    case paymentConstants.GET_REQUEST:
      return {
        loading : true
      };
    case paymentConstants.GET_SUCCESS:
      return {
        loading : false,
        payment : action.payment
      };
    case paymentConstants.GET_FAILURE:
      return {
        loading : false
      };
    case paymentConstants.REFUND_REQUEST:
      return {
        loading : true,
        payment : state.payment
      };
    case paymentConstants.REFUND_SUCCESS:
      return {
        loading : false,
        payment : state.payment,
        refundSuccess : true
      };
    case paymentConstants.REFUND_FAILURE:
      return {
        loading : false,
        payment : state.payment
      };
    default:
      return state
  }
}
