import React from "react";
import swal from "sweetalert";
import { connect } from "react-redux";
import { chatActions } from "../../actions";

const DeleteMessage = ({ isHover, messageId, dispatch }) => {
  return (
    <span
      className="ti-close"
      style={{
        color: "#fff",
        display: isHover ? "" : "none",
        cursor: "pointer"
      }}
      onClick={() => {
        // console.log("delete message", messageId)
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this message!",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            dispatch(chatActions.deleteAMessage(messageId));
            swal("Poof! Your imaginary file has been deleted!", {
              icon: "success"
            });
          }
        });
      }}
    />
  );
};

export default connect()(DeleteMessage);
