import { authHeader } from "../helpers";
export const authService = {
  fetch,
  auth
};

function fetch(banner) {
  const role = localStorage.getItem("role");
  if (role) {
    return Promise.resolve(JSON.parse(role));
  }

  return Promise.resolve();
}

function auth() {
  var host = window.location.protocol + "//" + window.location.host;

  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  var queryString = "/api/admin/v1/auth";

  return fetch(host + queryString, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return Promise.resolve();
  });
}
