import { dashboardConstants } from "../constants";

export function dashboard(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GET_TRANSACTION_COUNT_REQUEST:
      var info = state.counts ? state.counts : {};
      info[action.method] = { count: 0, loading: true };
      return { ...state, counts: info };
    case dashboardConstants.GET_TRANSACTION_COUNT_SUCCESS:
      var count = action.result.count ? action.result.count : 0;
      var counts = state.counts ? state.counts : {};
      counts[action.method] = { count, loading: false };
      return { counts };
    case dashboardConstants.GET_TRANSACTION_COUNT_FAILURE:
      var info2 = state;
      info2[action.method] = { count: -1, loading: false };
      return { ...state, counts: info2 };
    case dashboardConstants.GET_DETAILED_TRANSACTION_COUNT_REQUEST:
      var info3 = state.details ? state.details : {};
      info3[action.method] = { count: 0, loading: true };
      return { ...state, details: info3 };
    case dashboardConstants.GET_DETAILED_TRANSACTION_COUNT_SUCCESS:
      var details = state.details ? state.details : {};
      details[action.method] = { ...action.result, loading: false };
      return { ...state, details: details };
    case dashboardConstants.GET_DETAILED_TRANSACTION_COUNT_FAILURE:
      var info4 = state;
      info4[action.method] = { loading: false };
      return { ...state, details: info4 };
    //CATEGORY INFO
    case dashboardConstants.GET_CATEGORY_INFOS_REQUEST:
      return { ...state };
    case dashboardConstants.GET_CATEGORY_INFOS_SUCCESS:
      return { ...state, categoryInfos: action.result };
    case dashboardConstants.GET_CATEGORY_INFOS_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
