import React, { Component, useState } from "react";
import { DropTarget } from "react-dnd";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from "reactstrap";
import { editHomePageService } from "../../services";

const NewCardModal = props => {
  //card type
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectIndex, setSelectIndex] = useState(0);
  let zoneIndex = 0;
  let categoryIndex = 0;
  let categoryPriority = "none";
  let titleObj = {
    title: {
      zh_TW: "",
      en: ""
    },
    body: {
      zh_TW: "",
      en: ""
    }
  };
  function getZoneIndex(index) {
    zoneIndex = index;
    return index;
  }
  function getCategoryIndex(index, priority) {
    categoryIndex = index;
    categoryPriority = priority;
    return index;
  }
  function getTitleObj(obj) {
    titleObj = obj;
    return obj;
  }
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>Modal title</ModalHeader>
      <ModalBody>
        {/* card type select */}
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(!dropdownOpen)}
        >
          <DropdownToggle caret>card type </DropdownToggle>
          <DropdownMenu>
            {props.cardProtos.map((cardProto, index) => (
              <DropdownItem
                onClick={() => setSelectIndex(index)}
                key={cardProto.id}
              >
                {cardProto.serverType}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {props.cardProtos[selectIndex].serverType || "Select a typez"}
        <CategoryBody
          categories={props.categories}
          getCategoryIndex={getCategoryIndex}
          visible={props.cardProtos[selectIndex].serverType === "categoryPosts"}
        />
        <ZoneBody
          zones={props.zones}
          getZoneIndex={getZoneIndex}
          visible={props.cardProtos[selectIndex].serverType === "zonePosts"}
        />
        <InviteFriendBody
          getTitleObj={getTitleObj}
          defaultObj={titleObj}
          visible={props.cardProtos[selectIndex].serverType === "inviteFriends"}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() =>
            props.handleClose(
              props.cardProtos[selectIndex],
              zoneIndex,
              categoryIndex,
              categoryPriority,
              titleObj
            )
          }
        >
          Add
        </Button>
        <Button color="secondary" onClick={() => props.handleClose(undefined)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const CategoryBody = props => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [isPrioorityDropOpen, setIsPriorityDropOpen] = useState(false);
  const [priority, setPriority] = useState("none");
  const priorityArr = ["first", "second", "third", "none"];
  props.getCategoryIndex(categoryIndex, priority);
  return (
    <div style={{ display: props.visible ? "" : "none" }}>
      <Dropdown isOpen={isDropOpen} toggle={() => setIsDropOpen(!isDropOpen)}>
        <DropdownToggle caret>category</DropdownToggle>
        <DropdownMenu>
          {props.categories.map((category, index) => (
            <DropdownItem
              onClick={() => setCategoryIndex(index)}
              key={category.code}
            >
              {category.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {props.categories[categoryIndex].name || "Select a category"}
      <Dropdown
        isOpen={isPrioorityDropOpen}
        toggle={() => setIsPriorityDropOpen(!isPrioorityDropOpen)}
      >
        <DropdownToggle caret>alternate</DropdownToggle>
        <DropdownMenu>
          {priorityArr.map(element => (
            <DropdownItem onClick={() => setPriority(element)} key={element}>
              {element}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {`Category alternate to users most often: ${priority}`}
    </div>
  );
};
const ZoneBody = props => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [zoneIndex, setZoneIndex] = useState(0);
  props.getZoneIndex(zoneIndex);
  return (
    <div style={{ display: props.visible ? "" : "none" }}>
      <Dropdown isOpen={isDropOpen} toggle={() => setIsDropOpen(!isDropOpen)}>
        <DropdownToggle caret>zone</DropdownToggle>
        <DropdownMenu>
          {props.zones.map((zone, index) => (
            <DropdownItem onClick={() => setZoneIndex(index)} key={zone.id}>
              {zone.name.zh_TW}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {props.zones[zoneIndex].name.zh_TW || "Select a zone"}
    </div>
  );
};
const InviteFriendBody = props => {
  const [inviteTitle_zh_TW, setInviteTitle_zh_TW] = useState("");
  const [inviteTitle_en, setInviteTitle_en] = useState("");
  const [inviteBody_zh_TW, setInviteBody_zh_TW] = useState("");
  const [inviteBody_en, setInviteBody_en] = useState("");
  props.getTitleObj({
    title: {
      zh_TW: inviteTitle_zh_TW,
      en: inviteTitle_en
    },
    body: {
      zh_TW: inviteBody_zh_TW,
      en: inviteBody_en
    }
  });
  return (
    <div style={{ display: props.visible ? "" : "none" }}>
      invite friend title zh_TW
      <Input
        onChange={e => setInviteTitle_zh_TW(e.target.value)}
        value={inviteTitle_zh_TW}
      />
      invite friend title en
      <Input
        onChange={e => setInviteTitle_en(e.target.value)}
        value={inviteTitle_en}
      />
      <br />
      invite friend body zh_TW
      <Input
        onChange={e => setInviteBody_zh_TW(e.target.value)}
        value={inviteBody_zh_TW}
      />
      invite friend body en
      <Input
        onChange={e => setInviteBody_en(e.target.value)}
        value={inviteBody_en}
      />
    </div>
  );
};
// dnd drop config
const dropSpec = {
  drop(props, monitor, component) {
    props.moveItem(monitor.getItem(), props.index);
  }
};
const dropCollect = (connect, monitor) => {
  return {
    collectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};
class AddHomePageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      cardProtos: []
    };
  }
  addNewSection = () => {
    this.setState({ showModal: true });
  };
  handleClose = (
    cardData,
    zoneIndex,
    categoryIndex,
    categoryPriority,
    inviteObj
  ) => {
    console.clear();
    // need validator
    this.setState({ showModal: false });
    if (cardData) {
      // has data push to parent state!!!!!
      switch (cardData.serverType) {
        case "zonePosts":
          cardData.title = this.props.zones[zoneIndex].name;
          cardData.moreAction.zoneID = this.props.zones[zoneIndex].id;
          break;
        case "categoryPosts":
          cardData.title = this.props.categories[categoryIndex].title;
          cardData.categoryImgURL = this.props.categories[
            categoryIndex
          ].imageURL;
          cardData.moreAction.category = this.props.categories[
            categoryIndex
          ].code;
          if (categoryPriority !== "none") cardData.priority = categoryPriority;
          break;
        case "inviteFriends":
          cardData.inviteFriend = inviteObj;
          break;
        default:
          break;
      }
      this.props.addItem(cardData);
    }
  };
  getCardProtos = () => {
    editHomePageService.getCardProtos().then(cardProtos => {
      this.setState({ cardProtos });
    });
  };
  componentDidMount() {
    this.getCardProtos();
  }
  render() {
    const { collectDropTarget, isOver } = this.props;
    return collectDropTarget(
      <div
        style={{
          borderTop: isOver ? "3px solid blue" : "",
          padding: "8px 0"
        }}
      >
        <div
          style={{
            border: "1px solid black",
            padding: "8px 4px"
          }}
          onClick={this.addNewSection}
        >
          +
        </div>
        {this.state.showModal && (
          <NewCardModal
            showModal={this.state.showModal}
            handleClose={this.handleClose}
            cardProtos={this.state.cardProtos}
            categories={this.props.categories}
            zones={this.props.zones}
          />
        )}
      </div>
    );
  }
}

export default DropTarget("homePageCard", dropSpec, dropCollect)(
  AddHomePageCard
);
