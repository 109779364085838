export const notificationConstants = {
  GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",

  SEND_NOTIFICATIONS_REQUEST: "SEND_NOTIFICATIONS_REQUEST",
  SEND_NOTIFICATIONS_SUCCESS: "SEND_NOTIFICATIONS_SUCCESS",
  SEND_NOTIFICATIONS_FAILURE: "SEND_NOTIFICATIONS_FAILURE",

  SEND_NOTIFICATION_TEST_REQUEST: "SEND_NOTIFICATION_TEST_REQUEST",
  SEND_NOTIFICATION_TEST_SUCCESS: "SEND_NOTIFICATION_TEST_SUCCESS",
  SEND_NOTIFICATION_TEST_FAILURE: "SEND_NOTIFICATION_TEST_FAILURE",

  GET_SCHEDULEDJOBS_REQUEST: "GET_SCHEDULEDJOBS_REQUEST",
  GET_SCHEDULEDJOBS_SUCCESS: "GET_SCHEDULEDJOBS_SUCCESS",
  GET_SCHEDULEDJOBS_FAILURE: "GET_SCHEDULEDJOBS_FAILURE",

  SCHEDULE_NOTIFICATIONS_REQUEST: "SCHEDULE_NOTIFICATIONS_REQUEST",
  SCHEDULE_NOTIFICATIONS_SUCCESS: "SCHEDULE_NOTIFICATIONS_SUCCESS",
  SCHEDULE_NOTIFICATIONS_FAILURE: "SCHEDULE_NOTIFICATIONS_FAILURE",

  CANCEL_SCHEDULE_NOTIFICATIONS_REQUEST:
    "CANCEL_SCHEDULE_NOTIFICATIONS_REQUEST",
  CANCEL_SCHEDULE_NOTIFICATIONS_SUCCESS:
    "CANCEL_SCHEDULE_NOTIFICATIONS_SUCCESS",
  CANCEL_SCHEDULE_NOTIFICATIONS_FAILURE: "CANCEL_SCHEDULE_NOTIFICATIONS_FAILURE"
};
