import React, { Component } from "react";
// import { render } from "react-dom";
import { connect } from "react-redux";
import { paymentActions } from "../actions";
// import { userActions } from "../actions";
// import { Link } from "react-router-dom";
// import { UserContent } from "../components";
import swal from "sweetalert";
import { Row, Col, FormGroup } from "reactstrap";
import moment from "moment";

class TransactionPayment extends Component {
  constructor(props) {
    super(props);
    const { transaction, dispatch } = this.props;
    if (transaction) {
      dispatch(paymentActions.get(transaction));
    }
  }

  handleOnClickRefund(tradeId, e) {
    e.preventDefault();

    if (tradeId) {
      var self = this;
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: [
          {
            text: "Cancel",
            value: null,
            visible: true,
            className: "btn btn-success btn-fill",
            closeModal: true
          },
          {
            text: "Refund",
            value: true,
            visible: true,
            className: "btn btn-danger btn-fill",
            closeModal: false
          }
        ],
        dangerMode: true,
        buttonsStyling: false
      }).then(function(confirm) {
        if (confirm) {
          const { dispatch } = self.props;
          const { payment } = self.props.payment;
          dispatch(paymentActions.refund(payment));
        }
      });
    }

    return;
    // const { payment } = this.props.payment;

    // if (payment.tradeId)
    // {
    //     this.props.onClick(payment.tradeId);
    //     //dispatch(paymentActions.refund(payment.tradeId));
    // }
  }

  statusText(status) {
    var text = "";
    switch (status) {
      case -1:
        text = "Error";
        break;
      case 0:
        text = "Authorized";
        break;
      case 1:
        text = "OK";
        break;
      case 3:
        text = "Refunded";
        break;
      case 4:
        text = "Pending";
        break;
      case 5:
        text = "Cancelled";
        break;
      default:
        text = status;
        break;
    }
    /*
                -1  ERROR   交易錯誤
                0   AUTH    銀行已授權交易，但尚未請款
                1   OK  交易完成
                2   PARTIALREFUNDED 部分退款
                3   REFUNDED    完全退款
                4   PENDING 待付款
                5   CANCEL  取消交易
                */

    return text;
  }

  render() {
    // const { loading, payment, refundSuccess } = this.props.payment;
    const { loading, payment } = this.props.payment;
    var info = "";

    // var status = "";

    if (loading) {
      info = (
        <img
          alt="..."
          className="img-responsive center-block"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      );
    } else if (payment && Object.keys(payment).length > 0) {
      var statusText = this.statusText(payment.status);
      this.handleOnClickRefund = this.handleOnClickRefund.bind(
        this,
        payment.tradeId
      );

      // var refundButton =
      //   payment.refundAmount === payment.originalAmount || refundSuccess ? (
      //     ""
      //   ) : (
      //     <div className="text-center">
      //       <button
      //         className="btn btn-danger btn-fill btn-wd pull-right"
      //         onClick={this.handleOnClickRefund}
      //       >
      //         Refund
      //       </button>
      //       <br />
      //       <br />
      //     </div>
      //   );

      info = (
        <div>
          <Row className="row-vin-padding12">
            <Col>
              <h5>Payment</h5>
            </Col>
          </Row>
          <Row className="row-vin-padding12">
            <Col>
              <FormGroup>
                <h6>Tappay ID</h6>
                <p className="form-control-static"> {payment.id}</p>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <h6>Payment Status</h6>
                <p className="form-control-static"> {statusText}</p>
              </FormGroup>
            </Col>
            {payment.time && (
              <Col>
                <FormGroup>
                  <h6>Trade Time</h6>
                  <p className="form-control-static">
                    {moment(payment.time).format("LLLL")}
                  </p>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row className="row-vin-padding12">
            <Col>
              <FormGroup>
                <h6>Original Amount</h6>
                <p className="form-control-static"> {payment.originalAmount}</p>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <h6>Paid Amount</h6>
                <p className="form-control-static"> {payment.amount}</p>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <h6>Refund Amount</h6>
                <p className="form-control-static"> {payment.refundAmount}</p>
              </FormGroup>
            </Col>
          </Row>
          <div className="clearfix" />
          <br />
        </div>
      );
    }

    return <div>{info}</div>;
  }
}

const mapStateToProps = state => {
  const { payment } = state;
  return {
    payment
  };
};
export default connect(mapStateToProps)(TransactionPayment);
