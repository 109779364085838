import { sponsorConstants } from "../constants";
import { sponsorService } from "../services";
import { alertActions } from "./";
import { userActions } from "../actions";

export const sponsorActions = {
  getAllApplications,
  getApplication,
  updateApplication,
  getAllSponsors,
  getSponsor,
  createSponsor,
  updateSponsor,
  downgradeSponsor,
  updatePendingInfo,
  getAllNPOs
};

function getAllApplications() {
  return dispatch => {
    dispatch(request());
    sponsorService.getAllApplications().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: sponsorConstants.GET_ALL_APPLICATION_REQUEST };
  }
  function success(applications) {
    return { type: sponsorConstants.GET_ALL_APPLICATION_SUCCESS, applications };
  }
  function failure(error) {
    return { type: sponsorConstants.GET_ALL_APPLICATION_FAILURE, error };
  }
}

function getApplication(id) {
  return dispatch => {
    dispatch(request());
    sponsorService.getApplication(id).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: sponsorConstants.GET_APPLICATION_REQUEST };
  }
  function success(application) {
    return { type: sponsorConstants.GET_APPLICATION_SUCCESS, application };
  }
  function failure(error) {
    return { type: sponsorConstants.GET_APPLICATION_FAILURE, error };
  }
}

// role : sponsor / NPO
function updateApplication(application, approved, role = "sponsor") {
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(application, approved));
    sponsorService.updateApplication(application, approved, role).then(
      result => {
        dispatch(success(result, approved));
      },
      error => {
        dispatch(failure(error, approved));
        dispatch(alertActions.error(error, approved));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request(application, approved) {
    return {
      type: sponsorConstants.UPDATE_APPLICATION_REQUEST,
      application,
      approved
    };
  }
  function success(result, approved) {
    return {
      type: sponsorConstants.UPDATE_APPLICATION_SUCCESS,
      application: result,
      approved
    };
  }
  function failure(error, approved) {
    return {
      type: sponsorConstants.UPDATE_APPLICATION_FAILURE,
      error,
      approved
    };
  }
}

function getAllNPOs() {
  return dispatch => {
    dispatch(request());
    sponsorService.getAllNPOs().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: sponsorConstants.GET_ALL_NPO_REQUEST };
  }
  function success(NPOs) {
    return { type: sponsorConstants.GET_ALL_NPO_SUCCESS, NPOs };
  }
  function failure(error) {
    return { type: sponsorConstants.GET_ALL_NPO_FAILURE, error };
  }
}

function getAllSponsors() {
  return dispatch => {
    dispatch(request());
    sponsorService.getAllSponsors().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: sponsorConstants.GET_ALL_SPONSOR_REQUEST };
  }
  function success(sponsors) {
    return { type: sponsorConstants.GET_ALL_SPONSOR_SUCCESS, sponsors };
  }
  function failure(error) {
    return { type: sponsorConstants.GET_ALL_SPONSOR_FAILURE, error };
  }
}

function getSponsor(id) {
  return dispatch => {
    dispatch(request());
    sponsorService.getSponsor(id).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: sponsorConstants.GET_SPONSOR_REQUEST };
  }
  function success(sponsor) {
    return { type: sponsorConstants.GET_SPONSOR_SUCCESS, sponsor };
  }
  function failure(error) {
    return { type: sponsorConstants.GET_SPONSOR_FAILURE, error };
  }
}

function createSponsor(data) {
  return dispatch => {
    dispatch(request());
    sponsorService.createSponsor(data).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request(application) {
    return { type: sponsorConstants.CREATE_SPONSOR_REQUEST };
  }
  function success(result) {
    return { type: sponsorConstants.CREATE_SPONSOR_SUCCESS, result };
  }
  function failure(error) {
    return { type: sponsorConstants.CREATE_SPONSOR_FAILURE, error };
  }
}

function updateSponsor(id, data) {
  return dispatch => {
    dispatch(request());
    sponsorService.updateSponsor(id, data).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: sponsorConstants.UPDATE_SPONSOR_REQUEST };
  }
  function success(result) {
    return { type: sponsorConstants.UPDATE_SPONSOR_SUCCESS, result };
  }
  function failure(error) {
    return { type: sponsorConstants.UPDATE_SPONSOR_FAILURE, error };
  }
}

function downgradeSponsor(id) {
  return dispatch => {
    dispatch(request());
    sponsorService.downgradeSponsor(id).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: sponsorConstants.DOWNGRADE_SPONSOR_REQUEST };
  }
  function success(result) {
    return { type: sponsorConstants.DOWNGRADE_SPONSOR_SUCCESS, result };
  }
  function failure(error) {
    return { type: sponsorConstants.DOWNGRADE_SPONSOR_FAILURE, error };
  }
}

function updatePendingInfo(uid, approved, reason) {
  return dispatch => {
    dispatch(request());
    var promise = approved
      ? sponsorService.approvePendingInfo(uid)
      : sponsorService.rejectPendingInfo(uid, reason);
    var action = approved ? "approve" : "reject";

    promise.then(
      result => {
        dispatch(success(action, result.sponsor));
      },
      error => {
        dispatch(failure(action, error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: sponsorConstants.UPDATE_SPONSOR_PENDINGINFO_REQUEST };
  }
  function success(action, sponsor) {
    return {
      type: sponsorConstants.UPDATE_SPONSOR_PENDINGINFO_SUCCESS,
      action,
      sponsor
    };
  }
  function failure(action, error) {
    return {
      type: sponsorConstants.UPDATE_SPONSOR_PENDINGINFO_FAILURE,
      error,
      action
    };
  }
}
