export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  GETUSER_REQUEST: "USERS_GETUSER_REQUEST",
  GETUSER_SUCCESS: "USERS_GETUSER_SUCCESS",
  GETUSER_FAILURE: "USERS_GETUSER_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  SEARCH_REQUEST: "USERS_SEARCH_REQUEST",
  SEARCH_SUCCESS: "USERS_SEARCH_SUCCESS",
  SEARCH_FAILURE: "USERS_SEARCH_FAILURE",

  GETUSER_TPLOG_REQUEST: "USERS_GETUSER_TPLOG_REQUEST",
  GETUSER_TPLOG_SUCCESS: "USERS_GETUSER_TPLOG_SUCCESS",
  GETUSER_TPLOG_FAILURE: "USERS_GETUSER_TPLOG_FAILURE",

  GET_SUSPENDEDUSERS_ACTIVITIES_REQUEST:
    "GET_SUSPENDEDUSERS_ACTIVITIES_REQUEST",
  GET_SUSPENDEDUSERS_ACTIVITIES_SUCCESS:
    "GET_SUSPENDEDUSERS_ACTIVITIES_SUCCESS",
  GET_SUSPENDEDUSERS_ACTIVITIES_FAILURE:
    "GET_SUSPENDEDUSERS_ACTIVITIES_FAILURE",

  RESUME_SUSPENDEDUSER_REQUEST: "RESUME_SUSPENDEDUSER_REQUEST",
  RESUME_SUSPENDEDUSER_SUCCESS: "RESUME_SUSPENDEDUSER_SUCCESS",
  RESUME_SUSPENDEDUSER_FAILURE: "RESUME_SUSPENDEDUSER_FAILURE",

  GET_WARNEDUSERS_ACTIVITIES_REQUEST: "GET_WARNEDUSERS_ACTIVITIES_REQUEST",
  GET_WARNEDUSERS_ACTIVITIES_SUCCESS: "GET_WARNEDUSERS_ACTIVITIES_SUCCESS",
  GET_WARNEDUSERS_ACTIVITIES_FAILURE: "GET_WARNEDUSERS_ACTIVITIES_FAILURE",

  RESUME_WARNEDUSER_REQUEST: "RESUME_WARNEDUSER_REQUEST",
  RESUME_WARNEDUSER_SUCCESS: "RESUME_WARNEDUSER_SUCCESS",
  RESUME_WARNEDUSER_FAILURE: "RESUME_WARNEDUSER_FAILURE"
};
