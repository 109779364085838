import { paymentConstants } from "../constants";
import { transactionService } from "../services";
// import { alertActions } from './';
import swal from "sweetalert";

export const paymentActions = {
  get,
  refund
};

function get(transaction) {
  return dispatch => {
    if (transaction.data.tappayId || transaction.data.stripeId) {
      var paymentId = transaction.data.tappayId;
      var service = "tappay";
      if (transaction.data.stripeId) {
        paymentId = transaction.data.stripeId;
        service = "stripe";
      }

      transactionService
        .getPaymentInfo(transaction.id, paymentId, service)
        .then(
          payment => {
            dispatch(success(payment));
          },
          error => {
            dispatch(failure(error));
            // dispatch(alertActions.error(error));
          }
        );
    } else {
      dispatch(failure(new Error("no payment info")));
    }
  };

  //   function request(payment) {
  //     return { type: paymentConstants.GET_REQUEST, payment };
  //   }
  function success(payment) {
    return { type: paymentConstants.GET_SUCCESS, payment };
  }
  function failure(error) {
    return { type: paymentConstants.GET_FAILURE, error };
  }
}

function refund(payment) {
  return dispatch => {
    if (payment.orderId && payment.tradeId) {
      transactionService.refund(payment).then(
        payment => {
          dispatch(success(payment));

          swal({
            title: "Refunded!",
            text: "You have refunded this payment.",
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn btn-success btn-fill",
                closeModal: true
              }
            },
            buttonsStyling: false
          });
        },
        error => {
          dispatch(failure(error));
          // dispatch(alertActions.error(error));
          swal({
            title: "Error Occurs",
            text: "Failed to refund, please try again.",
            icon: "error",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn btn-success btn-fill",
                closeModal: true
              }
            },
            buttonsStyling: false
          });
        }
      );
    } else {
      dispatch(failure(new Error("no payment id")));
    }
  };

  //   function request(payment) {
  //     return { type: paymentConstants.REFUND_REQUEST, payment };
  //   }
  function success(payment) {
    return { type: paymentConstants.REFUND_SUCCESS, payment };
  }
  function failure(error) {
    return { type: paymentConstants.REFUND_FAILURE, error };
  }
}
