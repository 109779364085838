import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import BannerTable from "./bannerTable.jsx";
import BannerDetail from "./bannerDetail.jsx";
import BannerCreation from "./bannerCreation.jsx";
import {
  Card,
  CardBody
  // CardHeader,
  // CardTitle,
  // Button,
  // FormGroup,
  // Row,
  // Col,
  // Input,
  // Label
} from "reactstrap";
class BannersView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCreate() {}

  render() {
    return (
      <Card>
        <CardBody>
          <Route exact path="/main/banners" component={BannerTable} />
          <Route path={`/main/banners/view/:id`} component={BannerDetail} />
          <Route path={`/main/banners/edit/:id`} component={BannerCreation} />
        </CardBody>
      </Card>
    );
  }
}

BannersView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state;
};
export default connect(mapStateToProps)(BannersView);
