import React, { Component } from "react";
import { notificationActions } from "../../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Utility from "../../helpers/utility";
import { Button } from "reactstrap";
import swal from "sweetalert";

class NotificationTable extends Component {
  constructor(props) {
    super(props);
    this.state = { notifications: [], jobs: [] };
    const { dispatch } = this.props;
    dispatch(notificationActions.getAll());
    dispatch(notificationActions.getJobs());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications) {
      this.setState({ notifications: nextProps.notifications });
    }

    if (nextProps.jobs) {
      this.setState({ jobs: nextProps.jobs });
    }

    if (this.sendingSwal && !nextProps.sending) {
      swal.close();
      this.sendingSwal = undefined;
    }
  }

  handleCancel = (e) => {
    console.log(" did click cancel -> ", e.target.name);
    let id = e.target.name;
    if (!id) return;
    let self = this;
    this.sendingSwal = swal({
      title: "Are you sure you want to cancel this scheduling?",
      text: "This cannot be undo",
      icon: "warning",
      buttons: [
        {
          text: "Not Now",
          value: null,
          visible: true,
          closeModal: true,
        },
        {
          text: "Confirm",
          value: true,
          visible: true,
          closeModal: false,
        },
      ],
      dangerMode: true,
      buttonsStyling: false,
    }).then(function(confirm) {
      if (confirm) {
        const { dispatch } = self.props;
        dispatch(notificationActions.cancelSchedule(id));
      }
    });
  };

  render() {
    return (
      <div className="content table-responsive table-full-width">
        {this.state.jobs && this.state.jobs.length > 0 && (
          <>
            <h5>Scheduled</h5>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th width="30%">Content</th>
                  <th>Country</th>
                  <th>Web URL</th>
                  <th>Scheduled Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.jobs.map((el) => (
                  <tr key={el.id}>
                    <td>{el.data.name}</td>
                    <td>
                      {el.data.content}
                      <br />
                      {el.data.zh_TW}
                    </td>
                    <td>{el.data.country}</td>
                    <td>{el.data.url}</td>
                    <td>
                      {Utility.timestampToFormattedString(el.data.sendAt)}
                    </td>
                    <td>
                      <Button
                        name={el.id}
                        onClick={this.handleCancel}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <h5>History</h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th width="30%">Content</th>
              <th>Country</th>
              <th>Type</th>
              <th>Community Id</th>
              <th>Web URL</th>
              <th>Sent Time</th>
            </tr>
          </thead>
          <tbody>
            {this.state.notifications.map((el) => (
              <tr key={el.id}>
                <td>{el.data.name}</td>
                <td>
                  {el.data.content}
                  <br />
                  {el.data.zh_TW}
                </td>
                <td>{el.data.country}</td>
                <td>{el.data.type}</td>
                <td>{el.data.entity_uuid}</td>
                <td>{el.data.url}</td>
                <td>{Utility.timestampToFormattedString(el.data.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

NotificationTable.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { notification } = state;
  return notification;
};
export default connect(mapStateToProps)(NotificationTable);
