import React, { Component } from "react";
// import { render } from "react-dom";
import { connect } from "react-redux";
import { ratingsActions } from "../actions";
// import { Link } from "react-router-dom";
// import { UserContent } from "../components";
import StarRatingComponent from "react-star-rating-component";
import moment from "moment";
import swal from "sweetalert";

class TransactionRating extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    const { transaction, dispatch } = this.props;
    if (transaction) {
      dispatch(ratingsActions.get(transaction));
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleRatingsChange = this.handleRatingsChange.bind(this);
    this.handleOnClickSave = this.handleOnClickSave.bind(this);
    this.handleOnClickDelete = this.handleOnClickDelete.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { ratings } = this.state;
    if (value !== ratings[name].originContent) {
      ratings[name].saveDisabled = false;
    } else if (ratings[name].rating === ratings[name].originRating) {
      ratings[name].saveDisabled = true;
    }

    ratings[name].content = value;

    this.setState({ ratings: ratings });
  }

  handleRatingsChange(newVale, prevValue, name) {
    const { ratings } = this.state;

    if (newVale !== prevValue) {
      ratings[name].saveDisabled = false;
    } else if (ratings[name].content === ratings[name].originContent) {
      ratings[name].saveDisabled = true;
    }
    ratings[name].rating = newVale;
    this.setState({ ratings: ratings });
  }

  handleOnClickSave(e) {
    e.preventDefault();

    const { name } = e.target;
    const { transaction, dispatch } = this.props;
    dispatch(
      ratingsActions.updateRatings(transaction, {
        name: name,
        data: this.state.ratings[name]
      })
    );
  }

  handleOnClickDelete(e) {
    e.preventDefault();
    const { name } = e.target;
    var self = this;
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: [
        {
          text: "Cancel",
          value: null,
          visible: true,
          className: "btn btn-success btn-fill",
          closeModal: true
        },
        {
          text: "Delete",
          value: true,
          visible: true,
          className: "btn btn-danger btn-fill",
          closeModal: false
        }
      ],
      dangerMode: true,
      buttonsStyling: false
    }).then(function(confirm) {
      if (confirm) {
        const { transaction, dispatch } = self.props;
        dispatch(
          ratingsActions.deleteRatings(transaction, {
            name: name,
            data: self.state.ratings[name]
          })
        );
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ratings) {
      var ratings = nextProps.ratings;
      if (ratings.ratings) {
        var ratingInfos = ratings.ratings;
        if (Object.keys(ratingInfos).length > 0) {
          Object.keys(ratingInfos).forEach(key => {
            var value = ratingInfos[key];
            if (value) {
              value.originContent = value.content;
              value.originRating = value.rating;
              value.saveDisabled = true;
              value.updating = value.updating ? value.updating : false;
            }
          });
        }
      }
      this.setState(nextProps.ratings);
    }
  }

  render() {
    const { ratings, loading } = this.state;

    var info = "";

    if (loading && loading === true) {
      info = (
        <img
          alt="..."
          className="img-responsive center-block"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      );
    } else if (ratings && Object.keys(ratings).length > 0) {
      var rates = [];
      Object.keys(ratings).forEach(key => {
        let value = ratings[key];

        if (value) {
          const timestamp = value.createdAt;
          const formatted = timestamp ? moment(timestamp).format("LLLL") : "";

          var title;
          if (key === "giverRate") {
            title = "Ratings from Giver";
          } else if (key === "takerRate") {
            title = "Ratings from Taker";
          } else if (key === "designatorRate") {
            title = "Ratings from Designator";
          }

          var rate = (
            <div className="col-md-6" key={key}>
              <label>{title}</label>
              <div className="form-group">
                <div className="col-md-12">
                  <StarRatingComponent
                    name={key}
                    value={value.rating}
                    starColor="#ba0017"
                    onStarClick={this.handleRatingsChange}
                    editing={true}
                  />
                </div>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control border-input"
                    placeholder="Content"
                    name={key}
                    value={value.content}
                    onChange={this.handleChange}
                  />
                </div>
                <label className="col-sm-6 control-label">{formatted}</label>
                <div className="col-sm-6" />
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary btn-fill btn-wd pull-right"
                  name={key}
                  disabled={value.saveDisabled}
                  onClick={this.handleOnClickSave}
                >
                  Save
                </button>
                {value.updating === true && (
                  <img
                    alt="..."
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                  />
                )}
                <button
                  className="btn btn-danger btn-fill btn-wd pull-right"
                  name={key}
                  onClick={this.handleOnClickDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          );

          rates.push(rate);
        }
      });

      if (rates.length > 0) {
        info = (
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <h4 className="title pull-left">Ratings</h4>
                </div>
              </div>
            </div>
            <div className="row">{rates}</div>

            <div className="clearfix" />
            <br />
          </div>
        );
      }
    }

    return <div>{info}</div>;
  }
}

const mapStateToProps = state => {
  const { ratings } = state;
  return { ratings };
};
export default connect(mapStateToProps)(TransactionRating);
