import React, { Component } from "react";
import { connect } from "react-redux";
// import { transactionActions } from "../actions";
// import { userActions } from "../actions";
import { Link } from "react-router-dom";
import UserImage from "./userImage.jsx";

//TRS1514732408280
class UserTable extends Component {
  constructor(props) {
    super(props);
    this.handleCheckDetail = this.handleCheckDetail.bind(this);
  }

  handleCheckDetail(e) {
    e.preventDefault();
    //onClick={this.handleCheckDetail}
  }

  render() {
    return (
      <div className="content table-responsive table-full-width">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Photo</th>
              <th>Display Name</th>
              <th>Unique ID</th>
              <th>Country</th>
              <th>Role</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.users.map(el => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>
                  {el.data.imageURL && el.data.imageURL.indexOf("http") < 0 ? (
                    <UserImage
                      id={el.id}
                      height={60}
                      imageURL={el.data.imageURL}
                    />
                  ) : (
                    <img
                      src={el.data.imageURL}
                      alt="..."
                      height="60px"
                      className="rounded-circle img-no-padding img-responsive"
                    />
                  )}
                </td>
                <td>{el.data.displayName}</td>
                <td>{el.data.uniqueID}</td>
                <td>{el.data.country}</td>
                {/* <td>{el.data.method}</td>
                                <td>{el.data.country}</td>
                                <td>{el.data.status}</td> */}
                <td>
                  <Link to={`/main/users/${el.id}/detail`}>
                    <button className="btn btn-info btn-fill">Detail</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { users } = state.users;

  return {
    users
  };
};
export default connect(mapStateToProps)(UserTable);
