import { userConstants } from "../constants";
import { userService } from "../services";
import { alertActions } from "./";
import { history } from "../helpers";

export const userActions = {
  search,
  login,
  logout,
  register,
  getAll,
  getUser,
  delete: _delete,
  getTPLogs,
  getSuspendedActivities,
  getWarnedActivities,
  resumeSuspendedUser,
  resumeWarnedUser
};

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      user => {
        dispatch(success(user));
        window.location = "/";
        history.push("/");
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  window.location = "/";
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return dispatch => {
    dispatch(request(user));

    userService.register(user).then(
      user => {
        dispatch(success());
        history.push("/login");
        dispatch(alertActions.success("Registration successful"));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());

    userService
      .getAll()
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function getUser(id) {
  return (dispatch, getState) => {
    const reduxState = getState();
    if (reduxState.users[id]) {
      // already has user data
    } else {
      dispatch(request());
      userService
        .getById(id)
        .then(
          user => dispatch(success(user)),
          error => dispatch(failure(error))
        );
    }
  };

  function request() {
    return { type: userConstants.GETUSER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETUSER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETUSER_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    userService.delete(id).then(
      user => {
        dispatch(success(id));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

function search(keyword) {
  return dispatch => {
    dispatch(request());

    userService.search(keyword).then(
      users => {
        dispatch(success(users));
      },
      error => {
        dispatch(failure(error));

        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: userConstants.SEARCH_REQUEST };
  }
  function success(users) {
    return { type: userConstants.SEARCH_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.SEARCH_FAILURE, error };
  }
}

function getTPLogs(id) {
  return dispatch => {
    dispatch(request());
    userService.getTPLogs(id).then(
      logs => {
        dispatch(success(logs));
      },
      error => {
        dispatch(failure(error));

        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: userConstants.GETUSER_TPLOG_REQUEST };
  }
  function success(logs) {
    return { type: userConstants.GETUSER_TPLOG_SUCCESS, logs };
  }
  function failure(error) {
    return { type: userConstants.GETUSER_TPLOG_FAILURE, error };
  }
}

function getSuspendedActivities(id) {
  return dispatch => {
    dispatch(request());
    userService.getSuspendedActivities().then(
      activities => {
        dispatch(success(activities));
      },
      error => {
        dispatch(failure(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: userConstants.GET_SUSPENDEDUSERS_ACTIVITIES_REQUEST };
  }
  function success(activities) {
    return {
      type: userConstants.GET_SUSPENDEDUSERS_ACTIVITIES_SUCCESS,
      activities
    };
  }
  function failure(error) {
    return { type: userConstants.GET_SUSPENDEDUSERS_ACTIVITIES_FAILURE, error };
  }
}

function getWarnedActivities(id) {
  return dispatch => {
    dispatch(request());
    userService.getWarnedActivities().then(
      activities => {
        dispatch(success(activities));
      },
      error => {
        dispatch(failure(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: userConstants.GET_WARNEDUSERS_ACTIVITIES_REQUEST };
  }
  function success(activities) {
    return {
      type: userConstants.GET_WARNEDUSERS_ACTIVITIES_SUCCESS,
      activities
    };
  }
  function failure(error) {
    return { type: userConstants.GET_WARNEDUSERS_ACTIVITIES_FAILURE, error };
  }
}

function resumeSuspendedUser(id) {
  return dispatch => {
    dispatch(request());
    userService.resumeSuspendedUser().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: userConstants.RESUME_SUSPENDEDUSER_REQUEST };
  }
  function success(result) {
    return {
      type: userConstants.RESUME_SUSPENDEDUSER_SUCCESS,
      result
    };
  }
  function failure(error) {
    return { type: userConstants.RESUME_SUSPENDEDUSER_REQUEST, error };
  }
}

function resumeWarnedUser(activityId, userId) {
  return dispatch => {
    dispatch(request());
    userService.resumeWarnedUser(activityId, userId).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: userConstants.RESUME_WARNEDUSER_REQUEST };
  }
  function success(result) {
    return {
      type: userConstants.RESUME_WARNEDUSER_SUCCESS,
      result
    };
  }
  function failure(error) {
    return { type: userConstants.RESUME_WARNEDUSER_FAILURE, error };
  }
}
