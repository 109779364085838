export const mailingConstants = {
  GET_EMAILS_REQUEST: "GET_EMAILS_REQUEST",
  GET_EMAILS_SUCCESS: "GET_EMAILS_SUCCESS",
  GET_EMAILS_FAILURE: "GET_EMAILS_FAILURE",

  SEND_EMAIL_REQUEST: "SEND_EMAIL_REQUEST",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",

  SEND_EMAIL_TEST_REQUEST: "SEND_EMAIL_TEST_REQUEST",
  SEND_EMAIL_TEST_SUCCESS: "SEND_EMAIL_TEST_SUCCESS",
  SEND_EMAIL_TEST_FAILURE: "SEND_EMAIL_TEST_FAILURE",

  GET_SCHEDULEDJOBS_EMAIL_REQUEST: "GET_SCHEDULEDJOBS_EMAIL_REQUEST",
  GET_SCHEDULEDJOBS_EMAIL_SUCCESS: "GET_SCHEDULEDJOBS_EMAIL_SUCCESS",
  GET_SCHEDULEDJOBS_EMAIL_FAILURE: "GET_SCHEDULEDJOBS_EMAIL_FAILURE",

  SCHEDULE_EMAIL_REQUEST: "SCHEDULE_EMAIL_REQUEST",
  SCHEDULE_EMAIL_SUCCESS: "SCHEDULE_EMAIL_SUCCESS",
  SCHEDULE_EMAIL_FAILURE: "SCHEDULE_EMAIL_FAILURE",

  CANCEL_SCHEDULE_EMAIL_REQUEST: "CANCEL_SCHEDULE_EMAIL_REQUEST",
  CANCEL_SCHEDULE_EMAIL_SUCCESS: "CANCEL_SCHEDULE_EMAIL_SUCCESS",
  CANCEL_SCHEDULE_EMAIL_FAILURE: "CANCEL_SCHEDULE_EMAIL_FAILURE"
};
