import moment from "moment";

class Utility {
  // constructor() {}
  static dateFromServer(date) {
    return date
      ? new Date(date._seconds * 1000 + date._nanoseconds / 1000000)
      : undefined;
  }

  static timestampToDate(timestamp) {
    return new Date(timestamp) ? new Date(timestamp) : undefined;
  }

  static timestampToFormattedString(timestamp) {
    const formatted = moment(timestamp).format("lll");
    return timestamp ? formatted : undefined;
  }

  static timestampFromNowString(timestamp) {
    const display = moment(timestamp).fromNow(true);
    return timestamp ? display : "unknown";
  }

  //dateFromServer
}

export default Utility;
