import { ecanOrderConstants } from "../constants";
import { transactionService } from "../services";
// import { alertActions } from "./";
import swal from "sweetalert";

export const ecanOrderActions = {
  get,
  update
};

function get(transaction) {
  return dispatch => {
    dispatch(request());

    if (transaction.id && transaction.data.pickupDate) {
      transactionService
        .getEcanOrder(transaction.id, transaction.data.pickupDate)
        .then(
          ecanOrder => {
            dispatch(success(ecanOrder));
          },
          error => {
            dispatch(failure(error));
            // dispatch(alertActions.error(error));
          }
        );
    } else {
      dispatch(failure({ error: new Error("Empty"), order: {} }));
    }
  };

  function request(ecanOrder) {
    return { type: ecanOrderConstants.GET_REQUEST, ecanOrder };
  }
  function success(ecanOrder) {
    return { type: ecanOrderConstants.GET_SUCCESS, ecanOrder };
  }
  function failure(error) {
    return { type: ecanOrderConstants.GET_FAILURE, error };
  }
}

function update(order, transaction) {
  return dispatch => {
    transactionService.updateEcanOrder(order, transaction).then(
      ecanOrder => {
        dispatch(success(order));

        swal({
          title: "Updated!",
          text: "You have update this order.",
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "btn btn-success btn-fill",
              closeModal: true
            }
          },
          buttonsStyling: false
        });
      },
      error => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
        swal({
          title: "Error Occurs",
          text: "Failed to update, please try again.",
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "btn btn-success btn-fill",
              closeModal: true
            }
          },
          buttonsStyling: false
        });
      }
    );
  };

  // function request(order) {
  //   return { type: ecanOrderConstants.UPDATE_REQUEST, order };
  // }
  function success(order) {
    return { type: ecanOrderConstants.UPDATE_SUCCESS, order };
  }
  function failure(error) {
    return { type: ecanOrderConstants.UPDATE_FAILURE, error };
  }
}
