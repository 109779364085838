import React, { Component } from "react";
import PropTypes from "prop-types";
import PostSquare from "./postSquare";

class PostGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    console.log(this.props.posts);
    const { posts } = this.props;
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {posts.map(post => (
          <PostSquare post={post} key={post.id} />
        ))}
      </div>
    );
  }
}

PostGrid.defaultProps = {
  posts: []
};
PostGrid.propTypes = {
  posts: PropTypes.array.isRequired
};
export default PostGrid;
