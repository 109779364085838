import { authHeader } from "../helpers";
export const zonesService = {
  createNewZone(zoneObj) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(zoneObj)
    };
    return fetch(`${host}/api/admin/v1/zone`, requestOptions).then(response => {
      console.log("opopopopopo");
      console.log(response);
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return { result: "ok" };
    });
  },
  getZonesList() {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${host}/api/admin/v1/zones`, requestOptions).then(
      response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      }
    );
  },
  getSingleZone(zoneId) {
    console.log(zoneId);
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${host}/api/admin/v1/zone/${zoneId}`, requestOptions).then(
      response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      }
    );
  },
  updateZone(zoneId, zoneObj) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "PUT",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(zoneObj)
    };
    return fetch(`${host}/api/admin/v1/zone/${zoneId}`, requestOptions).then(
      response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return { result: "ok" };
      }
    );
  }
};
