import React, { Component } from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
export default class DateTimeSelectComponent extends Component {
  constructor(props) {
    super(props);
    let now = moment();

    this.state = {
      value: props.value
    };
  }
  componentWillReceiveProps(nextProps) {
    console.log(" componentWillReceiveProps  ");
    if (nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  dateOnChange = date => {
    var now = moment().add(1, "minute");
    if (date < now) {
      return;
    }
    this.setState({ value: date });
    this.props.onChange(date);
  };

  handleValidDate = (current, selected) => {
    var now = moment().subtract(1, "day");
    return current > now;
  };

  render() {
    return (
      <ReactDatetime
        inputProps={{
          className: "form-control",
          placeholder: "Date"
        }}
        value={this.state.value}
        onChange={this.dateOnChange}
        isValidDate={this.handleValidDate}
      />
    );
  }
}
