import React from "react";
import PropTypes from "prop-types";
import { UserContent } from "../index";
import { chatActions } from "../../actions";
import { connect } from "react-redux";
import { Input } from "reactstrap";

const ChatRelationCell = ({
  relation,
  dispatch,
  chattingID,
  multiChattingID
}) => {
  return (
    <div
      style={{
        margin: "4px",
        padding: "20px 0 4px 0",
        borderBottom: "1px solid #ccc",
        cursor: "pointer",
        backgroundColor: chattingID.includes(relation.id)
          ? "rgba(193, 25, 32, 0.1)"
          : ""
      }}
      onClick={() => {
        console.log("relation clickkkkkkkkk");
        console.log(relation);
        // prevent multiple clicking
        if (!chattingID.includes(relation.id))
          dispatch(chatActions.setSingleChattingID(relation.id));
        // dispatch(chatActions.toogleMultiChattingID(relation.id));
      }}
    >
      <UserContent id={relation.id} key={relation.id} horizontal />
      <label onClick={e => e.stopPropagation()}>
        <Input
          type="checkbox"
          style={{ position: "unset", margin: "0 4px" }}
          checked={multiChattingID.includes(relation.id)}
          onChange={e => {
            e.stopPropagation();
            dispatch(chatActions.toogleMultiChattingID(relation.id));
          }}
        />
        <span>for broadcast</span>
        <span
          style={{
            display: "inline-block",
            marginLeft: "20px",
            backgroundColor: "#c11920",
            width: "18px",
            height: "18px",
            color: "#fff",
            textAlign: "center",
            borderRadius: "50%",
            fontWeight: "bold",
            opacity: relation.badge > 0 ? "1" : "0"
          }}
        >
          {relation.badge}
        </span>
      </label>
    </div>
  );
};

ChatRelationCell.propTypes = {
  relation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    updatedAt: PropTypes.number.isRequired
    // there are some other properties whitch is optional
  }).isRequired,
  chattingID: PropTypes.array.isRequired
};
const mapState2Props = state => {
  const { chattingID, multiChattingID } = state.chat;
  return { chattingID, multiChattingID };
};
export default connect(mapState2Props)(ChatRelationCell);
