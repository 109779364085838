import { ratingsConstants } from '../constants';

const initialState =  {loading : false};

export function ratings(state = initialState, action) {
  switch (action.type) {
    case ratingsConstants.GET_REQUEST:
      return {
        loading : true
      };
    case ratingsConstants.GET_SUCCESS:
      return {
        ...state,
        loading : false,
        ratings : action.ratings
      };
    case ratingsConstants.GET_FAILURE:
      return {
        loading : false
      };
    case ratingsConstants.UPDATE_REQUEST:
      if (action.ratingData)
      {
        state.ratings[action.name] = action.ratingData;
      }
      return {
        ...state
      };
    case ratingsConstants.UPDATE_SUCCESS:
      if (action.newRating)
      {
        state.ratings[action.name] = action.newRating;
      }
      return {
        ...state
      };
    case ratingsConstants.UPDATE_FAILURE:
      if (action.ratingData)
      {
        state.ratings[action.name] = action.ratingData;
      }
      return {
        ...state
      };
    case ratingsConstants.DELETE_REQUEST:
      return {
        ...state
      };
    case ratingsConstants.DELETE_SUCCESS:
      if (action.name)
      {
        state.ratings[action.name] = undefined;
      }
      return {
        ...state
      };
    case ratingsConstants.DELETE_FAILURE:
      return {
        ...state
      };
    default:
      return state
  }
}
