export const sponsorConstants = {
  GET_ALL_APPLICATION_REQUEST: "SPONSOR_APPLICATION_GET_ALL_REQUEST",
  GET_ALL_APPLICATION_SUCCESS: "SPONSOR_APPLICATION_GET_ALL_SUCCESS",
  GET_ALL_APPLICATION_FAILURE: "SPONSOR_APPLICATION_GET_ALL_FAILURE",

  GET_APPLICATION_REQUEST: "SPONSOR_APPLICATION_GET_REQUEST",
  GET_APPLICATION_SUCCESS: "SPONSOR_APPLICATION_GET_SUCCESS",
  GET_APPLICATION_FAILURE: "SPONSOR_APPLICATION_GET_FAILURE",

  UPDATE_APPLICATION_REQUEST: "SPONSOR_APPLICATION_UPDATE_REQUEST",
  UPDATE_APPLICATION_SUCCESS: "SPONSOR_APPLICATION_UPDATE_SUCCESS",
  UPDATE_APPLICATION_FAILURE: "SPONSOR_APPLICATION_UPDATE_FAILURE",

  GET_ALL_NPO_REQUEST: "NPO_GET_ALL_REQUEST",
  GET_ALL_NPO_SUCCESS: "NPO_GET_ALL_SUCCESS",
  GET_ALL_NPO_FAILURE: "NPO_GET_ALL_FAILURE",

  GET_ALL_SPONSOR_REQUEST: "SPONSOR_GET_ALL_REQUEST",
  GET_ALL_SPONSOR_SUCCESS: "SPONSOR_GET_ALL_SUCCESS",
  GET_ALL_SPONSOR_FAILURE: "SPONSOR_GET_ALL_FAILURE",

  GET_SPONSOR_REQUEST: "SPONSOR_GET_REQUEST",
  GET_SPONSOR_SUCCESS: "SPONSOR_GET_SUCCESS",
  GET_SPONSOR_FAILURE: "SPONSOR_GET_FAILURE",

  CREATE_SPONSOR_REQUEST: "SPONSOR_CREATE_REQUEST",
  CREATE_SPONSOR_SUCCESS: "SPONSOR_CREATE_SUCCESS",
  CREATE_SPONSOR_FAILURE: "SPONSOR_CREATE_FAILURE",

  UPDATE_SPONSOR_REQUEST: "SPONSOR_UPDATE_REQUEST",
  UPDATE_SPONSOR_SUCCESS: "SPONSOR_UPDATE_SUCCESS",
  UPDATE_SPONSOR_FAILURE: "SPONSOR_UPDATE_FAILURE",

  DOWNGRADE_SPONSOR_REQUEST: "SPONSOR_DOWNGRADE_REQUEST",
  DOWNGRADE_SPONSOR_SUCCESS: "SPONSOR_DOWNGRADE_SUCCESS",
  DOWNGRADE_SPONSOR_FAILURE: "SPONSOR_DOWNGRADE_FAILURE",

  UPDATE_SPONSOR_PENDINGINFO_REQUEST: "SPONSOR_UPDATE_PENDINGINFO_REQUEST",
  UPDATE_SPONSOR_PENDINGINFO_SUCCESS: "SPONSOR_UPDATE_PENDINGINFO_SUCCESS",
  UPDATE_SPONSOR_PENDINGINFO_FAILURE: "SPONSOR_UPDATE_PENDINGINFO_FAILURE"
};
