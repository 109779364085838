// import { alertConstants, authConstants } from "../constants";
import { authConstants } from "../constants";
import { authService } from "../services";
import { userActions } from "../actions";

export const authActions = {
  fetch,
  auth
};

function fetch() {
  return dispatch => {
    dispatch(request());
    authService.fetch().then(result => {
      dispatch(success(result));
    });
  };

  function request() {
    return { type: authConstants.FETCH_REQUEST };
  }
  function success(result) {
    return { type: authConstants.FETCH_SUCCESS, result };
  }
  // function failure(error) {
  //   return { type: authConstants.FETCH_FAILURE, error };
  // }
}

function auth() {
  return dispatch => {
    authService.auth().then(
      result => {},
      err => {
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
}
