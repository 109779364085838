import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, Button, Label } from "reactstrap";
import swal from "sweetalert";
import { connect } from "react-redux";
import { chatActions } from "../../actions";

const AddTemplate = props => {
  const [modalVisible, setModalVisible] = useState(false);
  const [templateMessage, setTemplateMessage] = useState("");
  useEffect(() => {
    if (!modalVisible) setTemplateMessage("");
  }, [modalVisible]);
  return (
    <div
      style={{
        padding: "4px",
        border: "1px solid #ccc",
        margin: "4px",
        cursor: "pointer"
      }}
      onClick={() => setModalVisible(true)}
    >
      <Label>+</Label>

      <Modal
        isOpen={modalVisible}
        toggle={() => setModalVisible(!modalVisible)}
      >
        <ModalBody>
          <textarea
            cols="55"
            rows="5"
            onChange={e => setTemplateMessage(e.target.value)}
            value={templateMessage}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (templateMessage) {
                const { dispatch } = props;
                dispatch(chatActions.creteChatTemplate(templateMessage));
                setModalVisible(false);
              } else {
                swal("Oops!", "You need to put some wordings", "error");
              }
            }}
          >
            Create
          </Button>
          <Button color="secondary" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default connect()(AddTemplate);
