import React, { Component } from "react";
import EditHomePageCard from "./editHomePageCard";
import AddHomePageCard from "./addHomePageCard";
import { editHomePageService } from "../../services";
import { Button } from "reactstrap";
import swal from "sweetalert";

class EditHomePageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: {
        TW: [],
        US: []
      },
      country: "TW",
      categories: [],
      zones: []
    };
  }
  // change country
  changeCountry = country => {
    this.setState({ country });
  };
  // item actions
  moveItem = (draggingItem, insertIndx) => {
    this.setState(preState => {
      preState.sections[preState.country][draggingItem.index].willDelete = true;
      const copyItem = Object.assign({}, draggingItem);
      delete copyItem.index;
      preState.sections[preState.country].splice(insertIndx, 0, copyItem);
      preState.sections[preState.country] = preState.sections[
        preState.country
      ].filter(section => !section.willDelete);
      return {
        sections: preState.sections
      };
    });
  };
  addItem = newItem => {
    const TimeStamp = new Date().valueOf();
    const test2Item = Object.assign({}, newItem, {
      id: `autoGenID${TimeStamp}`
    });
    this.setState(preState => {
      preState.sections[preState.country].push(test2Item);
      return {
        sections: preState.sections
      };
    });
  };
  deleteItem = deleteIndex => {
    this.setState(preState => {
      preState.sections[preState.country].splice(deleteIndex, 1);
      return { sections: preState.sections };
    });
  };
  editItem = (editedItem, index) => {
    this.setState(preState => {
      preState.sections[preState.country][index] = editedItem;
      return { sections: preState.sections };
    });
  };
  // prepareing to send items
  hasErrorCaheck = () => {
    // migt be deprecated in future
    // currenet is for fool-proof for not selecting none
    const hasErrors = {
      TW: false,
      US: false
    };
    const { sections } = this.state;
    Object.keys(sections).forEach(country => {
      const hasError = sections[country].reduce((acc, curSection) => {
        if (
          curSection.serverType === "categoryPosts" &&
          curSection.priority === undefined
        )
          return true;
        return acc;
      }, false);
      hasErrors[country] = hasError;
    });
    if (hasErrors.TW)
      swal({
        text: "Cannot choose priority of none in categoryPosts in TW"
      });
    if (hasErrors.US)
      swal({
        text: "Cannot choose priority of none in categoryPosts in US"
      });
    return hasErrors.TW || hasErrors.US;
  };
  saveOrder = () => {
    if (!this.hasErrorCaheck()) {
      const { sections } = this.state;
      Object.keys(sections).forEach(country => {
        sections[country] = sections[country].map((section, index) => {
          section.weight = (sections[country].length - index) * 10;
          return section;
        });
      });
      // use this sections to commit
      editHomePageService.saveCardOrder(sections).then(data => {
        if (data.result === "ok") {
          this.getAllDatas();
          swal("Good job!", "You successfully edited", "success");
        }
      });
    }
  };
  // for getting initial data's
  getCategories = () => {
    return editHomePageService.getCategories().then(categories => {
      // this.setState({ categories });
      return categories;
    });
  };
  getZones = () => {
    return editHomePageService.getZones().then(data => {
      // this.setState({ zones: data[this.state.country] });
      return data[this.state.country];
    });
  };
  getCurrenetContent = () => {
    return editHomePageService.getCurrentHomePageContent().then(data => {
      // need to change TW or US
      // this.setState(preState => ({ sections: data }));
      return data;
    });
  };
  getAllDatas = () => {
    Promise.all([
      this.getCategories(),
      this.getZones(),
      this.getCurrenetContent()
    ]).then(values => {
      this.setState({
        categories: values[0],
        zones: values[1],
        sections: values[2]
      });
    });
  };
  // react func
  componentDidMount() {
    console.clear();
    // this.getCategories();
    // this.getZones();
    this.getAllDatas();
  }
  render() {
    const { sections, country } = this.state;
    return (
      <div>
        <Button onClick={() => this.changeCountry("TW")}>TW</Button>
        <Button onClick={() => this.changeCountry("US")}>US</Button>
        <div style={{ fontWeight: "bold" }}>
          You are Currenetly editing {this.state.country}
        </div>
        <div>
          {sections[country].map((section, index) => (
            <EditHomePageCard
              key={`${section.id}_${country}`}
              section={section}
              index={index}
              moveItem={this.moveItem}
              deleteItem={() => this.deleteItem(index)}
              editItem={this.editItem}
              categories={this.state.categories}
              zones={this.state.zones}
            />
          ))}
          <AddHomePageCard
            index={sections[country].length}
            moveItem={this.moveItem}
            addItem={this.addItem}
            country={this.state.country}
            categories={this.state.categories}
            zones={this.state.zones}
          />
        </div>
        <Button onClick={this.saveOrder}>save</Button>
      </div>
    );
  }
}

export default EditHomePageView;
