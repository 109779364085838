import { promoCodeConstants } from "../constants";

const initialState = { loading: false };

export function promoCode(state = initialState, action) {
  switch (action.type) {
    case promoCodeConstants.GET_ALL_REQUEST:
      return {
        loading: true
      };
    case promoCodeConstants.GET_ALL_SUCCESS:
      return {
        loading: false,
        promoCodes: action.promoCodes
      };
    case promoCodeConstants.GET_ALL_FAILURE:
      return {
        loading: false
      };
    case promoCodeConstants.GET_REQUEST:
      return {};
    case promoCodeConstants.GET_SUCCESS:
      return { detail: action.promoCode };
    case promoCodeConstants.GET_FAILURE:
      return {};
    case promoCodeConstants.GET_USERS_REQUEST:
      return {};
    case promoCodeConstants.GET_USERS_SUCCESS:
      return { drawingList: action.users };
    case promoCodeConstants.GET_USERS_FAILURE:
      return {};
    default:
      return state;
  }
}
