import React, { Component } from "react";
import { promoCodeActions } from "../../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Utility from "../../helpers/utility";
class PromoCodeTable extends Component {
  constructor(props) {
    super(props);
    this.state = { promoCodes: [] };
    const { dispatch } = this.props;
    dispatch(promoCodeActions.getAll());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.promoCodes) {
      this.setState({ promoCodes: nextProps.promoCodes });
    }
  }

  render() {
    return (
      <div className="content table-responsive table-full-width">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>PromoCode</th>
              <th>Title</th>
              <th>Type</th>
              <th>Created At</th>
              <th>Country</th>
              <th>Start At</th>
              <th>End At</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.promoCodes.map(el => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.data.title.zh_TW}</td>
                <td>{el.data.type}</td>
                <td>
                  {Utility.timestampToFormattedString(el.data.createdAt)
                    ? Utility.timestampToFormattedString(el.data.createdAt)
                    : "Not Set"}
                </td>
                <td>{el.data.country}</td>
                <td>
                  {Utility.timestampToFormattedString(el.data.startAt)
                    ? Utility.timestampToFormattedString(el.data.startAt)
                    : "Not Set"}
                </td>
                <td>
                  {Utility.timestampToFormattedString(el.data.endAt)
                    ? Utility.timestampToFormattedString(el.data.endAt)
                    : "Not Set"}
                </td>
                <td>
                  <Link to={`/main/promoCodes/view/${el.id}`}>
                    <button className="btn btn-info btn-fill">Detail</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

PromoCodeTable.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { promoCode } = state;
  return promoCode;
};
export default connect(mapStateToProps)(PromoCodeTable);
