import React, { Component } from "react";
// import { render } from "react-dom";
import { bannerActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Utility from "../helpers/utility";
import StorageImage from "../transactions/StorageImage.jsx";

class BannerTable extends Component {
  constructor(props) {
    super(props);
    this.state = { banners: [] };
    const { dispatch } = this.props;
    dispatch(bannerActions.getAll());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.banner && nextProps.banner.banners) {
      const banners = nextProps.banner.banners;
      this.setState({ banners });
    }
  }

  render() {
    // this.state.banners.map(el => {});
    return (
      <div className="content table-responsive table-full-width">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Title</th>
              <th>Banner</th>
              <th>Type</th>
              <th>Country</th>
              <th>Stauts</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.banners.map(el => (
              <tr key={el.id}>
                <td>
                  {el.data.metadata.en.title
                    ? el.data.metadata.en.title
                    : el.data.metadata.zh_TW.title}
                </td>
                <td>
                  <StorageImage
                    imageURL={
                      el.data.metadata.en.imageURL
                        ? el.data.metadata.en.imageURL
                        : el.data.metadata.zh_TW.imageURL
                    }
                    height="80px"
                  />
                </td>
                <td>{el.data.action}</td>
                <td>{el.data.country}</td>
                <td>{el.data.status}</td>
                <td>
                  {Utility.dateFromServer(el.data.startDate)
                    ? Utility.dateFromServer(el.data.startDate).toLocaleString()
                    : "Not Set"}
                </td>
                <td>
                  {Utility.dateFromServer(el.data.endDate)
                    ? Utility.dateFromServer(el.data.endDate).toLocaleString()
                    : "Not Set"}
                </td>
                <td>
                  <Link to={`/main/banners/view/${el.id}`}>
                    <button className="btn btn-info btn-fill">Detail</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

BannerTable.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { banner } = state;
  return { banner };
};
export default connect(mapStateToProps)(BannerTable);
