import React, { Component } from "react";
//import { userActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import NotificationTable from "./notificationTable.jsx";
import NotificationCreate from "./notificationCreate.jsx";

import {
  Card,
  CardBody
  // CardHeader,
  // CardTitle,
  // Button,
  // FormGroup,
  // Row,
  // Col,
  // Input,
  // Label
} from "reactstrap";
class NotificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleCreate() {}
  render() {
    return (
      <Card>
        <CardBody>
          <NotificationCreate />
          <br />

          <Route
            exact
            path="/main/notifications"
            component={NotificationTable}
          />
        </CardBody>
      </Card>
    );
  }
}

NotificationView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state;
};
export default connect(mapStateToProps)(NotificationView);
