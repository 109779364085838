import React, { Component } from "react";
import { connect } from "react-redux";
// import { transactionActions } from "../actions";
import { ecanOrderActions } from "../actions";
import swal from "sweetalert";

//TRS1514732408280
class TransactionEcanOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      takerName: "",
      takerPostCode: "",
      takerAddress: "",
      giverName: "",
      giverPostCode: "",
      giverAddress: ""
    };

    if (props.updatedEcanOrder) {
      this.state = props.updatedEcanOrder;
    } else {
      const { transaction, dispatch } = this.props;
      if (transaction) {
        dispatch(ecanOrderActions.get(transaction));
      }
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleOnClickUpdateOrder = this.handleOnClickUpdateOrder.bind(this);
  }

  // componentWillReceiveProps(nextProps) {
  //   const { order } = nextProps.ecanOrder;
  //   this.setState(order);
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.updatedEcanOrder !== prevState.updatedEcanOrder) {
      return nextProps.updatedEcanOrder;
    } else if (nextProps.ecanOrder && nextProps.ecanOrder.order)
      return nextProps.ecanOrder.order;
    else return null;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleOnClickUpdateOrder(e) {
    e.preventDefault();

    var order = this.state;

    var self = this;
    swal({
      title: "Update this carrier order?",
      text: "",
      icon: "warning",
      buttons: [
        {
          text: "Cancel",
          value: null,
          visible: true,
          //className: "btn btn-success btn-fill",
          closeModal: true
        },
        {
          text: "Update",
          value: true,
          visible: true,
          //className: "btn btn-danger btn-fill",
          closeModal: false
        }
      ],
      dangerMode: true,
      buttonsStyling: false
    }).then(function(confirm) {
      if (confirm) {
        const { dispatch, transaction } = self.props;

        dispatch(ecanOrderActions.update(order, transaction));
      }
    });
  }

  render() {
    const { loading } = this.props.ecanOrder;
    const order = this.state;

    var info = "";
    if (loading) {
      info = (
        <img
          alt="..."
          className="img-responsive center-block"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      );
    } else if (order && Object.keys(order).length > 0) {
      info = (
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <h5 className="col-sm-12">
                  Ecan Order (The order would be sending to the carrier)
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-sm-6">Taker Name</label>
                <input
                  type="text"
                  className="form-control border-input"
                  placeholder="Taker Address"
                  name="takerName"
                  value={this.state.takerName}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-sm-6">Post Code</label>
                <input
                  type="text"
                  className="form-control border-input"
                  placeholder="Post Code"
                  name="takerPostCode"
                  value={this.state.takerPostCode}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-sm-12">Taker Address</label>
                <input
                  type="text"
                  className="form-control border-input"
                  placeholder="Taker Address"
                  name="takerAddress"
                  value={this.state.takerAddress}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-sm-6">Giver Name</label>
                <input
                  type="text"
                  className="form-control border-input"
                  placeholder="Giver Address"
                  name="giverName"
                  value={this.state.giverName}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-sm-6">Post Code</label>
                <input
                  type="text"
                  className="form-control border-input"
                  placeholder="Post Code"
                  name="giverPostCode"
                  value={this.state.giverPostCode}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="col-sm-12">Giver Address</label>
                <input
                  type="text"
                  className="form-control border-input"
                  placeholder="Giver Address"
                  name="giverAddress"
                  value={this.state.giverAddress}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primiry btn-fill btn-wd pull-right"
              onClick={this.handleOnClickUpdateOrder}
            >
              Update
            </button>
            <br />
            <br />
          </div>
        </div>
      );
    }

    return <div>{info}</div>;
  }
}

const mapStateToProps = state => {
  const { ecanOrder } = state;
  return {
    ecanOrder
  };
};
export default connect(mapStateToProps)(TransactionEcanOrder);
