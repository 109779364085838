import { bannerConstants } from "../constants";
import { bannerService } from "../services";
import { alertActions } from "./";
import { userActions } from "../actions";
import { history } from "../helpers";

export const bannerActions = {
  create,
  getAll,
  get,
  update
};

function create(banner) {
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(banner));
    bannerService.create(banner).then(
      result => {
        dispatch(success(result));
        window.location = "/#/main/banners";
        history.push("/#/main/banners");
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request(banner) {
    return { type: bannerConstants.CREATE_REQUEST, banner };
  }
  function success(result) {
    return { type: bannerConstants.CREATE_SUCCESS, result };
  }
  function failure(error) {
    return { type: bannerConstants.CREATE_FAILURE, error };
  }
}

function update(id, banner, action) {
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(banner, action));
    // new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     reject(banner);
    //   }, 2000);
    // }).then(
    //   result => {
    //     banner.data.status = "rejected";

    //     dispatch(success(result));
    //   },
    //   err => {
    //     dispatch(failure(err));
    //     dispatch(alertActions.error("err"));
    //   }
    // );

    bannerService.update(id, banner).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request(banner, action) {
    return { type: bannerConstants.UPDATE_REQUEST, banner, action };
  }
  function success(banner) {
    return { type: bannerConstants.UPDATE_SUCCESS, banner, action };
  }
  function failure(error) {
    return { type: bannerConstants.UPDATE_FAILURE, error, action };
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());
    bannerService.getAll().then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: bannerConstants.GET_ALL_REQUEST };
  }
  function success(banners) {
    return { type: bannerConstants.GET_ALL_SUCCESS, banners };
  }
  function failure(error) {
    return { type: bannerConstants.GET_ALL_FAILURE, error };
  }
}

function get(id) {
  return dispatch => {
    dispatch(request());
    bannerService.get(id).then(
      banner => {
        dispatch(success(banner));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: bannerConstants.GET_REQUEST };
  }
  function success(banner) {
    return { type: bannerConstants.GET_SUCCESS, banner };
  }
  function failure(error) {
    return { type: bannerConstants.GET_FAILURE, error };
  }
}
