import React, { Component, useState, useEffect } from "react";
import { postService } from "../../services";
import {
  Row,
  Label,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { UserContent } from "../../components";
import { PostImage } from "../../components/PostGrid";
import swal from "sweetalert";
import Select from "react-select";
import { withRouter } from "react-router-dom";

const DeleteModal = props => {
  const [isVisible, setIsVisible] = useState(false);
  const [deleteReasons, setDeleteReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  useEffect(() => {
    postService.deleteReasonOption().then(reasons => setDeleteReasons(reasons));
  }, [deleteReasons.length]);
  const handleReasonChange = reason => {
    setSelectedReason(reason);
  };
  return (
    <>
      <Button onClick={() => setIsVisible(true)}>Delete Post</Button>
      <Modal isOpen={isVisible} toggle={() => setIsVisible(!isVisible)}>
        <ModalHeader>Delete Post</ModalHeader>
        <ModalBody>
          <Select
            options={deleteReasons}
            onChange={handleReasonChange}
            value={selectedReason}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (selectedReason) {
                props.deletePost(selectedReason.value);
                setIsVisible(!isVisible);
              } else {
                swal(
                  "Oops!",
                  "You need to select a reason to delete a post",
                  "error"
                );
              }
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={() => setIsVisible(!isVisible)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
const HideModal = props => {
  const [isVisible, setIsVisible] = useState(false);
  const [hideReasons, setHideReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  useEffect(() => {
    postService.hideReasonOption().then(reasons => setHideReasons(reasons));
  }, [hideReasons.length]);
  const handleReasonChange = reason => {
    setSelectedReason(reason);
  };
  return (
    <>
      <Button onClick={() => setIsVisible(true)}>Hide Post</Button>
      <Modal isOpen={isVisible} toggle={() => setIsVisible(!isVisible)}>
        <ModalHeader>Hide Post</ModalHeader>
        <ModalBody>
          <Select
            options={hideReasons}
            onChange={handleReasonChange}
            value={selectedReason}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (selectedReason) {
                props.hedePosts(selectedReason.value);
                setIsVisible(!isVisible);
              } else {
                swal(
                  "Oops!",
                  "You need to select a reason to hide a post",
                  "error"
                );
              }
            }}
          >
            Hide
          </Button>
          <Button color="secondary" onClick={() => setIsVisible(!isVisible)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
const DisplayRow = props => {
  return (
    <Row>
      <Label md="2" sm="2">
        {props.label}
      </Label>
      <Col md="6" sm="6">
        <div style={{ display: "flex", flexWrap: "wrap" }}>{props.value}</div>
      </Col>
    </Row>
  );
};

class PostDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: null
    };
  }
  getPost = () => {
    postService.getPostByID(this.props.match.params.id).then(data => {
      this.setState({ post: data });
    });
  };
  hidePost = reason => {
    postService
      .hidePost(this.props.match.params.id, reason)
      .then(data => {
        if (data.result === "ok") {
          swal("Good job!", "You successfully hide a post", "success");
          this.props.history.push("/main/posts");
        }
      })
      .catch(() => {
        swal("Oops!", "You fail to hide a post", "error");
      });
  };
  deletePost = reason => {
    postService
      .deletePost(this.props.match.params.id, reason)
      .then(data => {
        if (data.result === "ok") {
          swal("Good job!", "You successfully delete a post", "success");
          this.props.history.push("/main/posts");
        }
      })
      .catch(() => {
        swal("Oops!", "You fail to delete a post", "error");
      });
  };
  hideModal = () => {
    swal({
      text: 'hide reason".',
      content: "input",
      button: {
        text: "hide!",
        closeModal: false
      }
    }).then(reason => {
      if (reason) {
        this.hidePost(reason);
      } else {
        swal("Oops!", "You need to fill a reason", "error");
      }
    });
  };
  componentDidMount() {
    this.getPost();
  }
  render() {
    const { post } = this.state;
    return post ? (
      <div>
        post detail
        <DisplayRow label="title" value={post.title} />
        <DisplayRow label="status" value={post.status} />
        <DisplayRow label="id" value={post.id} />
        <DisplayRow label="country" value={post.country} />
        <br />
        <DisplayRow label="poster" value={<UserContent id={post.poster} />} />
        <br />
        <DisplayRow label="quantity" value={post.quantity} />
        {/* <DisplayRow
          label="thumbNail"
          value={<PostImage path={post.mediumThumbnailURL} />}
        />
        <br /> */}
        <DisplayRow label="isVisible" value={post.isVisible.toString()} />
        <DisplayRow label="isVendor" value={post.isVendor.toString()} />
        <DisplayRow label="remarks" value={post.remarks || "-"} />
        <DisplayRow
          label="images"
          value={post.imagesURL.map(image => (
            <PostImage path={image} key={image} />
          ))}
        />
        <DeleteModal deletePost={this.deletePost} />
        <HideModal hedePosts={this.hidePost} />
      </div>
    ) : (
      <div>loading ....</div>
    );
  }
}

export default withRouter(PostDetail);
