export const chatConstants = {
  GET_CHAT_RELATION: "GET_CHAT_RELATION",
  GET_CHAT_RELATION_SUCCESS: "GET_CHAT_RELATION_SUCCESS",
  GET_CHAT_RELATION_FAIL: "GET_CHAT_RELATION_FAIL",

  GET_CHAT_TEMPLATES: "GET_CHAT_TEMPLATES",
  GET_CHAT_TEMPLATES_SUCCESS: "GET_CHAT_TEMPLATES_SUCCESS",
  GET_CHAT_TEMPLATES_FAIL: "GET_CHAT_TEMPLATES_FAIL",

  ADD_CHAT_TEMPLATE: "ADD_CHAT_TEMPLATE",
  ADD_CHAT_TEMPLATE_SUCCESS: "ADD_CHAT_TEMPLATE_SUCCESS",
  ADD_CHAT_TEMPLATE_FAIL: "ADD_CHAT_TEMPLATE_FAIL",

  UPDATE_CHAT_TEMPLATE: "UPDATE_CHAT_TEMPLATE",
  UPDATE_CHAT_TEMPLATE_SUCCESS: "UPDATE_CHAT_TEMPLATE_SUCCESS",
  UPDATE_CHAT_TEMPLATE_FAIL: "UPDATE_CHAT_TEMPLATE_FAIL",

  DELETE_CHAT_TEMPLATE: "DELETE_CHAT_TEMPLATE",
  DELETE_CHAT_TEMPLATE_SUCCESS: "DELETE_CHAT_TEMPLATE_SUCCESS",
  DELETE_CHAT_TEMPLATE_FAIL: "DELETE_CHAT_TEMPLATE_FAIL",

  GET_CHAT_CONTENTS: "GET_CHAT_CONTENTS",
  GET_CHAT_CONTENTS_SUCCESS: "GET_CHAT_CONTENTS_SUCCESS",
  GET_CHAT_CONTENTS_FAIL: "GET_CHAT_CONTENTS_FAIL",

  TOOGLE_MULTI_CHATS_TARGET_ID: "TOOGLE_MULTI_CHATS_TARGET_ID",
  SET_SINGLE_CHAT_TARGET_ID: "SET_SINGLE_CHAT_TARGET_ID",
  CLEAR_ALL_MULTI_CHATS_TARGET_ID: "CLEAR_ALL_MULTI_CHATS_TARGET_ID",

  SENDING_MESSAGE_TO_A_CAHRT_TARGET: "SENDING_MESSAGE_TO_A_CAHRT_TARGET",
  SENDING_MESSAGE_TO_A_CAHRT_TARGET_SUCCESS:
    "SENDING_MESSAGE_TO_A_CAHRT_TARGET_SUCCESS",
  SENDING_MESSAGE_TO_A_CAHRT_TARGET_FAIL:
    "SENDING_MESSAGE_TO_A_CAHRT_TARGET_FAIL",

  NEW_MESSAGE_COMMING: "NEW_MESSAGE_COMMING",
  A_RELATION_MOVE_TO_TOP: "A_RELATION_MOVE_TO_TOP",

  BROADCASTING_A_MESSAGE: "BROADCASTING_A_MESSAGE",
  BROADCASTING_A_MESSAGE_SUCCESS: "BROADCASTING_A_MESSAGE_SUCCESS",
  BROADCASTING_A_MESSAGE_FAIL: "BROADCASTING_A_MESSAGE_FAIL",

  DELETE_A_MESSAGE: "DELETE_A_MESSAGE",
  DELETE_A_MESSAGE_SUCCESS: "DELETE_A_MESSAGE_SUCCESS",
  DELETE_A_MESSAGE_FAIL: "DELETE_A_MESSAGE_FAIL",
  RESET_CHAT_INIT_STATE: "RESET_CHAT_INIT_STATE"
};
