import { authHeader } from "../helpers";
export const notificationService = {
  getAll,
  send,
  getJobs,
  cancelSchedule,
};

export const SendType = {
  sendImediately: "sendImediately",
  testing: "testing",
  schedule: "schedule",
};

function getAll() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    host + "/api/admin/v1/officialNotifications",
    requestOptions
  ).then((response) => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function send(info, type) {
  var url;
  if (type == SendType.sendImediately)
    url = "/api/admin/v1/officialNotification";
  else if (type == SendType.testing)
    url = "/api/admin/v1/officialNotificationTest";
  else if (type == SendType.schedule) {
    url = "/api/admin/v1/scheduleOfficialNotification";
    info.sendAt = info.sendAt.toDate().getTime();
  } else return Promise.reject();

  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(info),
  };
  return fetch(host + url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
    .catch((err) => {
      return err.text().then((error) => {
        return Promise.reject({ status: err.status, error });
      });
    });
}

function getJobs() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    host + "/api/admin/v1/scheduledOfficialNotifications",
    requestOptions
  ).then((response) => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function cancelSchedule(id) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    host + "/api/admin/v1/scheduleOfficialNotification/" + id,
    requestOptions
  ).then((response) => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}
