import React, { Component } from "react";
// import { render } from "react-dom";
import { sponsorActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Route, Link } from "react-router-dom";
import ReactLoading from "react-loading";
import {
  // Card,
  // CardBody,
  // CardTitle,
  Button,
  FormGroup,
  // Row,
  // Col,
  Input,
  // Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledAlert,
} from "reactstrap";

class SponsorCreate extends Component {
  constructor(props) {
    super(props);
    this.state = { sponsorInfo: {}, loading: false };
  }

  componentWillReceiveProps(nextProps) {
    var state = {};
    if (nextProps.loading !== undefined) {
      state.loading = nextProps.loading;
    }
    if (nextProps.shouldDismiss) {
      state.shouldDismiss = nextProps.shouldDismiss;
      setTimeout(() => {
        this.props.handleToggleCreate();
      }, 2000);
    }
    if (nextProps.error) {
      state.error = nextProps.error;
      this.setState({ error: nextProps.error });
    }
    this.setState(state);
  }

  handleDidClickSave = () => {
    if (!this.state.sponsorInfo.displayName) return;
    if (!this.state.sponsorInfo.email) return;
    const { dispatch } = this.props;
    dispatch(
      sponsorActions.createSponsor({
        role: "sponsor",
        displayName: this.state.sponsorInfo.displayName,
        email: this.state.sponsorInfo.email,
      })
    );
  };

  handleTextChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      this.setState({
        sponsorInfo: { ...this.state.sponsorInfo, email: value },
      });
    } else if (name === "displayName") {
      this.setState({
        sponsorInfo: { ...this.state.sponsorInfo, displayName: value },
      });
    }
  };

  render() {
    return (
      <div>
        <ModalHeader> Create Sponsor</ModalHeader>
        {this.state.loading ? (
          <div className="loading-center">
            <ReactLoading
              type="spinningBubbles"
              color="#eee"
              height="100px"
              width="100px"
            />
          </div>
        ) : (
          <div>
            {this.state.shouldDismiss ? (
              <div>
                <span className="nc-icon nc-check-2" />
                <h3>Created Sponsor successfully</h3>
              </div>
            ) : (
              <ModalBody>
                {this.state.error && (
                  <UncontrolledAlert color="danger" fade={false}>
                    <span>
                      <b>Creating Sponsor failed</b>
                      <span
                        role="img"
                        aria-label={"請洽詢系統管理員🖕"}
                        aria-hidden={"false"}
                      >
                        請洽詢系統管理員🖕
                      </span>
                    </span>
                  </UncontrolledAlert>
                )}
                <FormGroup>
                  <Label for="displayName">Display Name</Label>
                  <Input
                    type="text"
                    name="displayName"
                    id="displayName"
                    placeholder="Enter Display Name"
                    value={this.state.sponsorInfo.displayName}
                    minLength="2"
                    onChange={this.handleTextChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email address</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter email account"
                    value={this.state.sponsorInfo.email}
                    email="true"
                    onChange={this.handleTextChange}
                  />
                </FormGroup>
              </ModalBody>
            )}
          </div>
        )}

        <ModalFooter>
          <div className="left-side">
            <Button
              color="link"
              onClick={this.props.handleToggleCreate}
              disabled={this.state.loading}
            >
              Cancel
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side">
            <Button
              color="link"
              onClick={this.handleDidClickSave}
              disabled={this.state.loading}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </div>
    );
  }
}

SponsorCreate.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { sponsor } = state;
  const { createSponsor } = sponsor;

  return createSponsor;
};
export default connect(mapStateToProps)(SponsorCreate);
