import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import ChatRelationCell from "./chatRelationCell";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { chatActions } from "../../actions";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { chatService } from "../../services";

const BroadCast = ({ broadcastMessage, multiChattingID }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  return (
    <div>
      <i
        className="ti-rss-alt"
        style={{ cursor: "pointer" }}
        onClick={() => setModalVisible(true)}
      >
        <span style={{ margin: "0 4px" }}>broadcast</span>
        <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)}>
          <ModalHeader>Modal title</ModalHeader>
          <ModalBody>
            <textarea
              cols="55"
              rows="4"
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalVisible(false)}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                broadcastMessage(message);
                setModalVisible(false);
              }}
            >
              Send
            </Button>
          </ModalFooter>
        </Modal>
      </i>
      <div>select {multiChattingID.length} user</div>
    </div>
  );
};

class ChatRelationsLists extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  scrollHitBottom = e => {
    const element = e.target;
    const { dispatch, relations } = this.props;
    // hit bottom
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      dispatch(
        chatActions.getChatRelations(relations[relations.length - 1].updatedAt)
      );
    }
  };
  componentDidMount() {
    this.chatRelationListener = chatService.chatRelationListener(snapshot => {
      this.props.relationReOrdering(snapshot);
    });
  }
  componentWillUnmount() {
    this.chatRelationListener.off();
  }
  render() {
    const {
      relations,
      dispatch,
      broadcastMessage,
      multiChattingID
    } = this.props;
    return (
      <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "58px"
          }}
        >
          <Button
            onClick={() => dispatch(chatActions.clearAllMultiChattingID())}
          >
            clear
          </Button>
          <BroadCast
            broadcastMessage={broadcastMessage}
            multiChattingID={multiChattingID}
          />
        </div>
        <div
          style={{ height: "calc(100% - 58px)", overflow: "auto" }}
          onScroll={this.scrollHitBottom}
        >
          {relations.map(relation => (
            <ChatRelationCell key={relation.id} relation={relation} />
          ))}
        </div>
      </div>
    );
  }
}

ChatRelationsLists.defaultProps = {
  relations: [],
  multiChattingID: []
};
ChatRelationsLists.propTypes = {
  relations: PropTypes.array.isRequired,
  broadcastMessage: PropTypes.func.isRequired,
  relationReOrdering: PropTypes.func.isRequired,
  multiChattingID: PropTypes.array.isRequired
};
const mapState2Props = state => {
  const { chatRelations, multiChattingID } = state.chat;
  return { relations: chatRelations, multiChattingID };
};
export default connect(mapState2Props)(ChatRelationsLists);
