import { mailingConstants } from "../constants";

const initialState = {};

export function mailing(state = initialState, action) {
  switch (action.type) {
    case mailingConstants.GET_EMAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case mailingConstants.GET_EMAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        mails: action.mails
      };
    case mailingConstants.GET_EMAILS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case mailingConstants.SEND_EMAIL_REQUEST:
      return { ...state, sending: true };
    case mailingConstants.SEND_EMAIL_SUCCESS:
      const { mails } = state;

      if (mails) {
        mails.unshift(action.mail);
      }
      return { ...state, sending: false, mails };
    case mailingConstants.SEND_EMAIL_FAILURE:
      return { ...state, sending: false };

    case mailingConstants.SCHEDULE_EMAIL_REQUEST:
      return { ...state, sending: true };
    case mailingConstants.SCHEDULE_EMAIL_SUCCESS:
      const { jobs } = state;
      if (jobs) {
        jobs.unshift(action.job);
      }
      return { ...state, sending: false, jobs: jobs || [action.job] };
    case mailingConstants.SCHEDULE_EMAIL_FAILURE:
      return { ...state, sending: false };

    case mailingConstants.GET_SCHEDULEDJOBS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case mailingConstants.GET_SCHEDULEDJOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: action.jobs
      };
    case mailingConstants.GET_SCHEDULEDJOBS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case mailingConstants.CANCEL_SCHEDULE_EMAIL_REQUEST:
      return { ...state, sending: true };
    case mailingConstants.CANCEL_SCHEDULE_EMAIL_SUCCESS: {
      const { jobs } = state;
      var newJobs = jobs;
      if (newJobs) {
        newJobs = jobs.filter(function(job) {
          return job.id != action.job.id;
        });
      }

      return { ...state, sending: false, jobs: newJobs };
    }

    case mailingConstants.CANCEL_SCHEDULE_EMAIL_FAILURE:
      return { ...state, sending: false };

    default:
      return state;
  }
}
