import { authHeader } from "../helpers";
import queryString from "query-string";

import firebase from "firebase";

export const chatService = {
  _getChatRelations(endPoint = new Date().valueOf()) {
    const db = firebase.database();
    return db
      .ref(`/chat_relations/${this.taptotOfficialUserId}`)
      .orderByChild("updatedAt")
      .endAt(endPoint - 1)
      .limitToLast(this.chatRelationLimit)
      .once("value")
      .then(relations => {
        const relationArr = [];
        relations.forEach(relation => {
          relationArr.unshift({ id: relation.key, ...relation.val() });
        });
        return relationArr;
      });
  },
  _getChatsContent(chatTargetId = "", endPoint = new Date().valueOf()) {
    const db = firebase.database();
    const roomName = this.getChatRoonID(chatTargetId);
    return db
      .ref(`/chats/${roomName}`)
      .orderByChild("createdAt")
      .endAt(endPoint - 1)
      .limitToLast(this.chatContentLimit)
      .once("value")
      .then(chats => {
        const chatsArr = [];
        chats.forEach(relation => {
          chatsArr.push({ id: relation.key, ...relation.val() });
        });
        return chatsArr;
      });
  },
  _getChatTemplates() {
    const db = firebase.database();
    return db
      .ref("wordings/chatTemplates")
      .once("value")
      .then(templates => {
        const templatArrr = [];
        templates.forEach(template => {
          templatArrr.push({ id: template.key, ...template.val() });
        });
        return templatArrr;
      });
  },
  _creteChatTemplate(message = "") {
    const db = firebase.database();
    return db
      .ref("wordings/chatTemplates")
      .push({
        message,
        createdAt: new Date().valueOf(),
        updatedAt: new Date().valueOf()
      })
      .then(() => ({ result: "ok" }));
  },
  _updateChatTemplate(templateId, message = "") {
    const db = firebase.database();
    return db
      .ref(`wordings/chatTemplates/${templateId}`)
      .update({
        message,
        updatedAt: new Date().valueOf()
      })
      .then(() => ({ result: "ok" }));
  },
  _deleteChatTemplate(templateId) {
    const db = firebase.database();
    return db
      .ref(`wordings/chatTemplates/${templateId}`)
      .remove()
      .then(() => ({ result: "ok" }));
  },
  _submitMessage(chatTargetId = "", message = "", type = "message") {
    const roomName = this.getChatRoonID(chatTargetId);
    const messageObj =
      type === "message"
        ? {
            createdAt: new Date().valueOf(),
            message,
            sender_name: this.taptotOfficialUserName,
            sender_uuid: this.taptotOfficialUserId
          }
        : {
            createdAt: new Date().valueOf(),
            message: "",
            imageURL: message,
            msgType: "IMAGE",
            sender_name: this.taptotOfficialUserName,
            sender_uuid: this.taptotOfficialUserId
          };
    const db = firebase.database();
    return db
      .ref(`chats/${roomName}`)
      .push(messageObj)
      .then(() => {
        const updatedAt = new Date().valueOf();
        // need to check if they are exist
        // updating relation
        db.ref(
          `chat_relations/${this.taptotOfficialUserId}/${chatTargetId}`
        ).update({
          updatedAt
        });
        // updating relation
        db.ref(
          `chat_relations/${chatTargetId}/${this.taptotOfficialUserId}`
        ).update({
          updatedAt
        });
        return { result: "ok" };
      });
  },
  chatRelationLimit: 10,
  chatContentLimit: 10,
  taptotOfficialUserId: process.env.REACT_APP_TAPTOT_OFFICIAL_USERID,
  taptotOfficialUserName: process.env.REACT_APP_TAPTOT_OFFICIAL_USER_NAME,
  getChatRoonID(chatTargetId = "") {
    return [chatTargetId, this.taptotOfficialUserId].sort().join("_");
  },
  chatContentListener(roomID = "", callback = snapshot => {}) {
    const db = firebase.database();
    let first = true;
    db.ref(`chats/${roomID}`)
      .limitToLast(1)
      .orderByChild("createdAt")
      .on("child_added", snapshot => {
        if (first) {
          first = false;
        } else {
          callback({ id: snapshot.key, ...snapshot.val() });
        }
      });
    return db.ref(`chats/${roomID}`);
  },
  chatRelationListener(callback = snapshot => {}) {
    const db = firebase.database();
    let first = true;
    db.ref(`chat_relations/${this.taptotOfficialUserId}`)
      .limitToLast(1)
      .orderByChild("updatedAt")
      .on("value", snapshots => {
        if (first) {
          first = false;
        } else {
          snapshots.forEach(snap => {
            callback({ id: snap.key, ...snap.val() });
          });
        }
      });
    return db.ref(`chat_relations/${this.taptotOfficialUserId}`);
  },
  getChatRelations(endPoint = new Date().valueOf()) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      startPoint: endPoint,
      limit: this.chatRelationLimit
    });
    return fetch(
      `${host}/api/admin/v1/chat/relations?${querrys}`,
      requestOptions
    )
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      })
      .then(relations => {
        const relationArr = [];
        relations.forEach(relation => {
          relationArr.push({ id: relation.id, ...relation.data });
        });
        return relationArr;
      });
  },
  getChatsContent(chatTargetId = "", endPoint = new Date().valueOf()) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      startPoint: endPoint,
      limit: this.chatContentLimit
    });
    return fetch(
      `${host}/api/admin/v1/chat/${chatTargetId}/messages?${querrys}`,
      requestOptions
    )
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      })
      .then(chats => {
        const chatsArr = [];
        chats.forEach(message => {
          chatsArr.push({ id: message.id, ...message.data });
        });
        return chatsArr;
      });
  },
  getChatTemplates() {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${host}/api/admin/v1/chat/templates`, requestOptions)
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      })
      .then(templates => {
        const templatArrr = [];
        templates.forEach(template => {
          templatArrr.unshift({ id: template.id, ...template.data });
        });
        return templatArrr;
      });
  },
  creteChatTemplate(message = "") {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({ message: message })
    };
    return fetch(`${host}/api/admin/v1/chat/template`, requestOptions).then(
      response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      }
    );
  },
  updateChatTemplate(templateId, message = "") {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "PUT",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({ message: message })
    };
    return fetch(
      `${host}/api/admin/v1/chat/template/${templateId}`,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  },
  deleteChatTemplate(templateId) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "DELETE",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(
      `${host}/api/admin/v1/chat/template/${templateId}`,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  },
  submitMessage(chatTargetId = "", message = "", type = "message") {
    const host = window.location.protocol + "//" + window.location.host;
    const bodyObj =
      type === "message"
        ? { message }
        : { message: "", imageURL: message, msgType: "IMAGE" };
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(bodyObj)
    };
    return fetch(
      `${host}/api/admin/v1/chat/${chatTargetId}/message`,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  },
  broadcastMessage(recipients = [], message = "", type = "message") {
    const host = window.location.protocol + "//" + window.location.host;
    const bodyObj =
      type === "message"
        ? { message, recipients }
        : { message: "", imageURL: message, msgType: "IMAGE", recipients };
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(bodyObj)
    };
    return fetch(`${host}/api/admin/v1/chat/broadcast`, requestOptions).then(
      response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      }
    );
  },
  leaveChatRoom(chatTargetId = "") {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(
      `${host}/api/admin/v1/chat/${chatTargetId}/leave`,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  },
  refDisconnect(chatTargetId = "") {
    const db = firebase.database();
    return db
      .ref(`chat_relations/${this.taptotOfficialUserId}/${chatTargetId}`)
      .onDisconnect()
      .update({ active: false, offlineAt: new Date().valueOf() });
  },
  deleteMessage(chatTargetId = "", messageId = "") {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "DELETE",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(
      `${host}/api/admin/v1/chat/${chatTargetId}/message/${messageId}`,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  },
  chatTotalUnreadListener(callback = () => {}) {
    const db = firebase.database();
    const unreadChatRef = `userRecords/${this.taptotOfficialUserId}/badges`;
    db.ref(unreadChatRef).on("value", snapshots => {
      callback(snapshots.val());
    });
    return db.ref(unreadChatRef);
  }
};
