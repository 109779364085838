import React, { Component } from "react";
import { communitiesService } from "../../services/communitiesService";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

class CommunitiesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communities: [],
      nextClick: 0,
      isLastPage: false,
      statusOption: communitiesService.statusOptions()[1],
      privacyOption: communitiesService.privacyOptions()[1]
    };
  }

  getCommunities = () => {
    // get the first page
    const { statusOption, privacyOption } = this.state;
    communitiesService
      .getCommunities(statusOption.value, privacyOption.value)
      .then(data => {
        this.setState({
          communities: data,
          nextClick: 0,
          isLastPage: data.length < communitiesService.pageLimit
        });
      });
  };
  getPrevioesPage = () => {
    const { communities, nextClick, statusOption, privacyOption } = this.state;
    if (nextClick <= 1) {
      this.getCommunities();
    } else {
      communitiesService
        .getCommunities(
          statusOption.value,
          privacyOption.value,
          communities[0].createdAt,
          "previous"
        )
        .then(data =>
          this.setState(preState => ({
            communities: data,
            nextClick: preState.nextClick - 1,
            isLastPage: data.length < communitiesService.pageLimit
          }))
        );
    }
  };
  getNextPage = () => {
    const { communities, statusOption, privacyOption } = this.state;
    communitiesService
      .getCommunities(
        statusOption.value,
        privacyOption.value,
        communities[communities.length - 1].createdAt
      )
      .then(data => {
        if (data.length > 0) {
          this.setState(preState => ({
            communities: data,
            nextClick: preState.nextClick + 1,
            isLastPage: data.length < communitiesService.pageLimit
          }));
        } else {
          this.setState({ isLastPage: true });
        }
      });
  };
  handleStatusChange = status => {
    this.setState({ statusOption: status }, this.getCommunities);
  };
  handlePrivacyChange = privacy => {
    this.setState({ privacyOption: privacy }, this.getCommunities);
  };
  componentWillMount() {
    this.getCommunities();
  }
  render() {
    return (
      <div className="content table-responsive table-full-width">
        <label>Status</label>
        <Select
          options={communitiesService.statusOptions()}
          onChange={this.handleStatusChange}
          value={this.state.statusOption}
        />
        <br />
        <label>Privacy</label>
        <Select
          options={communitiesService.privacyOptions()}
          onChange={this.handlePrivacyChange}
          value={this.state.privacyOption}
        />
        <br />
        <table className="table table-striped">
          <thead>
            <tr>
              <th>community id</th>
              <th>name</th>
              <th>privacy</th>
              <th>announcement</th>
              <th>owner</th>
              <th>status</th>
              <th>detail</th>
            </tr>
          </thead>
          <tbody>
            {this.state.communities.map(el => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.name}</td>
                <td>{el.privacy ? "public" : "private"}</td>
                <td>{el.announcement}</td>
                <td>{el.owner}</td>
                <td>{communitiesService.statusConverter(el.status)}</td>
                <td>
                  <Link to={`/main/communities/detail/${el.id}`}>
                    <Button>detail</Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button onClick={this.getPrevioesPage}>
          {this.state.nextClick ? "previous" : "refresh"}
        </Button>
        <Button onClick={this.getNextPage} disabled={this.state.isLastPage}>
          next
        </Button>
        <span style={{ margin: "0 8px", fontSize: "20px" }}>
          Page {this.state.nextClick + 1}
        </span>
      </div>
    );
  }
}

export default CommunitiesTable;
