export const ecanOrderConstants = {

    GET_REQUEST: 'ECANORDER_GET_REQUEST',
    GET_SUCCESS: 'ECANORDER_GET_SUCCESS',
    GET_FAILURE: 'ECANORDER_GET_FAILURE',

    UPDATE_REQUEST: 'ECANORDER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ECANORDER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ECANORDER_UPDATE_FAILURE'
  
};