import { combineReducers } from "redux";
import { authentication } from "./authenticationReducer";
import { users } from "./userReducer";
import { alert } from "./alertReducer";
import { transactions } from "./transactionReducer";
import { ecanOrder } from "./ecanOrderReducer";
import { payment } from "./paymentReducer";
import { ratings } from "./ratingsReducer";
import { banner } from "./bannerReducer";
import { sponsor } from "./sponsorReducer";
import { dashboard } from "./dashboardReducer";
// import { roles } from "./rolesReducer";
import { chat } from "./chatReducer";
import { promoCode } from "./promoCodeReducer";
import { notification } from "./notificationReducer";
import { mailing } from "./mailingReducer";

// const initialState = {
//   transactions: [{title:'shit', status : 'ok'}, {title:'eat', status : 'cancelled'},{title:'holly', status : 'cancelled'}],
//   authentication,
//   users,
//   alert
// };

const rootReducer = combineReducers({
  transactions,
  authentication,
  users,
  alert,
  ecanOrder,
  payment,
  ratings,
  banner,
  sponsor,
  dashboard,
  promoCode,
  notification,
  chat,
  mailing
});

// const rootReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case ADD_ARTICLE:
//       return { ...state, articles: [...state.articles, action.payload] };
//     default:
//       return state;
//   }
// }
export default rootReducer;
