import React, { Component } from "react";
// import { render } from "react-dom";
// import { userActions, sponsorActions } from "../../actions";
import { sponsorActions } from "../../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Route, Link } from "react-router-dom";
import moment from "moment";
import { Row, Col, CardBody, Label, Alert } from "reactstrap";

class SponsorRequestDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { applications: [], application: {} };
    if (
      this.props.sponsor.applications &&
      this.props.sponsor.applications.length > 0
    ) {
      var applications = this.props.sponsor.applications.filter(el => {
        return el.data.numeric_id === this.props.match.params.id;
      });

      if (applications.length > 0) {
        this.state = {
          application: applications[0]
        };
      }
    } else if (this.props.sponsor.application) {
      this.state = {
        application: this.props.sponsor.application
      };
    } else {
      if (this.props.match.params && this.props.match.params.id) {
        const { dispatch } = this.props;
        dispatch(sponsorActions.getApplication(this.props.match.params.id));
      }
    }

    this.handleClickApprove = this.handleClickApprove.bind(this);
    this.handleClickReject = this.handleClickReject.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sponsor) {
      var nextState = {};
      if (nextProps.sponsor.application) {
        nextState.application = nextProps.sponsor.application;
      }
      if (nextProps.sponsor.loading !== undefined) {
        nextState.loading = nextProps.sponsor.loading;
      }
      if (nextProps.sponsor.approved !== undefined) {
        nextState.approved = nextProps.sponsor.approved;
      }
      this.setState(nextState);
    }

    if (nextProps.alert.message) {
      this.setState({ alertMessage: nextProps.alert.message });
    } else {
      this.setState({ alertMessage: undefined });
    }
  }

  handleClickApprove(role) {
    const { application } = this.state;
    const { dispatch } = this.props;
    dispatch(sponsorActions.updateApplication(application, true, role));
  }

  handleClickReject() {
    const { application } = this.state;
    const { dispatch } = this.props;
    dispatch(sponsorActions.updateApplication(application, false));
  }

  render() {
    const { application } = this.state;
    var data = {};
    if (application.data) data = application.data;
    const timestamp = data.createdAt;
    const formatted = moment(timestamp).format("LLLL");
    data.formattedCreatedAt = formatted;
    return (
      <div>
        <CardBody>
          {this.state.alertMessage && (
            <Alert color="danger">{this.state.alertMessage}</Alert>
          )}
          <h4 className="card-title">{data.numeric_id}</h4>
          <Row>
            <Col>
              <Label>Applyer</Label>
              <div>{data.companyName}</div>
            </Col>
            <Col>
              <Label>contactName</Label>
              <div>{data.contactName}</div>
            </Col>
            <Col>
              <Label>Email</Label>
              <div>{data.email}</div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>Phone</Label>
              <div>{data.phone}</div>
            </Col>
            <Col>
              <Label>Type</Label>
              <div>{data.sponsorType}</div>
            </Col>
            <Col>
              <Label>Offering</Label>
              <div>{data.offering}</div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>Website</Label>
              <div>{data.website}</div>
            </Col>
            <Col>
              <Label>Apply Date</Label>
              <div>{data.formattedCreatedAt}</div>
            </Col>
            <Col>
              <Label>Status</Label>
              <div>{data.status}</div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Label>Message/questions</Label>
              <p>{data.questions}</p>
            </Col>
          </Row>
          {data.status === "pending" && (
            <>
              <button
                type="Edit"
                onClick={() => this.handleClickApprove("sponsor")}
                className="btn btn-info btn-fill btn-wd"
              >
                Approve as sponsor
                {this.state.loading &&
                  this.state.approved &&
                  this.state.approved === true && (
                    <img
                      alt="..."
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                    />
                  )}
              </button>
              <button
                type="Edit"
                className="btn btn-info btn-fill btn-wd"
                onClick={() => this.handleClickApprove("NPO")}
              >
                approve as NPO
              </button>
              <button
                type="Edit"
                onClick={this.handleClickReject}
                className="btn btn-info btn-fill btn-wd"
              >
                Reject
                {this.state.loading &&
                  this.state.approved !== undefined &&
                  this.state.approved === false && (
                    <img
                      alt="..."
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                    />
                  )}
              </button>
            </>
          )}
        </CardBody>
      </div>
    );
  }
}

SponsorRequestDetail.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { sponsor, alert } = state;
  return { sponsor, alert };
};
export default connect(mapStateToProps)(SponsorRequestDetail);
