import React, { Component } from "react";
import { Spring, Trail, animated, Keyframes } from "react-spring/renderprops";
import delay from "delay";

const test = () => {
  return Promise.resolve("ggygygygygy");
};
const test2 = async () => {
  await delay(2000);
  const result = await test();
  console.log(result);
  return result + "87878787";
};

const items = [{ key: "ggg", text: "t1" }, { key: "g2g", text: "t2" }];
class ChatAnimateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogle: false
    };
  }
  toggle = () => {
    console.log("toogle");
    this.setState(preState => ({ toogle: !preState.toogle }));
  };
  render() {
    const { toogle } = this.state;
    test2().then(data => {
      console.log(data);
    });
    return (
      <div>
        chatViewwwww
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
          {springProps => (
            <div style={{ ...springProps, fontSize: "20px" }}>hello</div>
          )}
        </Spring>
        <Trail
          items={items}
          keys={item => item.key}
          from={{ transform: "translate3d(-30px,0,0)", opacity: "0" }}
          to={{
            transform: toogle
              ? "translate3d(-30px,0,0)"
              : "translate3d(0px,0,0)",
            opacity: toogle ? "0" : "1"
          }}
          reverse={this.state.toogle}
        >
          {item => props => (
            <animated.div style={props} onClick={this.toggle}>
              {item.text}
            </animated.div>
          )}
        </Trail>
      </div>
    );
  }
}

export default ChatAnimateView;
