import React from "react";
import { connect } from "react-redux";
// import SlideBar from "./slideBar.jsx";

import { Route } from "react-router-dom";
import { Row, Col } from "reactstrap";
import TransactionsView from "../transactions/transactionsView.jsx";
// import SlideItem from "./slideItem.jsx";
// import NavBar from "./navBar.jsx";
import Footer from "./footer.jsx";
import UsersView from "../users/usersView.jsx";
import BannersView from "../officialBanners/bannersView.jsx";
import PromoCodesView from "../views/promoCodes/promoCodeView.jsx";
import NotificationView from "../views/notifications/notificationView.jsx";
import MailingView from "../views/mailing/mailingView.jsx";
import DashboardView from "../dashboard/dashboardView.jsx";
import BannerCreateView from "../officialBanners/bannerCreation.jsx";
import SponsorRequestsView from "../sponsors/applications/sponsorRequestsView.jsx";
import SponsorsView from "../sponsors/sponsorsView.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import AdminNavbar from "../components/Navbar/AdminNavbar.jsx";
// import CreatePromoCodesView from "../views/promoCodes/createPromoCodeView";
import CreatePromoCodesView from "../views/promoCodes/createPromoCodeView.js";
import CreateZonesView from "../views/zones/createZonesView";
import ZonesView from "../views/zones/zonesView";
import routes from "../../routes.js";
import { authActions } from "../actions";
import { EditHomePageView } from "../views/editHomePage";
import { ZeroCarbonFashionView } from "../views/tmpEvent";
import { CommunitiesView } from "../views/communities";
import { PostView } from "../views/posts";
import { ChatView } from "../views/chats";

import { chatService } from "../services";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-notifications/lib/notifications.css";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = this.props;
    this.state = {
      badges: {
        // key will be the same as routes.js route name,
        // value is a number
      }
    };
    dispatch(authActions.auth());
  }
  setBadges2SideBar = (routeName, value) => {
    const routesArr = routes(this.props.role);
    const hasRoute =
      routesArr.filter(route => route.name === routeName).length > 0;
    if (hasRoute) {
      this.setState(prevState => ({
        ...prevState,
        badges: Object.assign({}, prevState.badges, { [routeName]: value })
      }));
    } else {
      console.error(
        "router.js does not have this route name, remember to add hasBadge: true, to show badge in sidebar"
      );
    }
  };
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(authActions.fetch());
    this.unreadChatListener = chatService.chatTotalUnreadListener(badges => {
      NotificationManager.info(`you have ${badges.chat} message unread`);
      this.setBadges2SideBar("Chats", badges.chat);
    });
  }

  handleSelect(e, name) {
    this.setState(prevState => ({
      activeItem: name
    }));
  }

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  getRoutes = routes => {
    return routes(this.props.role).map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  componentWillUnmount() {
    this.unreadChatListener.off();
  }

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes(this.props.role)}
          badges={this.state.badges}
        />
        <div className="main-panel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <div className="content">
            <Row>
              <Col md="12">
                <Route exact path="/" component={DashboardView} />
                <Route path={`/main/dashboard`} component={DashboardView} />
                <Route
                  path={`/main/transactions`}
                  component={TransactionsView}
                />
                <Route path={`/main/users`} component={UsersView} />
                <Route path={`/main/promoCodes`} component={PromoCodesView} />
                <Route
                  path={`/main/createBanner`}
                  component={BannerCreateView}
                />
                <Route path={`/main/banners`} component={BannersView} />
                <Route
                  path={`/main/notifications`}
                  component={NotificationView}
                />
                <Route path={`/main/mailing`} component={MailingView} />
                <Route
                  path={`/main/sponsorRequests`}
                  component={SponsorRequestsView}
                />
                <Route path={`/main/sponsors`} component={SponsorsView} />
                <Route
                  path={`/main/editHomePage`}
                  component={EditHomePageView}
                />
                <Route
                  path={`/main/createPromoCodes`}
                  component={CreatePromoCodesView}
                />
                <Route path={`/main/zones`} component={ZonesView} />
                <Route path={`/main/createZones`} component={CreateZonesView} />
                <Route
                  path={`/main/tmpEvent`}
                  component={ZeroCarbonFashionView}
                />
                <Route path={`/main/communities`} component={CommunitiesView} />
                <Route path={`/main/posts`} component={PostView} />
                <Route path={`/main/chats`} component={ChatView} />
                <NotificationContainer />
                {/* <Route
                    path={`/main/roles`}
                    component={}
                  /> */}
              </Col>
            </Row>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, role } = state.authentication;
  return {
    loggingIn,
    role
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
