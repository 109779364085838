export const ratingsConstants = {

    GET_REQUEST: 'RATINGS_GET_REQUEST',
    GET_SUCCESS: 'RATINGS_GET_SUCCESS',
    GET_FAILURE: 'RATINGS_GET_FAILURE',

    UPDATE_REQUEST: 'RATINGS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'RATINGS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'RATINGS_UPDATE_FAILURE',

    DELETE_REQUEST: 'RATINGS_DELETE_REQUEST',
    DELETE_SUCCESS: 'RATINGS_DELETE_SUCCESS',
    DELETE_FAILURE: 'RATINGS_DELETE_FAILURE'

};