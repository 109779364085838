import React, { Component, useState, useEffect } from "react";
import { ChatMessage } from "./index";
import { Input } from "reactstrap";
import chatImg from "../../../assets/img/chat_background.jpg";
import FileUploader from "react-firebase-file-uploader";
import firebase from "firebase";
import PropTypes from "prop-types";
import { chatService } from "../../services";
import { DropTarget } from "react-dnd";

const dropSpec = {
  drop: (props, monitor, component) => {
    component.dropChatTemplateMessage(monitor.getItem().message);
    return { droppingMessage: monitor.getItem().message };
  }
};
const dropCollect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};
const TextingBar = ({ roomID, messageSendingFunc, dropChattingTemplate }) => {
  const [inputValue, setInputValue] = useState("");
  // when soneones drop drop template modify input
  useEffect(() => {
    setInputValue(inputValue + dropChattingTemplate);
  }, [dropChattingTemplate]);

  const handleUploadSuccess = fileName => {
    // sending messages
    // alert(`success path: chat_images/${roomID}/${fileName}`);
    const imageUrl = `chat_images/${roomID}/${fileName}`;
    messageSendingFunc(imageUrl, "image");
    // send image back
  };
  const sendMessage = () => {
    // sending message and clear input
    messageSendingFunc(inputValue);
    setInputValue("");
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "8px",
        backgroundColor: "#ccc"
      }}
    >
      <label
        style={{
          cursor: "pointer",
          fontSize: "19px",
          margin: "0 4px",
          color: "#fff",
          backgroundColor: "#000",
          borderRadius: "50%",
          width: "30px",
          textAlign: "center"
        }}
      >
        <i className="ti-image" />
        <FileUploader
          hidden
          accept="image/*"
          name="chat_images"
          randomizeFilename
          storageRef={firebase.storage().ref(`chat_images/${roomID}`)}
          // onUploadStart={this.handleUploadStart}
          // onUploadError={this.handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          // onProgress={this.handleProgress}
        />
      </label>
      <Input value={inputValue} onChange={e => setInputValue(e.target.value)} />
      {inputValue && (
        <i
          className="ti-announcement"
          style={{ cursor: "pointer", fontSize: "19px", margin: "0 4px" }}
          onClick={() => sendMessage()}
        />
      )}
    </div>
  );
};
class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropChattingTemplate: ""
    };
    this.messageEndRef = React.createRef();
    this.messageContainerRef = React.createRef();
    this.oldMessgeContainerHeight = 0;
    this.finishFirstScroll = false;
  }
  scrollHitTop = e => {
    const { chatContents } = this.props;
    if (this.messageContainerRef.current.scrollTop === 0) {
      this.props.querryOlderMessage(chatContents[0].createdAt);
    }
  };
  scrollToBottom = () => {
    this.messageEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  componentDidMount() {
    this.scrollToBottom();
    this.oldMessgeContainerHeight = this.messageContainerRef.current.scrollHeight;
    if (this.props.roomID) {
      this.chatListener = chatService.chatContentListener(
        this.props.roomID,
        newMessage => {
          this.props.newMessageComming(newMessage);
        }
      );
      console.clear();
      console.log(this.props.chattingID);
      chatService.refDisconnect(this.props.chattingID);
    }
  }
  dropChatTemplateMessage = message => {
    console.log("message!!!", message);
    if (this.props.roomID) {
      this.setState({ dropChattingTemplate: message });
    }
  };
  // process scroll effect
  componentDidUpdate(prevProps) {
    const messageContainerHeight = this.messageContainerRef.current
      .scrollHeight;
    // first time get messages scrolling to bottom
    if (
      this.props.chatContents.length !== prevProps.chatContents.length &&
      this.finishFirstScroll === false
    ) {
      this.scrollToBottom();
      this.finishFirstScroll = true;
      this.oldMessgeContainerHeight = messageContainerHeight;
    } else {
      // else is scrolling to top scroll to diff hieght
      if (
        this.props.chatContents.length > prevProps.chatContents.length &&
        this.oldMessgeContainerHeight !== messageContainerHeight &&
        this.props.chatContents[0].id !== prevProps.chatContents[0].id
      ) {
        this.messageContainerRef.current.scroll({
          top: messageContainerHeight - this.oldMessgeContainerHeight - 50 // 50 here is a magic number hehe
        });
        this.oldMessgeContainerHeight = messageContainerHeight;
      }
      // else scrolling to bottom has new message
      if (
        this.props.chatContents.length > prevProps.chatContents.length &&
        this.oldMessgeContainerHeight !== messageContainerHeight &&
        this.props.chatContents[this.props.chatContents.length - 1].id !==
          prevProps.chatContents[prevProps.chatContents.length - 1].id
      ) {
        this.scrollToBottom();
        this.oldMessgeContainerHeight = messageContainerHeight;
      }
    }
  }
  componentWillUnmount() {
    if (this.props.roomID) {
      this.chatListener.off();
    }
  }
  render() {
    const { chatContents, roomID, connectDropTarget, isOver } = this.props;
    return connectDropTarget(
      <div
        style={{
          height: "100%",
          margin: "0 8px",
          border: isOver ? "2px solid red" : "2px solid transparent"
        }}
      >
        <div
          style={{
            backgroundImage: `url(${chatImg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            padding: "20px 0",
            borderRadius: "8px 8px 0 0",
            height: "calc(100% - 32px - 8*2px)",
            overflow: "auto"
          }}
          onScroll={this.scrollHitTop}
          ref={this.messageContainerRef}
        >
          {chatContents.map(chat => (
            <ChatMessage
              key={chat.id}
              message={chat}
              isOwn={
                chat.sender_uuid ===
                process.env.REACT_APP_TAPTOT_OFFICIAL_USERID
              }
              avatar={
                chat.sender_uuid ===
                process.env.REACT_APP_TAPTOT_OFFICIAL_USERID
                  ? this.props.selfAvatar
                  : this.props.chattingAvatar
              }
            />
          ))}
          <div ref={this.messageEndRef} />
        </div>
        <TextingBar
          roomID={roomID}
          messageSendingFunc={this.props.messageSendingFunc}
          dropChattingTemplate={this.state.dropChattingTemplate}
        />
      </div>
    );
  }
}

ChatRoom.propTypes = {
  roomID: PropTypes.string.isRequired,
  chatContents: PropTypes.array.isRequired,
  chattingAvatar: PropTypes.string.isRequired,
  selfAvatar: PropTypes.string.isRequired,
  messageSendingFunc: PropTypes.func.isRequired,
  newMessageComming: PropTypes.func.isRequired,
  querryOlderMessage: PropTypes.func.isRequired,
  chattingID: PropTypes.string.isRequired
};
export default DropTarget("chatTemplateMessage", dropSpec, dropCollect)(
  ChatRoom
);
