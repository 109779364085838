import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import moment from "moment";
import DateTimeSelectComponent from "../../components/dateTimeSelectComponent";
import { StyledDropZone } from "react-drop-zone";
import "react-drop-zone/dist/styles.css";

import {
  // Card,
  // CardBody,
  // CardHeader,
  CardTitle,
  Button,
  FormGroup,
  Row,
  Col,
  Input,
  Label
} from "reactstrap";
import { mailingActions } from "../../actions";
class MailingCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      subject: "",
      subject_en: "",
      subject_zh_TW: "",
      country: {
        value: "TW",
        label: "Taiwan"
      },
      error: { show: false },
      sendAt: moment().add(1, "minute"),
      testingEmail: ""
    };
  }

  handleDateChange = date => {
    this.setState({ sendAt: date.seconds(0) });
  };

  handleCreate() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications || nextProps.jobs) {
      this.setState({
        name: "",
        subject: "",
        subject_en: "",
        subject_zh_TW: "",
        country: {
          value: "TW",
          label: "Taiwan"
        },
        error: { show: false }
      });
    }

    if (this.sendingSwal && !nextProps.sending) {
      swal.close();
      this.sendingSwal = undefined;
    }
  }

  submitNoti = (isTesting, isScheduling) => {
    let swalTitle = isTesting
      ? "This is in Test Mode."
      : "Are you sure to send this mail?";
    if (isScheduling) swalTitle = "Are you sure to schedule this mail?";
    let swalSubtitle = isTesting
      ? "Please check your Taptot DEV app."
      : "This cannot be undo!\n請仔細檢查所有文案是否正確";
    if (isScheduling) swalSubtitle = "Please select a scheduling time.";

    this.setState({ error: { show: false } });

    if (this.state.name.length === 0) {
      this.setState({ error: { show: true, reason: "Name cannot be empty " } });
      return;
    }

    if (this.state.subject.length === 0) {
      this.setState({
        error: { show: true, reason: "Subject cannot be empty " }
      });
      return;
    }

    // if (this.state.subject_en.length === 0) {
    //   this.setState({
    //     error: { show: true, reason: "Content(English) cannot be empty " }
    //   });
    //   return;
    // }

    // if (this.state.subject_zh_TW.length === 0) {
    //   this.setState({
    //     error: { show: true, reason: "Content(Chinese) cannot be empty " }
    //   });
    //   return;
    // }

    if (!this.state.template || this.state.template.length === 0) {
      this.setState({
        error: { show: true, reason: "Template cannot be empty " }
      });
      return;
    }

    if (isTesting && this.state.testingEmail.length === 0) {
      this.setState({
        error: { show: true, reason: "Testing Email cannot be empty " }
      });
      return;
    }

    if (isTesting && this.state.testingEmail.length === 0) {
      this.setState({
        error: { show: true, reason: "Testing Email cannot be empty " }
      });
      return;
    }
    if (
      isTesting &&
      !this.state.testingEmail.match(/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)
    ) {
      this.setState({
        error: {
          show: true,
          reason: "Testing Email is not in a correct format "
        }
      });
      return;
    }

    if (this.isScheduling && this.sendAt < moment()) {
      this.setState({
        error: {
          show: true,
          reason: "Schedule time cannot be earlier than current time "
        }
      });
      return;
    }

    let wrapper = document.createElement("div");
    ReactDOM.render(
      <DateTimeSelectComponent
        value={this.state.sendAt}
        onChange={this.handleDateChange}
        isValidDate={this.handleStartValidDate}
      />,
      wrapper
    );
    let el = wrapper.firstChild;

    var self = this;
    this.sendingSwal = swal({
      title: swalTitle,
      text: swalSubtitle,
      content: isScheduling ? el : undefined,
      icon: "warning",
      buttons: [
        {
          text: "Cancel",
          value: null,
          visible: true,
          closeModal: true
        },
        {
          text: isScheduling ? "Schedule" : "Send",
          value: true,
          visible: true,
          closeModal: false
        }
      ],
      dangerMode: true,
      buttonsStyling: false
    }).then(function(confirm) {
      if (confirm) {
        if (isScheduling && self.state.sendAt < moment()) {
          self.setState({
            error: {
              show: true,
              reason: "Schedule time cannot be earlier than current time "
            }
          });
          swal.close();
          return;
        }

        const { dispatch } = self.props;
        var info = {
          name: self.state.name,
          subject: self.state.subject,
          // subject: {
          //   en: self.state.subject_en,
          //   zh_TW: self.state.subject_zh_TW
          // },
          country: self.state.country.value,
          template: self.state.template
        };
        if (self.state.tag) info.tag = self.state.tag;
        if (
          isTesting &&
          self.state.testingEmail &&
          self.state.testingEmail.length > 0
        )
          info.testingEmail = self.state.testingEmail;

        var templateEle = document.querySelector("#capture");
        var positionInfo = templateEle.getBoundingClientRect();
        info.templateWidth = positionInfo.width;
        info.templateHeight = positionInfo.height;

        if (isTesting) {
          dispatch(mailingActions.sendTest(info));
          swal.close();
        } else if (isScheduling) {
          if (self.state.sendAt) info.sendAt = self.state.sendAt;
          dispatch(mailingActions.schedule(info));
        } else dispatch(mailingActions.send(info));
      }
    });
  };

  handleSubmit = () => {
    this.submitNoti(false);
  };

  handleSelectFile = () => {};

  handleTest = () => {
    this.submitNoti(true);
  };

  handleSchedule = () => {
    this.submitNoti(false, true);
  };

  handleTextChange = e => {
    const { name, value } = e.target;
    var info = {};
    info[name] = value;
    this.setState(info);
  };

  handleCountryChange = info => {
    this.setState({
      country: info,
      banner: {
        ...this.state.banner,
        country: info.value
      }
    });
  };

  addFile = (file, text) => {
    //this.setState({ files: [...this.state.files, file] })
    // console.log(" add text   ", text);
    if (file.type == "text/html") {
      var reader = new FileReader();
      let self = this;
      reader.onload = function(event) {
        // The file's text will be printed here
        //console.log(event.target.result);
        self.setState({ template: event.target.result });
      };

      reader.readAsText(file);
    } else {
      alert("Hello!? This is not in a html format");
    }
  };

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="12">
            {this.state.error.show && (
              <div className="alert alert-warning" role="alert">
                {this.state.error.reason}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm="10" md="6">
            <FormGroup>
              <CardTitle>Name</CardTitle>
              <Input
                type="text"
                name="name"
                className="form-control"
                value={this.state.name}
                onChange={this.handleTextChange}
                placeholder="Name your notification"
              />
              <br />
              <CardTitle>Tag</CardTitle>
              <Input
                type="text"
                name="tag"
                className="form-control"
                value={this.state.tag}
                onChange={this.handleTextChange}
                placeholder="Setup your tag (No unicode characters)"
              />
              <br />
              <CardTitle>Subject</CardTitle>
              <Input
                type="text"
                name="subject"
                className="form-control"
                value={this.state.subject}
                onChange={this.handleTextChange}
                placeholder="Enter subject"
              />
              <br />
              {/* <Input
                type="text"
                name="subject_en"
                className="form-control"
                value={this.state.subject_en}
                onChange={this.handleTextChange}
                placeholder="English"
              />
              <br />
              <Input
                type="text"
                name="subject_zh_TW"
                className="form-control"
                value={this.state.subject_zh_TW}
                onChange={this.handleTextChange}
                placeholder="Chinese"
              /> */}
            </FormGroup>
          </Col>
          <Col md="4">
            <CardTitle>Country</CardTitle>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              id="SelectCountry"
              value={this.state.country}
              onChange={this.handleCountryChange}
              options={[
                {
                  value: "TW",
                  label: "Taiwan"
                },
                { value: "US", label: "United States" }
              ]}
            />
            <br />
            <CardTitle>Testing Email</CardTitle>
            <Input
              type="email"
              name="testingEmail"
              className="form-control"
              value={this.state.testingEmail}
              onChange={this.handleTextChange}
              placeholder="Email"
            />
            <br />
            <Button onClick={this.handleTest}>Test</Button>
            <Button onClick={this.handleSubmit} color="warning">
              Submit
            </Button>
            {/* <Button onClick={this.handleSchedule} color="primary">
              Schedule
            </Button> */}
          </Col>
        </Row>
        <Row>
          <Col sm="6" md="6">
            <div>
              <CardTitle>Template</CardTitle>
              <StyledDropZone onDrop={this.addFile} />
            </div>
          </Col>
          <Col sm="6" md="6">
            <div
              id="capture"
              dangerouslySetInnerHTML={{ __html: this.state.template }}
            />
          </Col>
        </Row>
      </>
    );
  }
}

MailingCreate.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { mailing } = state;
  return mailing;
};
export default connect(mapStateToProps)(MailingCreate);
