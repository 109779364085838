import React from "react";
import PropTypes from "prop-types";
import { AddTemplate, ChatTemplateCell } from "./index";
import { connect } from "react-redux";

const ChatTemplateLists = ({ templates }) => {
  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "calc(100% - 35px - 2*4px)", overflow: "auto" }}>
        {templates.map(template => {
          return <ChatTemplateCell template={template} key={template.id} />;
        })}
      </div>
      <AddTemplate />
    </div>
  );
};

ChatTemplateLists.defaultProps = {
  templates: []
};
ChatTemplateLists.propTypes = {
  templates: PropTypes.array.isRequired
};
const mapState2Props = state => {
  return { templates: state.chat.chatTemplates };
};
export default connect(mapState2Props)(ChatTemplateLists);
