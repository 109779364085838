import { notificationConstants } from "../constants";
import { notificationService } from "../services";
import { userActions } from "../actions";

export const notificationActions = {
  getAll,
  send,
  sendTest,
  getJobs,
  schedule,
  cancelSchedule,
};

function getAll() {
  return (dispatch) => {
    dispatch(request());
    notificationService.getAll().then(
      (result) => {
        dispatch(success(result));
      },
      (err) => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: notificationConstants.GET_NOTIFICATIONS_REQUEST };
  }
  function success(result) {
    return {
      type: notificationConstants.GET_NOTIFICATIONS_SUCCESS,
      notifications: result,
    };
  }
  function failure(error) {
    return { type: notificationConstants.GET_NOTIFICATIONS_FAILURE, error };
  }
}

function sendTest(info) {
  return (dispatch) => {
    dispatch(request());
    notificationService.send(info, "testing").then(
      (result) => {
        dispatch(success(result));
      },
      (err) => {
        dispatch(failure(err));
        if (err.status === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: notificationConstants.SEND_NOTIFICATION_TEST_REQUEST };
  }
  function success(result) {
    return {
      type: notificationConstants.SEND_NOTIFICATION_TEST_SUCCESS,
      notification: result,
    };
  }
  function failure(error) {
    return {
      type: notificationConstants.SEND_NOTIFICATION_TEST_FAILURE,
      error,
    };
  }
}

function send(info) {
  return (dispatch) => {
    dispatch(request());
    notificationService.send(info, "sendImediately").then(
      (result) => {
        dispatch(success(result));
      },
      (err) => {
        dispatch(failure(err));
        if (err.status === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: notificationConstants.SEND_NOTIFICATIONS_REQUEST };
  }
  function success(result) {
    return {
      type: notificationConstants.SEND_NOTIFICATIONS_SUCCESS,
      notification: result,
    };
  }
  function failure(error) {
    return { type: notificationConstants.SEND_NOTIFICATIONS_FAILURE, error };
  }
}

function getJobs() {
  return (dispatch) => {
    dispatch(request());
    notificationService.getJobs().then(
      (result) => {
        dispatch(success(result));
      },
      (err) => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: notificationConstants.GET_SCHEDULEDJOBS_REQUEST };
  }
  function success(result) {
    return {
      type: notificationConstants.GET_SCHEDULEDJOBS_SUCCESS,
      jobs: result,
    };
  }
  function failure(error) {
    return { type: notificationConstants.GET_SCHEDULEDJOBS_FAILURE, error };
  }
}

function schedule(info) {
  return (dispatch) => {
    dispatch(request());
    notificationService.send(info, "schedule").then(
      (result) => {
        dispatch(success(result));
      },
      (err) => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return { type: notificationConstants.SCHEDULE_NOTIFICATIONS_REQUEST };
  }
  function success(result) {
    return {
      type: notificationConstants.SCHEDULE_NOTIFICATIONS_SUCCESS,
      job: result,
    };
  }
  function failure(error) {
    return {
      type: notificationConstants.SCHEDULE_NOTIFICATIONS_FAILURE,
      error,
    };
  }
}

function cancelSchedule(id) {
  return (dispatch) => {
    dispatch(request());
    notificationService.cancelSchedule(id).then(
      (result) => {
        dispatch(success(result));
      },
      (err) => {
        dispatch(failure(err));
        if (err === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };
  function request() {
    return {
      type: notificationConstants.CANCEL_SCHEDULE_NOTIFICATIONS_REQUEST,
    };
  }
  function success(result) {
    return {
      type: notificationConstants.CANCEL_SCHEDULE_NOTIFICATIONS_SUCCESS,
      job: result,
    };
  }
  function failure(error) {
    return {
      type: notificationConstants.CANCEL_SCHEDULE_NOTIFICATIONS_FAILURE,
      error,
    };
  }
}
