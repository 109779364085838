import React, { Component } from "react";
import { chatService } from "../../services";
import {
  ChatRelationsLists,
  ChatRoom,
  ChatTemplateLists
} from "../../components/ChatsKit";
import { Card, CardBody } from "reactstrap";
import { chatActions, userActions } from "../../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { UserSearch } from "./index";

class ChatView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getInitalDatas = () => {
    const { dispatch } = this.props;
    dispatch(chatActions.getChatRelations());
    dispatch(chatActions.getChatTemplates());
    dispatch(userActions.getUser(chatService.taptotOfficialUserId));
  };
  newMessageComming = messageObj => {
    const { dispatch } = this.props;
    dispatch(chatActions.newMessageComming(messageObj));
  };
  relationReOrdering = relationObj => {
    const { dispatch } = this.props;
    dispatch(chatActions.relationMoving2Top(relationObj));
  };
  messageSendingFunc = (message, type = "message") => {
    const { dispatch, chattingID } = this.props;
    dispatch(chatActions.sendMessage2aChatTarget(chattingID, message, type));
  };
  broadcastMessage = (message, type = "message") => {
    const { dispatch, multiChattingID } = this.props;
    dispatch(
      chatActions.broadcastingMessage2ChatTargets(
        multiChattingID,
        message,
        type
      )
    );
    // multiChattingID.forEach(chattingID => {
    //   dispatch(chatActions.sendMessage2aChatTarget(chattingID, message, type));
    // });
  };
  querryOlderMessage = endPoint => {
    if (endPoint) {
      const { dispatch, chattingID } = this.props;
      dispatch(chatActions.getChatContents(chattingID, endPoint));
    }
  };
  componentDidMount() {
    this.getInitalDatas();
  }
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(chatActions.reset2InitState());
  }
  render() {
    const viewConfig = {
      height: "700px",
      overflow: "auto"
    };
    console.log(this.props.chattingID);
    return (
      <Card>
        <CardBody>
          <UserSearch />
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "200px",
                border: "1px solid #ccc",
                ...viewConfig
              }}
            >
              <ChatRelationsLists
                broadcastMessage={this.broadcastMessage}
                relationReOrdering={this.relationReOrdering}
              />
            </div>
            <div style={{ flex: "1", ...viewConfig }}>
              <ChatRoom
                chatContents={this.props.chatContents}
                key={chatService.getChatRoonID(this.props.chattingID)}
                roomID={
                  this.props.chattingID
                    ? chatService.getChatRoonID(this.props.chattingID)
                    : ""
                }
                chattingID={this.props.chattingID}
                chattingAvatar={this.props.chattingAvatar}
                selfAvatar={this.props.selfAvatar}
                messageSendingFunc={this.messageSendingFunc}
                newMessageComming={this.newMessageComming}
                querryOlderMessage={this.querryOlderMessage}
              />
            </div>
            <div
              style={{
                width: "200px",
                // border: "1px solid green",
                ...viewConfig
              }}
            >
              <ChatTemplateLists />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

ChatView.defaultProps = {
  chatContents: []
};
ChatView.propTypes = {
  chatContents: PropTypes.array.isRequired
};
const mapState2Props = state => {
  // const chatting
  const chattingID =
    state.chat.chattingID.length > 0 ? state.chat.chattingID[0] : "";
  return {
    chatContents: state.chat.chatContents,
    chattingID: chattingID,
    multiChattingID: state.chat.multiChattingID,
    chattingAvatar: state.users[chattingID]
      ? state.users[chattingID].data.imageURL
      : "",
    selfAvatar: state.users[process.env.REACT_APP_TAPTOT_OFFICIAL_USERID]
      ? state.users[process.env.REACT_APP_TAPTOT_OFFICIAL_USERID].data.imageURL
      : ""
  };
};
export default connect(mapState2Props)(ChatView);
