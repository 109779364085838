import { userConstants } from "../constants";

export function users(state = { users: [] }, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users,
        transactions: [
          { title: "shit", status: "ok" },
          { title: "eat", status: "cancelled" },
          { title: "holly", status: "cancelled" }
        ]
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case userConstants.GETUSER_FAILURE:
      return {
        error: action.error,
        loading: false
      };
    case userConstants.GETUSER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETUSER_SUCCESS:
      var data = { ...state, loading: false };
      data[action.user.id] = action.user;
      // data.users = [action.user];
      return data;
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map(user =>
          user.id === action.id ? { ...user, deleting: true } : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };
    //Search
    case userConstants.SEARCH_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SEARCH_SUCCESS:
      var isEmpty = false;
      if (action.users.length === 0) isEmpty = true;

      return {
        ...state,
        loading: false,
        users: action.users,
        isEmpty: isEmpty
      };
    case userConstants.SEARCH_FAILURE:
      return {
        ...state,
        loading: false
      };
    //TP Logs
    case userConstants.GETUSER_TPLOG_REQUEST:
      return {
        ...state
      };
    case userConstants.GETUSER_TPLOG_SUCCESS:
      return {
        ...state,
        logs: action.logs
      };
    case userConstants.GETUSER_TPLOG_FAILURE:
      return {
        ...state
      };
    case userConstants.GET_SUSPENDEDUSERS_ACTIVITIES_REQUEST:
      return {
        ...state,
        suspended: { loading: true }
      };
    case userConstants.GET_SUSPENDEDUSERS_ACTIVITIES_SUCCESS:
      return {
        ...state,
        suspended: { loading: false, activities: action.activities }
      };
    case userConstants.GET_SUSPENDEDUSERS_ACTIVITIES_FAILURE:
      return {
        ...state,
        suspended: { loading: false, error: action.error }
      };

    case userConstants.GET_WARNEDUSERS_ACTIVITIES_REQUEST:
      return {
        ...state,
        warned: { loading: true }
      };
    case userConstants.GET_WARNEDUSERS_ACTIVITIES_SUCCESS:
      return {
        ...state,
        warned: { loading: false, activities: action.activities }
      };
    case userConstants.GET_WARNEDUSERS_ACTIVITIES_FAILURE:
      return {
        ...state,
        warned: { loading: false, error: action.error }
      };

    case userConstants.RESUME_WARNEDUSER_REQUEST:
      return {
        ...state
      };
    case userConstants.RESUME_WARNEDUSER_SUCCESS:
      const { warned } = state;
      warned.activities.forEach((activity, index) => {
        if (activity.id === action.result.id) {
          activity = { ...activity, ...action.result };
          warned.activities[index] = activity;
        }
      });
      return {
        ...state,
        warned: { loading: false, activities: action.activities }
      };
    case userConstants.RESUME_WARNEDUSER_FAILURE:
      return {
        ...state,
        warned: { ...state.warned, resumeError: action.error }
      };

    default:
      return state;
  }
}
