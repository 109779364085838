import React, { Component } from "react";
//import { userActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import PromoCodeTable from "./promoCodeTable.jsx";
import PromoCodeDetail from "./promoCodeDetail.jsx";
import {
  Card,
  CardBody
  // CardHeader,
  // CardTitle,
  // Button,
  // FormGroup,
  // Row,
  // Col,
  // Input,
  // Label
} from "reactstrap";
class PromoCodeView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCreate() {}

  render() {
    return (
      <Card>
        <CardBody>
          <Route exact path="/main/promoCodes" component={PromoCodeTable} />
          <Route
            path={`/main/promoCodes/view/:id`}
            component={PromoCodeDetail}
          />
        </CardBody>
      </Card>
    );
  }
}

PromoCodeView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state;
};
export default connect(mapStateToProps)(PromoCodeView);
