import React from "react";
import { connect } from "react-redux";
import {
  Card,
  // CardImg,
  // CardText,
  CardBody,
  CardTitle,
  // CardSubtitle,
  // Button,
  // Row,
  // Col,
  // Container,
  // CardFooter,
  CardHeader
  // Popover,
  // PopoverBody,
  // PopoverHeader,
  // Label
} from "reactstrap";
// import moment, { months } from "moment";
// import DatePicker from "react-datepicker";
// import { dashboardActions } from "../../actions";
import { PulseLoader } from "react-spinners";
// import ReactLoading from "react-loading";
import { css } from "react-emotion";

class CategoryInfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      total: props.total,
      taken: props.taken,
      taker: props.taker,
      giver: props.giver
    };
    this.override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
      text-align: center;
    `;
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate() {}

  componentDidMount() {}

  render() {
    const { loading } = this.state;
    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-center">{this.props.title} </CardTitle>
        </CardHeader>
        <CardBody>
          {loading ? (
            <PulseLoader
              className={this.override}
              sizeUnit={"px"}
              margin="2px"
              size={10}
              color={"#eee"}
              loading={loading}
            />
          ) : (
            <>
              <p className="text-center h6">Total : {this.state.total}</p>
              <p className="text-center h6">Taken : {this.state.taken}</p>
              <p className="text-center h6">Taker : {this.state.taker}</p>
              <p className="text-center h6">Giver : {this.state.giver}</p>
            </>
          )}
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { dashboard } = state;
  return { dashboard };
}

// export default connect(
//   mapStateToProps,
//   null,
//   null,
//   { withRef: true }
// )(CategoryInfoCard);
export default connect(mapStateToProps)(CategoryInfoCard);
