import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Media, Row } from "reactstrap";
// import firebase from "firebase/app";
// import { userService } from "../services";
import { userActions } from "../actions";
import ReactLoading from "react-loading";
import moment from "moment";
import UserInfoVertical from "./userInfoItemVertical.jsx";
import { Button, Alert } from "reactstrap";
import swal from "sweetalert";

class WarnedUserListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activities: []
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userActions.getWarnedActivities());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activities) {
      this.setState({ loading: false, activities: nextProps.activities });
    }

    if (this.sendingSwal && !nextProps.resuming) {
      swal.close();
      this.sendingSwal = undefined;
    }

    if (nextProps.resumeError) {
      this.setState({ resumeError: nextProps.resumeError.message });
    }
  }

  didClickResume = e => {
    let userId = e.target.name;
    let activityId = e.target.id;
    if (!userId || !activityId) return;
    let self = this;
    this.sendingSwal = swal({
      title: "Are you sure to resume this user?",
      text: "This cannot be undo",
      icon: "warning",
      buttons: [
        {
          text: "Not Now",
          value: null,
          visible: true,
          closeModal: true
        },
        {
          text: "Confirm",
          value: true,
          visible: true,
          closeModal: false
        }
      ],
      dangerMode: true,
      buttonsStyling: false
    }).then(function(confirm) {
      if (confirm) {
        if (self.state.resumeError) self.setState({ resumeError: "" });
        const { dispatch } = self.props;
        dispatch(userActions.resumeWarnedUser(activityId, userId));
      }
    });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <div className="loading-center">
            <ReactLoading
              type="spinningBubbles"
              color="#eee"
              height="30px"
              width="30px"
            />
          </div>
        ) : (
          <div className="content table-responsive table-full-width">
            {this.state.resumeError && this.state.resumeError != "" && (
              <Alert color="warning">{this.state.resumeError}</Alert>
            )}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Reason</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.activities.map(el => (
                  <tr key={el.createdAt}>
                    <td>{moment(el.createdAt).format("LLL")}</td>
                    <td>
                      <UserInfoVertical id={el.actionID} />
                    </td>
                    <td>{el.reason}</td>
                    <td>
                      {(!el.note || el.note != "resumed") && (
                        <Button
                          name={el.actionID}
                          id={el.id}
                          onClick={this.didClickResume}
                        >
                          Resume
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
}

WarnedUserListView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { warned } = state.users;
  return warned ? warned : {};
};

export default connect(mapStateToProps)(WarnedUserListView);
