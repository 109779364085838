import React, { Component } from "react";
// import { render } from "react-dom";
import { userActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import UserTable from "./userTable.jsx";
import UserDetail from "./userDetail.jsx";
import WarnedUserListView from "./userWarnedListView";
import SuspendedUserListView from "./userSuspendedListView";

import UserTPLog from "./userTpLog.jsx";
import EmptyContent from "../components/emptyContent.jsx";
import classnames from "classnames";

import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  // CardTitle,
  // CardText,
  // Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

class UsersView extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = { keyword: "", activeTab: "1" };
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSearch(e) {
    e.preventDefault();
    const { keyword } = this.state;
    const { dispatch } = this.props;

    if (keyword) {
      dispatch(userActions.search(keyword));
    }
  }

  handleCheckDetail(e) {
    e.preventDefault();
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  render() {
    const { keyword } = this.state;
    const { loading } = this.props;
    const { isEmpty } = this.props;

    return (
      <div>
        <Card>
          <CardBody>
            <div className="nav-tabs-navigation">
              <div className="nav-tabs-wrapper">
                <Nav id="tabs" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1"
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Search
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2"
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Suspended Users
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3"
                      })}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      Warned Users
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Form>
                  <Row>
                    <Col className="col-vin-alignself">
                      <input
                        type="text"
                        className="form-control border-input"
                        name="keyword"
                        value={keyword}
                        placeholder="EX: Display Name, ID, phone nubmer, invite Code"
                        onChange={this.handleChange}
                      />
                    </Col>
                    <Col>
                      <button
                        type="submit"
                        onClick={this.handleSearch}
                        className="btn btn-info btn-fill btn-wd"
                      >
                        Search
                      </button>
                      {loading && (
                        <img
                          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                          alt="..."
                        />
                      )}
                    </Col>
                  </Row>
                </Form>
                <Route exact path="/main/users" component={UserTable} />
                <Route path={`/main/users/:id/detail`} component={UserDetail} />
                <Route path={`/main/users/:id/tplogs`} component={UserTPLog} />
                {isEmpty ? <EmptyContent /> : ""}
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col>
                    <SuspendedUserListView />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col>
                    <WarnedUserListView />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    );
  }
}

UsersView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { loading, users, isEmpty } = state.users;
  return {
    loading,
    isEmpty,
    users
  };
};
export default connect(mapStateToProps)(UsersView);

/*

<div className="content">
                    <ul className="list-unstyled team-members">
                        <li>
                            <div className="row">
                                <div className="col-xs-3">
                                    <div className="avatar">
                                        <img src="" alt="Circle Image" className="img-circle img-no-padding img-responsive" />
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    DJ Khaled
                                    <br />
                                    <span className="text-muted"><small>Offline</small></span>
                                </div>

                                <div className="col-xs-3 text-right">
                                    <btn className="btn btn-sm btn-success btn-icon"><i className="fa fa-envelope"></i></btn>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="row">
                                <div className="col-xs-3">
                                    <div className="avatar">
                                        <img src="" alt="Circle Image" className="img-circle img-no-padding img-responsive" />
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    Creative Tim
                                    <br />
                                    <span className="text-success"><small>Available</small></span>
                                </div>

                                <div className="col-xs-3 text-right">
                                    <btn className="btn btn-sm btn-success btn-icon"><i className="fa fa-envelope"></i></btn>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="row">
                                <div className="col-xs-3">
                                    <div className="avatar">
                                        <img src="" alt="Circle Image" className="img-circle img-no-padding img-responsive" />
                                    </div>
                                </div>
                                <div className="col-xs-6">
                                    Flume
                                    <br />
                                    <span className="text-danger"><small>Busy</small></span>
                                </div>

                                <div className="col-xs-3 text-right">
                                    <btn className="btn btn-sm btn-success btn-icon"><i className="fa fa-envelope"></i></btn>
                                </div>
                            </div>
                        </li>
                    </ul>

*/
