import { authHeader } from "../helpers";
import QueryString from "query-string";
export const transactionService = {
  search,
  get,
  getEcanOrder,
  updateEcanOrder,
  getPaymentInfo,
  refund,
  cancel,
  complete,
  getRatings,
  updateRatings,
  deleteRatings,
  updateAddressInfo
};

function search(keyword, conditions) {
  var body = {};
  if (keyword && keyword.length > 0) {
    body = { keyword };
  } else if (conditions) {
    body = conditions;
  }

  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body)
  };

  return fetch(host + "/api/admin/v1/search/transactions", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function get(numericId) {
  var host = window.location.protocol + "//" + window.location.host;

  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(
    host + "/api/admin/v1/transaction/" + numericId,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function getEcanOrder(id, pickupDate) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  var queryString =
    "/api/admin/v1/transaction/" + id + "/ecanorder?date=" + pickupDate;

  return fetch(host + queryString, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }

    return response.json();
  });
}

function updateEcanOrder(order, transaction) {
  var host = window.location.protocol + "//" + window.location.host;

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ order })
  };

  var queryString =
    "/api/admin/v1/transaction/" +
    order.taptotId +
    "/ecanorder?date=" +
    transaction.data.pickupDate +
    "&id=" +
    transaction.id;

  return fetch(host + queryString, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function getPaymentInfo(id, paymentId, service) {
  var host = window.location.protocol + "//" + window.location.host;

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ paymentId, service })
  };

  var queryString = "/api/admin/v1/transaction/" + id + "/payment";

  return fetch(host + queryString, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function cancel(transaction, options) {
  var host = window.location.protocol + "//" + window.location.host;
  var transactionId = transaction.id;

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ transactionId, options })
  };

  var queryString = "/api/admin/v1/transaction/" + transaction.id + "/cancel";

  return fetch(host + queryString, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function complete(transaction) {
  var host = window.location.protocol + "//" + window.location.host;
  var transactionId = transaction.id;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  var queryString = "/api/admin/v1/transaction/" + transactionId + "/complete";
  return fetch(host + queryString, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function refund(payment) {
  var host = window.location.protocol + "//" + window.location.host;
  var tradeId = payment.tradeId;
  var paymentId = payment.id;

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ tradeId, paymentId })
  };

  var queryString = "/api/admin/v1/transaction/" + payment.orderId + "/refund";
  return fetch(host + queryString, requestOptions).then(response => {
    //debug
    return { status: 0 };

    // if (!response.ok) {
    //   return Promise.reject(response.status);
    // }

    // return response.json();
  });
}

function getRatings(transaction) {
  var host = window.location.protocol + "//" + window.location.host;
  var transactionId = transaction.id;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  var data = {};

  if (transaction.data.taker) {
    data.taker = transaction.data.taker;
  }

  if (transaction.data.giver) {
    data.giver = transaction.data.giver;
  }

  if (transaction.data.designator) {
    data.designator = transaction.data.designator;
  }

  var args = "?" + QueryString.stringify(data);

  var queryString =
    "/api/admin/v1/transaction/" + transactionId + "/ratings" + args;
  return fetch(host + queryString, requestOptions).then(response => {
    //debug
    //return {};
    if (!response.ok) {
      return Promise.reject(response.status);
    }

    return response.json();
  });
}

function updateRatings(transaction, ratings) {
  var host = window.location.protocol + "//" + window.location.host;
  var transactionId = transaction.id;

  if (transaction.data.taker) {
    ratings.taker = transaction.data.taker;
  }

  if (transaction.data.giver) {
    ratings.giver = transaction.data.giver;
  }

  if (transaction.data.designator) {
    ratings.designator = transaction.data.designator;
  }

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(ratings)
  };

  var queryString = "/api/admin/v1/transaction/" + transactionId + "/ratings";
  return fetch(host + queryString, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }

    return response.json();
  });
}

function deleteRatings(transaction, rating) {
  var host = window.location.protocol + "//" + window.location.host;
  var transactionId = transaction.id;
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  var data = {};

  if (rating.name) {
    data.name = rating.name;
  }

  if (rating.data.originRating) {
    data.rating = rating.data.originRating;
  }

  if (transaction.data.taker) {
    data.taker = transaction.data.taker;
  }

  if (transaction.data.giver) {
    data.giver = transaction.data.giver;
  }

  if (transaction.data.designator) {
    data.designator = transaction.data.designator;
  }

  var args = "?" + QueryString.stringify(data);

  var queryString =
    "/api/admin/v1/transaction/" + transactionId + "/ratings" + args;

  return fetch(host + queryString, requestOptions).then(response => {
    //debug
    //return {};
    if (!response.ok) {
      return Promise.reject(response.status);
    }

    return response.json();
  });
}

function updateAddressInfo(id, type, addressInfo, pickupDate) {
  var body = { type, addressInfo };
  var host = window.location.protocol + "//" + window.location.host;
  if (pickupDate) {
    body = { type, addressInfo, pickupDate, updateEcanOrder: true };
  }
  console.log(" body -> ", body);
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(body)
  };

  var queryString = "/api/admin/v1/transaction/" + id + "/addressInfo";

  return fetch(host + queryString, requestOptions).then(response => {
    //debug
    //return {};
    if (!response.ok) {
      return Promise.reject(response.status);
    }

    return response.json();
  });
}
