import { sponsorConstants } from "../constants";

const initialState = { loading: false, NPOs: [] };

export function sponsor(state = initialState, action) {
  switch (action.type) {
    case sponsorConstants.GET_ALL_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case sponsorConstants.GET_ALL_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: action.applications
      };
    case sponsorConstants.GET_ALL_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false
      };
    case sponsorConstants.GET_APPLICATION_REQUEST:
      return { ...state, loading: true };
    case sponsorConstants.GET_APPLICATION_SUCCESS:
      return { ...state, loading: false, application: action.application };
    case sponsorConstants.GET_APPLICATION_FAILURE:
      return { ...state, loading: false };

    case sponsorConstants.UPDATE_APPLICATION_REQUEST:
      return { ...state, loading: true, approved: action.approved };
    case sponsorConstants.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        application: action.application,
        approved: action.approved
      };
    case sponsorConstants.UPDATE_APPLICATION_FAILURE:
      return { ...state, loading: false, approved: action.approved };

    case sponsorConstants.GET_ALL_NPO_REQUEST:
      return { ...state, loading: true };
    case sponsorConstants.GET_ALL_NPO_SUCCESS:
      return { ...state, loading: false, NPOs: action.NPOs };
    case sponsorConstants.GET_ALL_NPO_FAILURE:
      return { ...state, loading: false };

    case sponsorConstants.GET_ALL_SPONSOR_REQUEST:
      return { ...state, loading: true };
    case sponsorConstants.GET_ALL_SPONSOR_SUCCESS:
      return { ...state, loading: false, sponsors: action.sponsors };
    case sponsorConstants.GET_ALL_SPONSOR_FAILURE:
      return { ...state, loading: false };

    case sponsorConstants.GET_SPONSOR_REQUEST:
      return { ...state, loading: true };
    case sponsorConstants.GET_SPONSOR_SUCCESS:
      return { ...state, loading: false, sponsor: action.sponsor };
    case sponsorConstants.GET_SPONSOR_FAILURE:
      return { ...state, loading: false };

    case sponsorConstants.CREATE_SPONSOR_REQUEST:
      return { ...state, createSponsor: { loading: true } };
    case sponsorConstants.CREATE_SPONSOR_SUCCESS:
      return {
        ...state,
        createSponsor: { loading: false, shouldDismiss: true }
      };
    case sponsorConstants.CREATE_SPONSOR_FAILURE:
      return {
        ...state,
        createSponsor: { loading: false, error: action.error }
      };

    case sponsorConstants.UPDATE_SPONSOR_REQUEST:
      return { ...state, updateSponsor: { loading: true } };
    case sponsorConstants.UPDATE_SPONSOR_SUCCESS:
      return {
        ...state,
        updateSponsor: { loading: false },
        sponsor: action.result.sponsor
      };
    case sponsorConstants.UPDATE_SPONSOR_FAILURE:
      return {
        ...state,
        updateUser: { loading: false }
      };
    case sponsorConstants.DOWNGRADE_SPONSOR_REQUEST:
      return { ...state, loading: true };
    case sponsorConstants.DOWNGRADE_SPONSOR_SUCCESS:
      return { ...state, loading: false };
    case sponsorConstants.DOWNGRADE_SPONSOR_FAILURE:
      return { ...state, loading: false };

    case sponsorConstants.UPDATE_SPONSOR_PENDINGINFO_REQUEST:
      return { ...state, updatePendingInfo: { loading: true } };
    case sponsorConstants.UPDATE_SPONSOR_PENDINGINFO_SUCCESS:
      return {
        ...state,
        updatePendingInfo: {
          loading: false,
          action: action.action,
          status: "ok"
        },
        sponsor: action.sponsor
      };
    case sponsorConstants.UPDATE_SPONSOR_PENDINGINFO_FAILURE:
      return {
        ...state,
        updatePendingInfo: {
          loading: false,
          action: action.action,
          status: null,
          error: action.error
        }
      };

    default:
      return state;
  }
}
