import React, { Component } from "react";
// import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../actions";
import PropTypes from "prop-types";
// import { Media } from "reactstrap";

class UserContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
    const { id, dispatch } = this.props;
    if (id) {
      dispatch(userActions.getUser(id));
    }
  }
  render() {
    const { users, horizontal, onlyName } = this.props;
    const { loading } = users;
    const { id } = this.props;

    var user =
      users[id] && users[id].data && Object.keys(users[id].data).length > 0
        ? users[id].data
        : undefined;
    var info =
      !loading && user ? (
        <div
          style={{
            display: "flex",
            alignItems: horizontal ? "center" : "left",
            flexDirection: horizontal ? "row" : "column",
            justifyContent: "flex-start"
          }}
        >
          <div className="avatar">
            <img
              src={user.imageURL}
              alt=" " // Circle Image
              className="img-circle img-no-padding img-responsive"
            />
          </div>

          <div style={{ margin: horizontal ? "0 8px" : "" }}>
            <div>{user.displayName}</div>
            <label className="text-center">@{user.uniqueID}</label>
          </div>
        </div>
      ) : (
        <img
          alt="..."
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      );
    if (!loading && user && onlyName) {
      info = <div>{user.displayName}</div>;
    }
    if (!loading && !user) {
      info = <div>Not found</div>;
    }

    return <div>{info}</div>;
  }
}

/*
			            <br />
			            <span className="text-muted"><small>Offline</small></span>
*/

const mapStateToProps = state => {
  var users = state.users;
  return {
    users
  };
};
UserContent.defaultProps = {
  horizontal: false,
  onlyName: false
};

var connnectedUserContent = connect(mapStateToProps)(UserContent);

export { connnectedUserContent as UserContent };
