import React from "react";
import ReactExport from "react-export-excel";
import { Button } from "reactstrap";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class ExcelDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { transactions: [] };
    console.log("props.transactions ->  ", props.transactions);
    if (props.transactions) {
      var transactions = [];
      var method;
      var transID;
      props.transactions.forEach(el => {
        var data = el.data;
        method = data.method;
        transID = data.numeric_id;
        data.createdAt = moment(data.createdAt).format("LLL");
        transactions.push(data);
      });

      this.state = {
        transactions: transactions,
        filename:
          transactions.length > 0
            ? method + "_" + moment().format("LLLL")
            : transID
      };
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render() {
    return (
      <ExcelFile
        element={<Button>Download Excel</Button>}
        filename={this.state.filename ? this.state.filename : moment().format()}
      >
        <ExcelSheet data={this.state.transactions} name="Transactions">
          <ExcelColumn label="Date" value="createdAt" />
          <ExcelColumn label="Transaction Number" value="numeric_id" />
          <ExcelColumn label="Carrier Track Number" value="tracking_number" />
          <ExcelColumn label="Pick Number" value="pickupNumber" />
          <ExcelColumn label="Amount" value="charge" />
          <ExcelColumn label="Shipping Method" value="method" />
          <ExcelColumn label="Status" value="status" />
          <ExcelColumn label="Taker ID" value="taker" />
          <ExcelColumn label="Taker Name" value="takerName" />
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default ExcelDownload;
