import { transactionConstants } from "../constants";
import { transactionService } from "../services";
import { alertActions } from "./";
import { userActions } from "../actions";
import swal from "sweetalert";

export const transactionActions = {
  getAll,
  get,
  cancel,
  search,
  complete,
  updateAddressInfo
};

function getAll(keyword) {
  return dispatch => {
    dispatch(request({ keyword }));

    transactionService.search(keyword).then(
      transactions => {
        dispatch(success(transactions));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: transactionConstants.GETALL_REQUEST, user };
  }
  function success(user) {
    return { type: transactionConstants.GETALL_SUCCESS, user };
  }
  function failure(error) {
    return { type: transactionConstants.GETALL_FAILURE, error };
  }
}

function get(numericId) {
  return dispatch => {
    dispatch(request({}));

    transactionService.get(numericId).then(
      transaction => {
        dispatch(success(transaction));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request(transaction) {
    return { type: transactionConstants.GET_REQUEST, transaction };
  }
  function success(transaction) {
    return { type: transactionConstants.GET_SUCCESS, transaction };
  }
  function failure(error) {
    return { type: transactionConstants.GET_FAILURE, error };
  }
}

function complete(transaction) {
  return dispatch => {
    dispatch(request({}));

    transactionService.complete(transaction).then(
      completedTransaction => {
        dispatch(success(completedTransaction));
        swal({
          title: "Order is completed!",
          text: "You have completed this order.",
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              //className: "btn btn-success btn-fill",
              closeModal: true
            }
          },
          buttonsStyling: false
        });
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        swal({
          title: "Error Occurs",
          text: "Failed to complete the order, please try again.",
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              //className: "btn btn-success btn-fill",
              closeModal: true
            }
          },
          buttonsStyling: false
        });
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request(transaction) {
    return { type: transactionConstants.COMPLETE_REQUEST, transaction };
  }
  function success(transaction) {
    return { type: transactionConstants.COMPLETE_SUCCESS, transaction };
  }
  function failure(error) {
    return { type: transactionConstants.COMPLETE_FAILURE, error };
  }
}

function cancel(transaction, options) {
  return dispatch => {
    dispatch(request({ transaction }));

    transactionService.cancel(transaction, options).then(
      cancelledTransaction => {
        dispatch(success(cancelledTransaction));

        swal({
          title: "Order is cancelled!",
          text: "You have cancelled this order.",
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "btn btn-success btn-fill",
              closeModal: true
            }
          },
          buttonsStyling: false
        });
      },
      error => {
        dispatch(failure(error));
        //dispatch(alertActions.error(error));
        swal({
          title: "Error Occurs",
          text: "Failed to cancel the order, please try again.",
          icon: "error",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "btn btn-success btn-fill",
              closeModal: true
            }
          },
          buttonsStyling: false
        });

        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request(transaction) {
    return { type: transactionConstants.CANCEL_REQUEST, transaction };
  }
  function success(transaction) {
    return { type: transactionConstants.CANCEL_SUCCESS, transaction };
  }
  function failure(error) {
    return { type: transactionConstants.CANCEL_FAILURE, error };
  }
}

function search(keyword, conditions) {
  return dispatch => {
    dispatch(request({ keyword }));
    transactionService.search(keyword, conditions).then(
      transactions => {
        dispatch(success(transactions));
      },
      error => {
        dispatch(failure(error));

        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request(transactions) {
    return { type: transactionConstants.SEARCH_REQUEST, transactions };
  }
  function success(transactions) {
    return { type: transactionConstants.SEARCH_SUCCESS, transactions };
  }
  function failure(error) {
    return { type: transactionConstants.SEARCH_FAILURE, error };
  }
}

function updateAddressInfo(id, type, addresssInfo, pickupDate) {
  return dispatch => {
    dispatch(request());
    transactionService
      .updateAddressInfo(id, type, addresssInfo, pickupDate)
      .then(
        result => {
          dispatch(success(result));
        },
        error => {
          dispatch(failure(error));

          if (error == 401) {
            dispatch(userActions.logout());
          }
        }
      );
  };
  function request() {
    return { type: transactionConstants.UPDATE_ADDRESSINFO_REQUEST };
  }
  function success(result) {
    return {
      type: transactionConstants.UPDATE_ADDRESSINFO_SUCCESS,
      result
    };
  }
  function failure(error) {
    return { type: transactionConstants.UPDATE_ADDRESSINFO_FAILURE, error };
  }
}
