import { bannerConstants } from "../constants";

const initialState = { loading: false };

export function banner(state = initialState, action) {
  switch (action.type) {
    case bannerConstants.CREATE_REQUEST:
      return {
        loading: true
      };
    case bannerConstants.CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        banner: action.banner
      };
    case bannerConstants.CREATE_FAILURE:
      return {
        loading: false,
        error: true
      };
    case bannerConstants.GET_ALL_REQUEST:
      return {
        loading: true
      };
    case bannerConstants.GET_ALL_SUCCESS:
      return {
        loading: false,
        banners: action.banners
      };
    case bannerConstants.GET_ALL_FAILURE:
      return {
        loading: false,
        banners: []
      };
    case bannerConstants.GET_REQUEST:
      return { loading: true };
    case bannerConstants.GET_SUCCESS:
      return { loading: false, banner: action.banner };
    case bannerConstants.GET_FAILURE:
      return { loading: false };
    case bannerConstants.UPDATE_REQUEST:
      var actionInfo = undefined;
      if (action.action) {
        actionInfo = {};
        actionInfo.action = action.action;
        actionInfo.loading = true;
      }
      return { loading: true, actionInfo };
    case bannerConstants.UPDATE_SUCCESS:
      if (action.action) {
        actionInfo = {};
        actionInfo.action = action.action;
        actionInfo.loading = false;
      }
      return {
        loading: false,
        banner: action.result,
        actionInfo: actionInfo
      };
    case bannerConstants.UPDATE_FAILURE:
      if (action.action) {
        actionInfo = {};
        actionInfo.action = action.action;
        actionInfo.loading = false;
      }
      return { loading: false, actionInfo: actionInfo };
    default:
      return state;
  }
}
