import TransactionsView from "./js/transactions/transactionsView.jsx";
import UsersView from "./js/users/usersView.jsx";
import BannersView from "./js/officialBanners/bannersView.jsx";
import DashboardView from "./js/dashboard/dashboardView.jsx";
import BannerCreateView from "./js/officialBanners/bannerCreation.jsx";
import SponsorRequestsView from "./js/sponsors/applications/sponsorRequestsView.jsx";
import PromoCodeView from "./js/views/promoCodes/promoCodeView.jsx";
import NotificationView from "./js/views/notifications/notificationView.jsx";
import CreatePromoCodesView from "./js/views/promoCodes/createPromoCodeView";
import ZonesView from "./js/views/zones/zonesView";
import CreateZonesView from "./js/views/zones/createZonesView";

const basedRoutes = {
  dashboard: {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ti-dashboard",
    component: DashboardView,
    layout: "/main",
  },
  transaction: {
    path: "/transactions",
    name: "Transactions",
    icon: "ti-menu",
    component: TransactionsView,
    layout: "/main",
  },
  user: {
    path: "/users",
    name: "Users",
    icon: "ti-user",
    component: UsersView,
    layout: "/main",
  },
  promoCode: {
    collapse: true,
    name: "promoCode",
    icon: "ti-bookmark",
    state: "promoCode",
    views: [
      {
        path: "/promoCodes",
        name: "PromoCodes",
        mini: "PC",
        component: PromoCodeView,
        layout: "/main",
      },
      {
        path: "/createPromoCodes",
        name: "CreatePromoCodes",
        mini: "CP",
        layout: "/main",
        component: CreatePromoCodesView,
      },
    ],
  },
  notification: {
    path: "/notifications",
    name: "Notifications",
    icon: "ti-announcement",
    component: NotificationView,
    layout: "/main",
  },
  editHomePage: {
    path: "/editHomePage",
    name: "EditHomePage",
    icon: "ti-layout-list-thumb",
    layout: "/main",
  },
  banner: {
    collapse: true,
    name: "Banners",
    icon: "ti-layout-accordion-separated",
    state: "banner",
    views: [
      {
        path: "/createBanner",
        name: "Create Banner",
        mini: "CB",
        component: BannerCreateView,
        layout: "/main",
      },
      {
        path: "/banners",
        name: "Banner List",
        mini: "BL",
        component: BannersView,
        layout: "/main",
      },
    ],
  },
  sponsor: {
    collapse: true,
    name: "Sponsors",
    icon: "ti-id-badge",
    state: "sponsor",
    views: [
      {
        path: "/sponsorRequests",
        name: "Sponsors Request",
        mini: "SR",
        component: SponsorRequestsView,
        layout: "/main",
      },
      {
        path: "/sponsors",
        name: "Sponsors",
        mini: "SN",
        component: SponsorRequestsView,
        layout: "/main",
      },
    ],
  },
  role: {
    path: "/roles",
    name: "Roles",
    icon: "ti-rocket",
    layout: "/main",
  },
  zones: {
    collapse: true,
    name: "zones",
    icon: "ti-layout-column3-alt",
    state: "zones",
    views: [
      {
        path: "/zones",
        name: "Zone List",
        mini: "ZL",
        component: ZonesView,
        layout: "/main",
      },
      {
        path: "/createZones",
        name: "Create Zones",
        mini: "CZ",
        layout: "/main",
        component: CreateZonesView,
      },
    ],
  },
  tmpEvent: {
    path: "/tmpEvent",
    name: "TmpEvent",
    icon: "ti-wand",
    layout: "/main",
  },
  communities: {
    path: "/communities",
    name: "Communities",
    icon: "ti-hand-open",
    layout: "/main",
  },
  posts: {
    path: "/posts",
    name: "Posts",
    icon: "ti-blackboard",
    layout: "/main",
  },
  chats: {
    path: "/chats",
    name: "Chats",
    icon: "ti-comment",
    layout: "/main",
    hasBadge: true,
  },
  mailing: {
    path: "/mailing",
    name: "Mailing",
    icon: "ti-email",
    layout: "/main",
  },
};

function routes(auth) {
  var routes = [basedRoutes.dashboard];
  if (!auth) return routes;
  if (auth.role === "cs") {
    routes.push(basedRoutes.transaction);
    routes.push(basedRoutes.user);
    routes.push(basedRoutes.communities);
    routes.push(basedRoutes.posts);
    routes.push(basedRoutes.chats);
  } else if (auth.role === "bd") {
    routes.push(basedRoutes.banner);
    routes.push(basedRoutes.sponsor);
  } else if (auth.role === "mkt") {
    routes.push(basedRoutes.banner);
    routes.push(basedRoutes.sponsor);
    routes.push(basedRoutes.promoCode);
    routes.push(basedRoutes.notification);
    routes.push(basedRoutes.communities);
    routes.push(basedRoutes.mailing);
    routes.push(basedRoutes.chats);
    routes.push(basedRoutes.editHomePage);
    routes.push(basedRoutes.zones);
    routes.push(basedRoutes.tmpEvent);
  } else if (auth.role === "admin") {
    routes.push(basedRoutes.transaction);
    routes.push(basedRoutes.user);
    routes.push(basedRoutes.banner);
    routes.push(basedRoutes.promoCode);
    routes.push(basedRoutes.notification);
    routes.push(basedRoutes.mailing);
    routes.push(basedRoutes.editHomePage);
    routes.push(basedRoutes.sponsor);
    routes.push(basedRoutes.role);
    routes.push(basedRoutes.zones);
    routes.push(basedRoutes.communities);
    routes.push(basedRoutes.posts);
    routes.push(basedRoutes.chats);
    routes.push(basedRoutes.tmpEvent);
  }
  return routes;
}

export default routes;
