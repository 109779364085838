import React from "react";
import { Route } from "react-router-dom";
import { CommunitiesTable, CommunityDetail } from "./index";
import { Card, CardBody } from "reactstrap";

const CommunitiesView = props => {
  return (
    <Card>
      <CardBody>
        <Route exact path="/main/communities" component={CommunitiesTable} />
        <Route
          path="/main/communities/detail/:id"
          component={CommunityDetail}
        />
      </CardBody>
    </Card>
  );
};

export default CommunitiesView;
