import React, { Component, useState } from "react";
import { DragSource, DropTarget } from "react-dnd";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from "reactstrap";

function getCategoryDefaultIndex(categories, section) {
  if (section.serverType !== "categoryPosts") return 0;
  return categories.findIndex(
    category => category.code === section.moreAction.category
  );
}
function getCategoryDefaultPriority(section) {
  if (section.serverType !== "categoryPosts") return "none";
  return section.priority || "none";
}
function getZoneDefaultIndex(zones, section) {
  if (section.serverType !== "zonePosts") return 0;
  return zones.findIndex(zone => zone.id === section.moreAction.zoneID);
}
function getDefaultTitleObj(section) {
  if (section.serverType !== "inviteFriends")
    return {
      title: {
        zh_TW: "",
        en: ""
      },
      body: {
        zh_TW: "",
        en: ""
      }
    };
  return section.inviteFriend;
}
const EditCardModal = props => {
  let zoneIndex = getZoneDefaultIndex(props.zones, props.section);
  let categoryIndex = getCategoryDefaultIndex(props.categories, props.section);
  let categoryPriority = getCategoryDefaultPriority(props.section);
  let titleObj = getDefaultTitleObj(props.section);
  function getZoneIndex(index) {
    zoneIndex = index;
    return index;
  }
  function getCategoryIndex(index, priority) {
    categoryIndex = index;
    categoryPriority = priority;
    return index;
  }
  function getTitleObj(obj) {
    titleObj = obj;
    return obj;
  }
  return (
    <Modal isOpen={props.showModal}>
      <ModalHeader>edit info</ModalHeader>
      <ModalBody>
        <CategoryBody
          categories={props.categories}
          getCategoryIndex={getCategoryIndex}
          defaultIndex={categoryIndex}
          defaultPriority={categoryPriority}
          visible={props.section.serverType === "categoryPosts"}
        />
        <ZoneBody
          zones={props.zones}
          getZoneIndex={getZoneIndex}
          defaultIndex={zoneIndex}
          visible={props.section.serverType === "zonePosts"}
        />
        <InviteFriendBody
          getTitleObj={getTitleObj}
          defaultObj={titleObj}
          visible={props.section.serverType === "inviteFriends"}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() =>
            props.closeModal(
              true,
              zoneIndex,
              categoryIndex,
              categoryPriority,
              titleObj
            )
          }
        >
          save
        </Button>
        <Button color="secondary" onClick={() => props.closeModal(false)}>
          cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const CategoryBody = props => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(props.defaultIndex);
  const [isPrioorityDropOpen, setIsPriorityDropOpen] = useState(false);
  const [priority, setPriority] = useState(props.defaultPriority);
  const priorityArr = ["first", "second", "third", "none"];
  props.getCategoryIndex(categoryIndex, priority);
  return (
    <div style={{ display: props.visible ? "" : "none" }}>
      <Dropdown isOpen={isDropOpen} toggle={() => setIsDropOpen(!isDropOpen)}>
        <DropdownToggle caret>category</DropdownToggle>
        <DropdownMenu>
          {props.categories.map((category, index) => (
            <DropdownItem
              onClick={() => setCategoryIndex(index)}
              key={category.code}
            >
              {category.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {props.categories[categoryIndex].name || "Select a category"}
      <Dropdown
        isOpen={isPrioorityDropOpen}
        toggle={() => setIsPriorityDropOpen(!isPrioorityDropOpen)}
      >
        <DropdownToggle caret>alternate</DropdownToggle>
        <DropdownMenu>
          {priorityArr.map(element => (
            <DropdownItem onClick={() => setPriority(element)} key={element}>
              {element}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {`Category alternate to users most often: ${priority}`}
    </div>
  );
};
const ZoneBody = props => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [zoneIndex, setZoneIndex] = useState(props.defaultIndex);
  props.getZoneIndex(zoneIndex);
  return (
    <div style={{ display: props.visible ? "" : "none" }}>
      <Dropdown isOpen={isDropOpen} toggle={() => setIsDropOpen(!isDropOpen)}>
        <DropdownToggle caret>zone</DropdownToggle>
        <DropdownMenu>
          {props.zones.map((zone, index) => (
            <DropdownItem onClick={() => setZoneIndex(index)} key={zone.id}>
              {zone.name.zh_TW}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
      {props.zones[zoneIndex].name.zh_TW || "Select a zone"}
    </div>
  );
};
const InviteFriendBody = props => {
  const [inviteTitle_zh_TW, setInviteTitle_zh_TW] = useState(
    props.defaultObj.title.zh_TW
  );
  const [inviteTitle_en, setInviteTitle_en] = useState(
    props.defaultObj.title.en
  );
  const [inviteBody_zh_TW, setInviteBody_zh_TW] = useState(
    props.defaultObj.body.zh_TW
  );
  const [inviteBody_en, setInviteBody_en] = useState(props.defaultObj.body.en);
  props.getTitleObj({
    title: {
      zh_TW: inviteTitle_zh_TW,
      en: inviteTitle_en
    },
    body: {
      zh_TW: inviteBody_zh_TW,
      en: inviteBody_en
    }
  });
  return (
    <div style={{ display: props.visible ? "" : "none" }}>
      invite friend title zh_TW
      <Input
        onChange={e => setInviteTitle_zh_TW(e.target.value)}
        value={inviteTitle_zh_TW}
      />
      invite friend title en
      <Input
        onChange={e => setInviteTitle_en(e.target.value)}
        value={inviteTitle_en}
      />
      <br />
      invite friend body zh_TW
      <Input
        onChange={e => setInviteBody_zh_TW(e.target.value)}
        value={inviteBody_zh_TW}
      />
      invite friend body en
      <Input
        onChange={e => setInviteBody_en(e.target.value)}
        value={inviteBody_en}
      />
    </div>
  );
};

// dragging settings
const dragSpec = {
  beginDrag(props, monitor, component) {
    return { ...props.section, index: props.index };
  }
};
const dragCollection = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};
// dropping settings
const dropSpec = {
  drop(props, monitor, component) {
    props.moveItem(monitor.getItem(), props.index);
  }
};
const dropCollection = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

class EditHomePageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }
  pressEdit = () => {
    this.setState({ showModal: true });
  };
  closeModal = (
    hasEdit,
    zoneIndex,
    categoryIndex,
    categoryPriority,
    inviteObj
  ) => {
    this.setState({ showModal: false });
    if (hasEdit) {
      const { section, editItem, index } = this.props;
      switch (section.serverType) {
        case "zonePosts":
          section.title = this.props.zones[zoneIndex].name;
          section.moreAction.zoneID = this.props.zones[zoneIndex].id;
          break;
        case "categoryPosts":
          section.title = this.props.categories[categoryIndex].title;
          section.categoryImgURL = this.props.categories[
            categoryIndex
          ].imageURL;
          section.moreAction.category = this.props.categories[
            categoryIndex
          ].code;
          if (categoryPriority !== "none") {
            section.priority = categoryPriority;
          } else {
            delete section.priority;
          }
          break;
        case "inviteFriends":
          section.inviteFriend = inviteObj;
          break;
        default:
          break;
      }
      editItem(section, index);
    }
  };
  render() {
    const {
      connectDragSource,
      connectDropTarget,
      isDragging,
      isOver,
      deleteItem,
      section
    } = this.props;
    const hasEdit = ["zonePosts", "categoryPosts", "inviteFriends"].includes(
      section.serverType
    );
    return connectDropTarget(
      <div
        style={{
          borderTop: isOver ? "3px solid blue" : "",
          padding: "8px 0"
        }}
      >
        {connectDragSource(
          <div
            style={{
              border: "1px solid black",
              padding: "8px 4px",
              backgroundColor: isDragging ? "red" : ""
              // borderTop: isOver ? "3px solid green" : "1px solid black"
            }}
          >
            {`${this.props.section.serverType} / ${
              this.props.section.title
                ? this.props.section.title.zh_TW
                : "no title"
            }`}
            <Button color="danger" onClick={deleteItem}>
              delete
            </Button>
            {hasEdit && (
              <>
                <Button color="info" onClick={this.pressEdit}>
                  edit
                </Button>
                <EditCardModal
                  showModal={this.state.showModal}
                  closeModal={this.closeModal}
                  categories={this.props.categories}
                  zones={this.props.zones}
                  section={section}
                />
              </>
            )}
            {this.props.section.serverType === "categoryPosts"
              ? ` user alternate : ${this.props.section.priority || "none"}`
              : ""}
          </div>
        )}
      </div>
    );
  }
}

const HOC_pipe = [
  DragSource("homePageCard", dragSpec, dragCollection),
  DropTarget("homePageCard", dropSpec, dropCollection)
];

export default HOC_pipe.reduce(
  (acc, curFunc) => curFunc(acc),
  EditHomePageCard
);
