import { notificationConstants } from "../constants";

const initialState = {};

export function notification(state = initialState, action) {
  switch (action.type) {
    case notificationConstants.GET_NOTIFICATIONS_REQUEST:
      delete state.error;
      return {
        ...state,
        loading: true,
      };
    case notificationConstants.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.notifications,
      };
    case notificationConstants.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case notificationConstants.SEND_NOTIFICATIONS_REQUEST:
      delete state.error;

      return { ...state, sending: true };
    case notificationConstants.SEND_NOTIFICATIONS_SUCCESS:
      const { notifications } = state;

      if (notifications) {
        notifications.unshift(action.notification);
      }
      return { ...state, sending: false, notifications };
    case notificationConstants.SEND_NOTIFICATIONS_FAILURE:
      return { ...state, sending: false, error: action.error };
    case notificationConstants.SEND_NOTIFICATION_TEST_FAILURE:
      return { ...state, sending: false, error: action.error };
    case notificationConstants.SCHEDULE_NOTIFICATIONS_REQUEST:
      delete state.error;

      return { ...state, sending: true };
    case notificationConstants.SCHEDULE_NOTIFICATIONS_SUCCESS:
      const { jobs } = state;
      if (jobs) {
        jobs.unshift(action.job);
      }
      console.log(" jobs ---> ", jobs);
      return { ...state, sending: false, jobs: jobs || [action.job] };
    case notificationConstants.SCHEDULE_NOTIFICATIONS_FAILURE:
      return { ...state, sending: false, error: action.error };

    case notificationConstants.GET_SCHEDULEDJOBS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case notificationConstants.GET_SCHEDULEDJOBS_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: action.jobs,
      };
    case notificationConstants.GET_SCHEDULEDJOBS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case notificationConstants.CANCEL_SCHEDULE_NOTIFICATIONS_REQUEST:
      return { ...state, sending: true };
    case notificationConstants.CANCEL_SCHEDULE_NOTIFICATIONS_SUCCESS: {
      const { jobs } = state;
      var newJobs = jobs;
      if (newJobs) {
        console.log(" new jobs ->  ", newJobs);
        console.log(" job id ->  ", action.job.id);

        newJobs = jobs.filter(function(job) {
          return job.id != action.job.id;
        });
      }

      return { ...state, sending: false, jobs: newJobs };
    }

    case notificationConstants.CANCEL_SCHEDULE_NOTIFICATIONS_FAILURE:
      return { ...state, sending: false };

    default:
      return state;
  }
}
