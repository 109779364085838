import { authHeader } from "../helpers";
import QueryString from "query-string";
export const dashboardService = {
  getTransactoinCount,
  getDetailTransactionCount,
  getCategoryInfos
};

function getTransactoinCount(startDate, endDate, type, status, country) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  var countryArg = undefined;
  console.clear();
  console.log("countryyyyyy", country);
  if (country === 1) countryArg = "TW";
  else if (country === 2) countryArg = "US";
  var data = {
    startDate: startDate.getTime(),
    endDate: endDate.getTime(),
    type: type,
    status: status === "all" ? undefined : status,
    country: countryArg
  };
  var args = "?" + QueryString.stringify(data);
  return fetch(
    host + "/api/admin/v1/transactionCount" + args,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function getDetailTransactionCount(startDate, endDate, type, status, country) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  // var countryArg = undefined;
  // if (country === 1) countryArg = "TW";
  // else if (country === 2) countryArg = "US";
  if (type === "familyMart" && status === "cancelled") {
    var data = {
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
      type: "all"
    };
    var args = "?" + QueryString.stringify(data);
    return fetch(
      host + "/api/admin/v1/familyMartCancelledTansactionCount" + args,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  } else return Promise.reject(new Error("not implemented yet"));
}

function getCategoryInfos(startDate, endDate, country) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  var countryArg = undefined;
  if (country === 1) countryArg = "TW";
  else if (country === 2) countryArg = "US";

  countryArg = "TW";
  var data = {
    startAt: startDate.getTime(),
    endAt: endDate.getTime(),
    country: countryArg
  };
  var args = "?" + QueryString.stringify(data);
  return fetch(
    host + "/api/admin/v1/categoryInfos" + args,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
    // return [
    //   {
    //     category: "Kids & Baby",
    //     total: 3211,
    //     taken: 1255,
    //     taker: 611,
    //     giver: 375
    //   }
    // ];
  });
}
