import React, { useState } from "react";
import { Label } from "reactstrap";
import { PostImage } from "../PostGrid";
import Utility from "../../helpers/utility";
import { DeleteMessage } from "./index";
import { UserContent } from "../index";

const MessageLine = props => {
  return (
    <div
      style={{
        textAlign: props.isOwn ? "right" : "left",
        // border: "1px solid red",
        padding: "4px",
        margin: "20px"
      }}
    >
      {props.children}
    </div>
  );
};
const MessageBox = props => {
  return (
    <div
      style={{
        border: "1px solid #ccc",
        display: "inline-block",
        borderRadius: "8px",
        padding: "8px",
        backgroundColor: "#fff",
        position: "relative"
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.children}
    </div>
  );
};
const MessageOwner = props => {
  const { isOwn, senderName, senderImageUrl, isHover, messageId } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: isOwn ? "flex-end" : "flex-start",
        position: "absolute",
        top: "-20px",
        right: isOwn ? "0" : ""
      }}
    >
      <span
        style={{
          order: isOwn ? "1" : "2",
          margin: "0 4px",
          whiteSpace: "nowrap",
          color: "#fff",
          fontWeight: "bold"
        }}
      >
        {senderName}
      </span>
      <span
        style={{
          order: isOwn ? "2" : "1",
          margin: "0 4px",
          display: "inline-block",
          width: "30px",
          height: "30px",
          border: "1px solid #ccc",
          borderRadius: "50%",
          color: "transparent",
          backgroundImage: `url(${senderImageUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
        {/* {senderImageUrl} */}
      </span>
      {isOwn && <DeleteMessage isHover={isHover} messageId={messageId} />}
    </div>
  );
};

const ChatMessage = ({ message, isOwn, avatar }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <MessageLine isOwn={isOwn}>
      <MessageBox
        isOwn={isOwn}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <MessageOwner
          isOwn={isOwn}
          senderName={message.sender_name}
          messageId={message.id}
          senderImageUrl={avatar}
          isHover={isHover}
        />
        {message.msgType === "IMAGE" ? (
          <PostImage path={message.imageURL} />
        ) : (
          <div style={{ textAlign: "left" }}>{message.message}</div>
        )}
        <Label>{Utility.timestampFromNowString(message.createdAt)}</Label>
        {message.admin_uuid && message.admin_uuid !== "Unauthorized" && (
          <UserContent onlyName id={message.admin_uuid} />
        )}
      </MessageBox>
    </MessageLine>
  );
};

export default ChatMessage;
