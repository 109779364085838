import React, { Component } from "react";
// import { render } from "react-dom";
import { bannerActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import BannerTable from "./bannerTable.jsx";
import moment from "moment";
import firebase from "firebase/app";

import {
  // Card,
  // CardBody,
  // CardHeader,
  CardTitle,
  Button,
  FormGroup,
  Row,
  Col,
  // Input,
  Label,
  Alert
} from "reactstrap";

class BannerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: {
        id: "",
        data: {
          name: "",
          action: "webView",
          country: "TW",
          isVisible: false,
          startDate: moment(),
          endDate: moment(),
          metadata: {
            en: { description: "", imageURL: "", link: "", title: "" },
            zh_TW: { description: "", imageURL: "", link: "", title: "" }
          }
        }
      }
    };

    if (this.props.banner.banners && this.props.banner.banners.length > 0) {
      var banners = this.props.banner.banners.filter(el => {
        return el.id === this.props.match.params.id;
      });

      if (banners.length > 0) {
        let banner = banners[0];
        this.state = {
          banner: banner
        };
        if (banner.data.metadata.zh_TW.imageURL) {
          this.getImageUrl(banner.data.metadata.zh_TW.imageURL).then(url => {
            banner.data.metadata.zh_TW.httpBannerURL = url;
            this.setState({ banner });
          });
        }
        if (banner.data.metadata.en.imageURL) {
          this.getImageUrl(banner.data.metadata.en.imageURL).then(url => {
            banner.data.metadata.en.httpBannerURL = url;
            this.setState({ banner });
          });
        }
      }
    } else if (this.props.banner.banner) {
      this.state = {
        banner: this.props.banner.banner
      };
    } else {
      if (this.props.match.params && this.props.match.params.id) {
        const { dispatch } = this.props;
        dispatch(bannerActions.get(this.props.match.params.id));
      }
    }
    this.handleClickEdit = this.handleClickEdit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.banner) {
      if (nextProps.banner.banner) {
        var banner = nextProps.banner.banner;
        this.setState({ banner });

        if (banner.data.metadata.zh_TW.imageURL) {
          this.getImageUrl(banner.data.metadata.zh_TW.imageURL).then(url => {
            banner.data.metadata.zh_TW.httpBannerURL = url;
            this.setState({ banner });
          });
        }
        if (banner.data.metadata.en.imageURL) {
          this.getImageUrl(banner.data.metadata.en.imageURL).then(url => {
            banner.data.metadata.en.httpBannerURL = url;
            this.setState({ banner });
          });
        }
      }

      if (nextProps.banner.actionInfo) {
        let actionInfo = nextProps.banner.actionInfo;
        this.setState({ actionInfo });
      }

      if (nextProps.alert.message) {
        this.setState({ alertMessage: nextProps.alert.message });
      } else {
        this.setState({ alertMessage: undefined });
      }
    }
  }

  handleCreate() {}

  handleClickEdit() {}

  onClickApprove = () => {
    const { dispatch } = this.props;
    var banner = this.state.banner;
    banner.data.status = "approved";
    dispatch(
      bannerActions.update(this.props.match.params.id, banner, "approve")
    );
  };

  onClickReject = () => {
    const { dispatch } = this.props;
    var banner = this.state.banner;
    banner.data.status = "rejected";
    dispatch(
      bannerActions.update(this.props.match.params.id, banner, "reject")
    );
  };

  getImageUrl = gsUrl => {
    var storage = firebase.storage();
    return storage.ref(gsUrl).getDownloadURL();
  };

  render() {
    return (
      <>
        {this.state.alertMessage && (
          <Alert color="danger">{this.state.alertMessage}</Alert>
        )}
        <div className="toolbar" />
        <CardTitle />
        <Row>
          <Label md="2" sm="2">
            Compaign Name
          </Label>
          <Col md="6" sm="6">
            <FormGroup>
              <p className="form-control-static">
                {this.state.banner.data.metadata.zh_TW.title}
              </p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label md="2" sm="2">
            Status
          </Label>
          <Col md="6" sm="6">
            <FormGroup>
              <p className="form-control-static">
                {this.state.banner.data.status}
              </p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label md="2" sm="2">
            Type
          </Label>
          <Col md="6" sm="6">
            <FormGroup>
              <p className="form-control-static">
                {this.state.banner.data.action}
              </p>
            </FormGroup>
          </Col>
        </Row>
        {Object.keys(this.state.banner.data.metadata).map(key => {
          let value = this.state.banner.data.metadata[key];
          let title =
            key == "zh_TW" ? "Chinese" : key == "en" ? "English" : "unknown";
          return (
            <div key={key}>
              <Row>
                <label>
                  <strong>{title}</strong>
                </label>
              </Row>
              <Row>
                <Label md="2" sm="2">
                  Title
                </Label>
                <Col md="6" sm="6">
                  <FormGroup>
                    <p className="form-control-static">{value.title}</p>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label md="2" sm="2">
                  Image
                </Label>
                <Col md="6" sm="6">
                  <FormGroup>
                    <img
                      alt="..."
                      className="img-fluid img-border"
                      src={value.httpBannerURL}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {(this.state.banner.data.action == "link" ||
                this.state.banner.data.action == "browser") && (
                <>
                  <Row>
                    <Label md="2" sm="2">
                      Link
                    </Label>
                    <Col md="6" sm="6">
                      <FormGroup>
                        <p className="form-control-static">{value.link}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label md="2" sm="2">
                      Description
                    </Label>
                    <Col md="6" sm="6">
                      <FormGroup>
                        <p className="form-control-static">
                          {value.description}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          );
        })}

        <Row>
          <Col md="12" className="text-right">
            {this.state.banner.data.status === "pending" && (
              <>
                <Button onClick={this.onClickApprove}>
                  Approve
                  {this.state.actionInfo &&
                    this.state.actionInfo.action === "approve" &&
                    this.state.actionInfo.loading && (
                      <img
                        alt="..."
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    )}
                </Button>
                <Button onClick={this.onClickReject}>
                  reject
                  {this.state.actionInfo &&
                    this.state.actionInfo.action === "reject" &&
                    this.state.actionInfo.loading && (
                      <img
                        alt="..."
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    )}
                </Button>
              </>
            )}
            <Link to={`/main/banners/edit/${this.state.banner.id}`}>
              <button className="btn btn-info btn-fill">Edit</button>
            </Link>
          </Col>
        </Row>
      </>
    );
  }
}

BannerDetail.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { banner, alert } = state;
  return { banner, alert };
};

export default connect(mapStateToProps)(BannerDetail);
