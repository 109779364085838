import { ratingsConstants } from "../constants";
import { transactionService } from "../services";
// import { alertActions } from "./";
import swal from "sweetalert";

export const ratingsActions = {
  get,
  updateRatings,
  deleteRatings
};

function get(transaction) {
  return dispatch => {
    dispatch(request({}));
    transactionService.getRatings(transaction).then(
      ratings => {
        dispatch(success(ratings));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(ratings) {
    return { type: ratingsConstants.GET_REQUEST, ratings };
  }
  function success(ratings) {
    return { type: ratingsConstants.GET_SUCCESS, ratings };
  }
  function failure(error) {
    return { type: ratingsConstants.GET_FAILURE, error };
  }
}

function updateRatings(transaction, rating) {
  return dispatch => {
    var name = rating.name;
    rating.data.updating = true;
    var ratingData = rating.data;
    dispatch(request(name, ratingData));
    transactionService.updateRatings(transaction, rating).then(
      newRating => {
        newRating.updating = false;
        dispatch(success(name, newRating));
      },
      error => {
        ratingData.updating = false;
        dispatch(failure(error, name, ratingData));
      }
    );
  };

  function request(name, ratingData) {
    return { type: ratingsConstants.UPDATE_REQUEST, name, ratingData };
  }
  function success(name, newRating) {
    return { type: ratingsConstants.UPDATE_SUCCESS, name, newRating };
  }
  function failure(error, name, ratingData) {
    return { type: ratingsConstants.UPDATE_FAILURE, error, name, ratingData };
  }
}

function deleteRatings(transaction, rating) {
  return dispatch => {
    // var name = rating.name;
    dispatch(request({}));
    transactionService.deleteRatings(transaction, rating).then(
      result => {
        dispatch(success(rating.name));

        swal({
          title: "Deleted!",
          text: "You have deleted this payment.",
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "btn btn-success btn-fill",
              closeModal: true
            }
          },
          buttonsStyling: false
        });
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(name) {
    return { type: ratingsConstants.DELETE_REQUEST, name };
  }
  function success(name) {
    return { type: ratingsConstants.DELETE_SUCCESS, name };
  }
  function failure(error) {
    return { type: ratingsConstants.DELETE_FAILURE, error };
  }
}
