import React, { Component } from "react";
import { communitiesService } from "../../services";
import { PostGrid } from "../../components/PostGrid";
import { Row, Label, Col, Button } from "reactstrap";
import Utility from "../../helpers/utility";
import { UserContent } from "../../components";
import swal from "sweetalert";

const DisplayRow = props => {
  return (
    <Row>
      <Label md="2" sm="2">
        {props.label}
      </Label>
      <Col md="6" sm="6">
        {props.value}
      </Col>
    </Row>
  );
};
class CommunityDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      community: null,
      nextClick: 0,
      isLastPage: false
    };
  }
  getPosts = () => {
    // will need id to querry the post in the future
    const communityId = this.props.match.params.id;
    communitiesService.getCommunityPosts(communityId).then(data =>
      this.setState({
        posts: data,
        nextClick: 0,
        isLastPage: data.length < communitiesService.pageLimit
      })
    );
  };
  previousPage = () => {
    const { posts, nextClick } = this.state;
    const communityId = this.props.match.params.id;
    if (nextClick <= 1) {
      this.getPosts();
    } else {
      communitiesService
        .getCommunityPosts(communityId, posts[0].createdAt, "previous")
        .then(data =>
          this.setState(preState => ({
            posts: data,
            nextClick: preState.nextClick - 1,
            isLastPage: data.length < communitiesService.pageLimit
          }))
        );
    }
  };
  nextPage = () => {
    const communityId = this.props.match.params.id;
    const { posts } = this.state;
    communitiesService
      .getCommunityPosts(communityId, posts[posts.length - 1].createdAt)
      .then(data => {
        if (data.length > 0) {
          this.setState(preState => ({
            posts: data,
            nextClick: preState.nextClick + 1,
            isLastPage: data.length < communitiesService.pageLimit
          }));
        } else {
          this.setState({ isLastPage: true });
        }
      });
  };
  getCommunity = () => {
    const communityId = this.props.match.params.id;
    communitiesService.getCommunityByID(communityId).then(data => {
      console.log(data);
      this.setState({ community: data });
    });
  };
  deleteCommunity = reason => {
    const communityId = this.props.match.params.id;
    communitiesService
      .deleteCommunity(communityId, reason)
      .then(data => {
        if (data.result === "ok") {
          swal("Good job!", "You successfully delete a community", "success");
        }
      })
      .catch(() => {
        swal("Oops!", "You fail to delete a community", "error");
      });
  };
  deleteModal = () => {
    swal({
      text: 'delete reason".',
      content: "input",
      button: {
        text: "delete!",
        closeModal: false
      }
    }).then(reason => {
      if (reason) {
        this.deleteCommunity(reason);
      } else {
        swal("Oops!", "You need to fill a reason", "error");
      }
    });
  };
  componentDidMount() {
    this.getCommunity();
    this.getPosts();
  }
  render() {
    const { community } = this.state;
    return community ? (
      <div>
        <DisplayRow label="name" value={community.name} />
        <DisplayRow label="id" value={community.id} />
        <DisplayRow label="description" value={community.description} />
        <DisplayRow label="announcement" value={community.announcement} />
        <DisplayRow label="memberCount" value={community.memberCount} />
        <br />
        <DisplayRow
          label="owner"
          value={<UserContent id={community.owner} />}
        />
        <br />
        <DisplayRow label="postCount" value={community.postCount} />
        <DisplayRow
          label="privacy"
          value={communitiesService.privacyConverter(community.privacy)}
        />
        <DisplayRow
          label="status"
          value={communitiesService.statusConverter(community.status)}
        />
        <DisplayRow
          label="createdAt"
          value={Utility.timestampToFormattedString(community.createdAt)}
        />
        {/* <DisplayRow label="admin" value={community.admin} /> */}
        <Button onClick={this.deleteModal}>delete community</Button>
        <br />
        <br />
        <h3>Posts : </h3>
        <PostGrid posts={this.state.posts} />
        <Button onClick={this.previousPage}>
          {this.state.nextClick ? "previos" : "refresh"}
        </Button>
        <Button onClick={this.nextPage} disabled={this.state.isLastPage}>
          next
        </Button>
        <span style={{ margin: "0 8px", fontSize: "20px" }}>
          Page {this.state.nextClick + 1}
        </span>
      </div>
    ) : (
      <div>loading...</div>
    );
  }
}

export default CommunityDetail;
