import React, { Component } from "react";
import { PostGrid } from "../../components/PostGrid";
import { postService } from "../../services";
import { Button } from "reactstrap";

class PostsGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      nextClick: 0,
      isLastPage: false
    };
  }
  getPosts = () => {
    // get the first page
    postService.getPosts().then(data => {
      this.setState({
        posts: data,
        nextClick: 0,
        isLastPage: data.length < postService.pageLimit
      });
    });
  };
  nextPage = () => {
    const { posts } = this.state;
    const lastCreatedAt = posts[posts.length - 1].createdAt;
    postService.getPosts(lastCreatedAt).then(data => {
      if (data.length > 0) {
        this.setState(preState => ({
          posts: data,
          nextClick: preState.nextClick + 1,
          isLastPage: data.length < postService.pageLimit
        }));
      } else {
        this.setState({ isLastPage: true });
      }
    });
  };
  previosPage = () => {
    const { posts, nextClick } = this.state;
    if (nextClick <= 1) {
      this.getPosts();
    } else {
      const lastCreatedAt = posts[0].createdAt;
      postService.getPosts(lastCreatedAt, "previous").then(data => {
        this.setState(preState => ({
          posts: data,
          nextClick: preState.nextClick - 1,
          isLastPage: data.length < postService.pageLimit
        }));
      });
    }
  };
  componentDidMount() {
    this.getPosts();
  }
  render() {
    return (
      <div>
        <h2>Posts: </h2>
        <PostGrid posts={this.state.posts} />
        <Button onClick={this.previosPage}>
          {this.state.nextClick ? "previous" : "refresh"}
        </Button>
        <Button onClick={this.nextPage} disabled={this.state.isLastPage}>
          next
        </Button>
        <span style={{ margin: "0 8px", fontSize: "20px" }}>
          Page {this.state.nextClick + 1}
        </span>
      </div>
    );
  }
}

export default PostsGallery;
