import React, { useState } from "react";
import { Input, Button, Table } from "reactstrap";
import { connect } from "react-redux";
import { userActions, chatActions } from "../../actions";

const UserSearch = ({ serchResult, dispatch }) => {
  const [searchWord, setSearchWord] = useState("");
  return (
    <div>
      <Input value={searchWord} onChange={e => setSearchWord(e.target.value)} />
      <Button onClick={() => dispatch(userActions.search(searchWord))}>
        search
      </Button>
      <Button onClick={() => setSearchWord("")}>clear</Button>
      {searchWord && (
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Unique id</th>
              <th>display name</th>
              <th>chat</th>
            </tr>
          </thead>
          <tbody>
            {serchResult.map((user, index) => {
              return (
                <tr key={user.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{user.id}</td>
                  <td>{user.data.uniqueID}</td>
                  <td>{user.data.displayName}</td>
                  <td>
                    <Button
                      onClick={() =>
                        dispatch(chatActions.setSingleChattingID(user.id))
                      }
                    >
                      chat
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};
const mapState2Props = state => {
  return { serchResult: state.users.users };
};
export default connect(mapState2Props)(UserSearch);
