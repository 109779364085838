import React, { Component } from "react";
import Select from "react-select";
import { Input, Button, Card, CardBody } from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
import swal from "sweetalert";
import { promoCodeService } from "../../services";

const typeOption = [
  {
    value: "drawing",
    label: "Lucky drawing"
  },
  {
    value: "shipping",
    label: "Shipping discount"
  },
  {
    value: "tp",
    label: "Free tp"
  }
];
const countryOption = [
  {
    value: "TW",
    label: "TW"
  },
  {
    value: "US",
    label: "US"
  }
];
class CreatePromoCodeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: countryOption[0], //"TW", // US
      description: {
        en: "",
        zh_TW: ""
      },
      startAt: moment()
        .add(15, "minute")
        .valueOf(), // might set as currenet moment
      endAt: moment()
        .add(1, "Month")
        .valueOf(), // might set a month from now
      expireType: "endAt", // dont know if it is neccessary to need this key
      title: {
        en: "",
        zh_TW: ""
      },
      type: typeOption[0], // drawing shipping tp
      validationText: {
        en: "",
        zh_TW: ""
      },
      discount: 0,
      promocode: ""
    };
  }
  handlePromocodeChange = promocode => {
    this.setState({ promocode });
  };
  handleTypeChange = selectedTypeOption => {
    this.setState({ type: selectedTypeOption, discount: 0 });
    console.log(selectedTypeOption);
  };
  handleCountryChange = selectedCountry => {
    this.setState({ country: selectedCountry });
  };
  handleTitleChange = (value, lang) => {
    this.setState(preState => {
      preState.title[lang] = value;
      return { title: preState.title };
    });
  };
  handleDescriptionChange = (value, lang) => {
    this.setState(preState => {
      preState.description[lang] = value;
      return { description: preState.description };
    });
  };
  handleValidationTextChange = (value, lang) => {
    this.setState(preState => {
      preState.validationText[lang] = value;
      return { validationText: preState.validationText };
    });
  };
  handleDateChange = (date, key) => {
    console.log(date);
    this.setState(preState => {
      if (key === "endAt" && preState.startAt > date.valueOf()) {
        return {
          [key]: moment(preState.startAt)
            .add(1, "month")
            .valueOf()
        };
        // might need to add alert that end date need to be bigger
      }
      return {
        [key]: date.valueOf()
      };
    });
  };
  validateStartDate = currDate => {
    const yesterday = moment().subtract(1, "day");
    return currDate > yesterday;
  };
  validateEndDate = currDate => {
    const startDate = moment(this.state.startAt);
    return currDate > startDate;
  };
  handleDiscountChange = value => {
    this.setState({ discount: value });
  };
  hasEmptyField = () => {
    let hasEmpty = false;
    Object.keys(this.state).forEach(key => {
      console.log(key);
      switch (key) {
        case "description":
        case "title":
        case "validationText":
        case "country":
        case "type":
          hasEmpty = Object.keys(this.state[key]).reduce((acc, cur) => {
            return this.state[key][cur] ? acc : true;
          }, hasEmpty);
          break;
        default:
          if (
            this.state[key] === "" ||
            this.state[key] === undefined ||
            this.state[key] === null
          )
            hasEmpty = true;
          break;
      }
    });
    console.log("hasss emprtyy??", hasEmpty);
    return hasEmpty;
  };
  submit = () => {
    if (this.hasEmptyField()) {
      swal("Oops!", "there are fields are empty", "error");
      return;
    }
    const { discount } = this.state;
    const floatReg = /^\d+(\.\d+)?$/;
    const discountValidate = floatReg.test(discount);
    const floatDiscount = parseFloat(discount, 10);
    if (discountValidate && floatDiscount >= 0 && floatDiscount < 1) {
      console.log(floatDiscount);
      console.log(this.state);
      // submit herer!!!!!
      const submitObj = {
        ...this.state,
        type: this.state.type.value,
        country: this.state.country.value,
        discountRate: (-1 * Math.floor((1 - floatDiscount) * 100)) / 100
      };
      delete submitObj.promocode;
      delete submitObj.discount;
      if (this.state.type !== typeOption[1]) {
        delete submitObj.discountRate;
        delete submitObj.expireType;
      }
      console.log(submitObj);
      promoCodeService
        .createPromoCode(this.state.promocode, submitObj)
        .then(data => {
          if (data.result === "ok") {
            swal("Good job!", "You successfully create a zone", "success");
          }
        })
        .catch(() => {
          swal("Oops!", "You fail to create a zone", "error");
        });
    } else {
      swal("Oops!", "discount number must >= 0 or < 1", "error");
    }
  };
  render() {
    return (
      <Card>
        <CardBody>
          <div>
            <div>promocode</div>
            <Input
              value={this.state.promocode}
              onChange={e => this.handlePromocodeChange(e.target.value)}
            />
            <br />
            <div>type</div>
            <Select
              options={typeOption}
              onChange={this.handleTypeChange}
              value={this.state.type}
            />
            <br />
            <div>country</div>
            <Select
              options={countryOption}
              onChange={this.handleCountryChange}
              value={this.state.country}
            />
            <br />
            <div>title tw</div>
            <Input
              onChange={e => this.handleTitleChange(e.target.value, "zh_TW")}
              value={this.state.title.zh_TW}
            />
            <div>title en</div>
            <Input
              onChange={e => this.handleTitleChange(e.target.value, "en")}
              value={this.state.title.en}
            />
            <br />
            <div>description tw</div>
            <Input
              onChange={e =>
                this.handleDescriptionChange(e.target.value, "zh_TW")
              }
              value={this.state.description.zh_TW}
            />
            <div>description en</div>
            <Input
              onChange={e => this.handleDescriptionChange(e.target.value, "en")}
              value={this.state.description.en}
            />
            <br />
            <div>validationText tw</div>
            <Input
              onChange={e =>
                this.handleValidationTextChange(e.target.value, "zh_TW")
              }
              value={this.state.validationText.zh_TW}
            />
            <div>validationText en</div>
            <Input
              onChange={e =>
                this.handleValidationTextChange(e.target.value, "en")
              }
              value={this.state.validationText.en}
            />
            <br />
            <div>start at</div>
            <ReactDatetime
              value={this.state.startAt}
              onChange={date => this.handleDateChange(date, "startAt")}
              isValidDate={this.validateStartDate}
            />
            <div>end at</div>
            <ReactDatetime
              value={this.state.endAt}
              onChange={date => this.handleDateChange(date, "endAt")}
              isValidDate={this.validateEndDate}
            />
            <br />
            {this.state.type === typeOption[1] && (
              <>
                <div>
                  discount (number between 0 and 1, eg: 0.7 as 打7折, 0 as free
                  shipping)
                </div>
                <Input
                  onChange={e => this.handleDiscountChange(e.target.value)}
                  value={this.state.discount}
                />
              </>
            )}

            <Button onClick={this.submit}>submit</Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CreatePromoCodeView;
