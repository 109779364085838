import React, { Component } from "react";
import { render } from "react-dom";
import { Route, HashRouter } from "react-router-dom";
// import { createStore } from "redux";
import { Provider } from "react-redux";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContextProvider } from "react-dnd";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss";
import "./assets/scss/vin.scss";

import "./css/style.css";
import "./css/animate.min.css";
import "./css/themify-icons.css";

import "bootstrap-switch";

// import "./js/lib/paper-dashboard.js";

import store from "./js/store";

// import { history } from "./js/helpers";
import { PrivateRoute } from "./js/components";
import { HomePage } from "./js/homePage";
import { LoginPage } from "./js/loginPage";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var host = window.location.hostname;
var config;
if (host === "admin.taptot.com") {
  config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE,
    projectId: process.env.REACT_APP_PROJECT,
    storageBucket: process.env.REACT_APP_STORAGE,
    messagingSenderId: process.env.REACT_APP_SENDERID
  };
} else {
  config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE,
    projectId: process.env.REACT_APP_PROJECT,
    storageBucket: process.env.REACT_APP_STORAGE,
    messagingSenderId: process.env.REACT_APP_SENDERID
  };
}

firebase.initializeApp(config);
//firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this line is required to work on plunker because the app preview runs on a subfolder url
    //history.push('/');
    // const { dispatch } = this.props;
    // history.listen((location, action) => {
    //     // clear alert on location change
    //     dispatch(alertActions.clear());
    // });
  }

  render() {
    return (
      <Provider store={store}>
        <DragDropContextProvider backend={HTML5Backend}>
          <HashRouter>
            <div>
              <PrivateRoute exact path="/" component={HomePage} />
              <PrivateRoute path="/main" component={HomePage} />
              <Route path="/login" component={LoginPage} />
            </div>
          </HashRouter>
        </DragDropContextProvider>
      </Provider>
    );
  }
}

render(<App />, document.getElementById("app"));
