import React, { Component } from "react";
import { promoCodeActions } from "../../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Route, Link } from "react-router-dom";
//import BannerTable from "./bannerTable.jsx";
// import moment from "moment";
// import firebase from "firebase/app";
import UserItem from "./promoCodeUserItem.jsx";

import {
  // Card,
  // CardBody,
  // CardHeader,
  CardTitle,
  // Button,
  FormGroup,
  Row,
  Col,
  // Input,
  Label
  // Alert
} from "reactstrap";

class PromoCodeDetail extends Component {
  constructor(props) {
    super(props);
    console.log(" PromoCodeDetail  init ");
    this.state = { detail: {}, country: "TW", language: "en" };
    if (this.props.promoCode) {
      // var banners = this.props.banner.banners.filter(el => {
      //   return el.id === this.props.match.params.id;
      // });
      // if (banners.length > 0) {
      //   let banner = banners[0];
      //   this.state = {
      //     banner: banner
      //   };
      //   var bannerImageUrl = banner.data.metadata.zh_TW.imageURL
      //     ? banner.data.metadata.zh_TW.imageURL
      //     : banner.data.metadata.US.imageURL;
      //   if (bannerImageUrl) {
      //     this.getImageUrl(bannerImageUrl).then(url => {
      //       banner.data.httpBannerURL = url;
      //       this.setState({ banner });
      //     });
      //   }
      // }
    } else {
      if (this.props.match.params && this.props.match.params.id) {
        const { dispatch } = this.props;
        dispatch(promoCodeActions.get(this.props.match.params.id));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(" detail ->  ", nextProps.detail);
    if (nextProps.detail) {
      this.setState({ detail: nextProps.detail });
      if (
        this.state.drawingList === undefined &&
        nextProps.detail.data &&
        nextProps.detail.data.type === "drawing"
      ) {
        const { dispatch } = this.props;
        dispatch(
          promoCodeActions.getUsers(
            nextProps.detail.id,
            nextProps.detail.data.country
          )
        );
      }
    }
    if (nextProps.drawingList) {
      this.setState({ drawingList: nextProps.drawingList });
    }
  }

  handleCreate() {}

  handleClickEdit() {}

  render() {
    var detail = this.state.detail;
    var isDrawing =
      detail.data && detail.data.type && detail.data.type === "drawing"
        ? true
        : false;

    return (
      <>
        <div className="toolbar" />
        <CardTitle />
        <Row>
          <Label md="2" sm="2">
            PromoCode
          </Label>
          <Col md="6" sm="6">
            <FormGroup>
              <p className="form-control-static">{detail.id}</p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label md="2" sm="2">
            Title
          </Label>
          <Col md="6" sm="6">
            <FormGroup>
              <p className="form-control-static">
                {detail.data ? detail.data.title[this.state.language] : ""}
              </p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label md="2" sm="2">
            Type
          </Label>
          <Col md="6" sm="6">
            <FormGroup>
              <p className="form-control-static">
                {detail.data ? detail.data.type : ""}
              </p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label md="2" sm="2">
            Description
          </Label>
          <Col md="6" sm="6">
            <FormGroup>
              <p className="form-control-static">
                {detail.data
                  ? detail.data.description[this.state.language]
                  : ""}
              </p>
            </FormGroup>
          </Col>
        </Row>
        {isDrawing && (
          <>
            <Row>
              <Label md="2" sm="2">
                Drawing users count
              </Label>
              <Col md="6" sm="6">
                <FormGroup>
                  {this.state.drawingList && (
                    <p className="form-control-static">
                      {this.state.drawingList.length}
                    </p>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Label md="2" sm="2">
                Drawing List
              </Label>
              <Col md="6" sm="6">
                <FormGroup>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Photo</th>
                        <th>Display Name</th>
                        <th>Unique ID</th>
                        <th>Invite Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.drawingList &&
                        this.state.drawingList.map(el => (
                          <UserItem
                            key={el.id}
                            imageURL={el.imageURL}
                            displayName={el.displayName}
                            uniqueID={el.uniqueID}
                            inviteCode={el.inviteCode}
                          />
                        ))}
                    </tbody>
                  </table>
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

PromoCodeDetail.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { promoCode } = state;
  return promoCode;
};

export default connect(mapStateToProps)(PromoCodeDetail);
