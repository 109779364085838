import React, { Component } from "react";
// import { render } from "react-dom";
// import { userActions, sponsorActions } from "../actions";
import { sponsorActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import ImageUpload from "../components/ImageUpload.jsx";
import FileUploader from "react-firebase-file-uploader";
import ReactBSAlert from "react-bootstrap-sweetalert";
// import TagsInput from "react-tagsinput";
// import Skeleton from "react-loading-skeleton";
// import ReactLoading from "react-loading";

import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
  Button,
  FormGroup,
  Row,
  Col,
  Input,
  Label,
  Media,
  Form,
  // FormText,
  CardText,
  Badge
} from "reactstrap";
import firebase from "firebase/app";
import { sponsorService } from "../services";
import { PostGrid } from "../components/PostGrid";

// const imgStyle = {
//   maxHeight: 100,
//   maxWidth: 300
// };

class SponsorDetail extends Component {
  constructor(props) {
    super(props);
    var sponsor = { data: {} };
    this.state = { sponsor, alert: null };
    var venders = this.props.isNPO
      ? this.props.sponsor.NPOs
      : this.props.sponsor.sponsors;

    if (venders && venders.length > 0) {
      var sponsors = venders.filter(el => {
        return el.id === this.props.match.params.id;
      });
      if (sponsors.length > 0) {
        this.state = {
          sponsor: sponsors[0]
        };
        sponsor = sponsors[0];
      }
    } else if (this.props.sponsor.sponsor) {
      this.state = {
        sponsor: this.props.sponsor.sponsor
      };
      sponsor = this.props.sponsor.sponsor;
    } else {
      if (this.props.match.params && this.props.match.params.id) {
        const { dispatch } = this.props;
        dispatch(sponsorActions.getSponsor(this.props.match.params.id));
      }
    }
    if (sponsor.data.sponsorBannerURL && !this.state.sponsorBannerHttpURL) {
      this.getImageUrl(sponsor.data.sponsorBannerURL).then(url => {
        this.setState({ sponsorBannerHttpURL: url });
      });
    }
    if (
      sponsor.data.pendingInformation &&
      sponsor.data.pendingInformation.sponsorBannerURL &&
      !this.state.pendingSponsorBannerURL
    ) {
      this.getImageUrl(sponsor.data.pendingInformation.sponsorBannerURL).then(
        url => {
          this.setState({ pendingSponsorBannerURL: url });
        }
      );
    }
    if (sponsor.data.sponsorLongDescription) {
      this.state = {
        ...this.state,
        sponsorLongDescription: sponsor.data.sponsorLongDescription
      };
    }
    if (sponsor.data.sponsorWebLink) {
      this.state = {
        ...this.state,
        sponsorWebLink: sponsor.data.sponsorWebLink
      };
    }
    this.state = { ...this.state, posts: [] };
    this.handleClickApprove = this.handleClickApprove.bind(this);
    this.handleClickReject = this.handleClickReject.bind(this);
  }

  getImageUrl = gsUrl => {
    var storage = firebase.storage();
    return storage.ref(gsUrl).getDownloadURL();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.sponsor && nextProps.sponsor.sponsor) {
      var sponsor = nextProps.sponsor.sponsor;
      this.setState({ sponsor });
      if (sponsor.data.sponsorBannerURL && !this.state.sponsorBannerHttpURL) {
        this.getImageUrl(sponsor.data.sponsorBannerURL).then(url => {
          this.setState({ sponsorBannerHttpURL: url });
        });
      }
      if (
        sponsor.data.pendingInformation &&
        sponsor.data.pendingInformation.sponsorBannerURL &&
        !this.state.pendingSponsorBannerURL
      ) {
        this.getImageUrl(sponsor.data.pendingInformation.sponsorBannerURL).then(
          url => {
            this.setState({ pendingSponsorBannerURL: url });
          }
        );
      }
      if (sponsor.data.sponsorLongDescription) {
        this.setState({
          sponsorLongDescription: sponsor.data.sponsorLongDescription
        });
      }
      if (sponsor.data.sponsorWebLink) {
        this.setState({
          sponsorWebLink: sponsor.data.sponsorWebLink
        });
      }
    }
    if (nextProps.sponsor && nextProps.sponsor.updatePendingInfo) {
      const updatePendingInfo = nextProps.sponsor.updatePendingInfo;
      if (updatePendingInfo.action === "reject") {
        if (updatePendingInfo.status != null) {
          this.succeedPendingInfo(false);
        }
      }
    }
    if (nextProps.sponsor && nextProps.sponsor.updateSponsor) {
      const updateSponsor = nextProps.sponsor.updateSponsor;
      this.setState({ updateSponsor });
    }
  }

  didUpdateFile = file => {
    this.uploadingFile = file;
  };

  handleTextChange = e => {
    const { name, value } = e.target;
    if (name === "longDescription") {
      this.setState({ sponsorLongDescription: value });
    }
  };

  handleClickSave = () => {
    var info = {};
    if (this.uploadingFile) {
      info.uploadingFile = this.uploadingFile;
      info.bannerfileUploader = this.bannerfileUploader;
    }
    if (
      this.state.sponsorLongDescription !==
      this.state.sponsor.data.sponsorLongDescription
    )
      info.sponsorLongDescription = this.state.sponsorLongDescription;

    if (
      this.state.shortDescription !== this.state.sponsor.data.shortDescription
    )
      info.shortDescription = this.state.shortDescription;

    if (Object.keys(info).length) {
      const { dispatch } = this.props;
      dispatch(sponsorActions.updateSponsor(this.state.sponsor.id, info));
    }
  };

  handleClickApprove() {
    const { dispatch } = this.props;
    dispatch(sponsorActions.updatePendingInfo(this.state.sponsor.id, true));
  }

  succeedPendingInfo = approved => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={approved ? "Approved" : "Rejected"}
        >
          {approved
            ? "Your sponsor's information has been approved."
            : "Your sponsor's information has been rejected."}
        </ReactBSAlert>
      )
    });
    setTimeout(this.hideAlert, 1000);
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  rejectPendingInfo = reason => {
    const { dispatch } = this.props;
    dispatch(
      sponsorActions.updatePendingInfo(this.state.sponsor.id, false, reason)
    );
  };

  handleClickReject = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          input
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          placeHolder="Write the reason."
          onConfirm={this.rejectPendingInfo}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, reject it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You will not be able to recover this!
        </ReactBSAlert>
      )
    });
  };
  getSpoonsorPosts = () => {
    const { match } = this.props;
    const sponorId = match.params.id;
    sponsorService._getSponsorPosts(sponorId).then(data => {
      console.log(data);
      this.setState({
        posts: data
      });
    });
  };
  componentDidMount() {
    this.getSpoonsorPosts();
  }

  render() {
    const { sponsor } = this.state;
    var data = {};
    if (sponsor && sponsor.data) data = sponsor.data;
    const timestamp = data.createdAt;
    const formatted = moment(timestamp).format("LLLL");
    data.formattedCreatedAt = formatted;
    return (
      <div>
        <Form className="form-horizontal">
          <Row>
            <Label md="2" sm="2" xs="1">
              Email
            </Label>
            <Col md="6" sm="6">
              <FormGroup>
                <p className="form-control-static">{data.email}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label md="2" sm="2">
              user ID
            </Label>
            <Col md="6" sm="6">
              <FormGroup>
                <p className="form-control-static">{sponsor.id}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label md="2" sm="2">
              DisplayName
            </Label>
            <Col md="6" sm="6">
              <FormGroup>
                <p className="form-control-static">{data.displayName}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label md="2" sm="2">
              Phone
            </Label>
            <Col md="6" sm="6">
              <FormGroup>
                <p className="form-control-static">{data.phone}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label md="2" sm="2">
              Background Image
            </Label>
            <Col md="6" sm="6">
              <ImageUpload
                orign={this.state.sponsorBannerHttpURL}
                didUpdateFile={this.didUpdateFile}
              />
              <FileUploader
                hidden
                accept="image/*"
                name="banner"
                randomizeFilename
                storageRef={firebase
                  .storage()
                  .ref(`sponsor_banner_images/${sponsor.id}`)}
                ref={instance => {
                  this.bannerfileUploader = instance;
                }}
                onUploadError={sponsorService.handleUploadError}
                onUploadSuccess={sponsorService.handleUploadSuccess}
              />
            </Col>
          </Row>
          <Row>
            <Label md="2" sm="2">
              Sponsor Description
            </Label>
            <Col md="10" sm="10">
              <FormGroup>
                <Input
                  type="textarea"
                  name="longDescription"
                  value={this.state.sponsorLongDescription}
                  onChange={this.handleTextChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        {data.pendingInformation && (
          <Row>
            <Col md={{ size: 10, offset: 2 }}>
              <Card className="sponsor-pending-card">
                <CardHeader>
                  <CardTitle>
                    Pending Information{" "}
                    {data.pendingInformation.status &&
                      data.pendingInformation.status === "rejected" && (
                        <Badge color="danger" pill>
                          Rejected
                        </Badge>
                      )}
                  </CardTitle>
                  {data.pendingInformation.reason && (
                    <p>{data.pendingInformation.reason}</p>
                  )}
                </CardHeader>
                <CardBody>
                  <div>
                    {data.pendingInformation.sponsorBannerURL && (
                      <Row>
                        <Label md="2">Background Image</Label>
                        <Col md="10">
                          <Media
                            object
                            src={this.state.pendingSponsorBannerURL}
                          />
                        </Col>
                      </Row>
                    )}
                    {data.pendingInformation.longDescription && (
                      <Row>
                        <Label md="2">Page Description</Label>
                        <Col md="10">
                          <p>{data.pendingInformation.longDescription}</p>
                        </Col>
                      </Row>
                    )}
                    {data.pendingInformation.shortDescription && (
                      <Row>
                        <Label md="2">Short Description</Label>
                        <Col md="10">
                          <p>{data.pendingInformation.shortDescription}</p>
                        </Col>
                      </Row>
                    )}
                  </div>
                  {!data.pendingInformation.status && (
                    <div>
                      <Button onClick={this.handleClickReject}>Reject</Button>
                      <Button onClick={this.handleClickApprove}>Approve</Button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <div>
          <h2>Posts</h2>
          <PostGrid posts={this.state.posts} />
        </div>
        <CardFooter className="text-right">
          <div className="form-group">
            <Link to={`/main/sponsors`} className="pull-left">
              <button className="btn btn-info btn-fill">Back</button>
            </Link>
            <button
              type="Save"
              onClick={this.handleClickSave}
              className="btn btn-info btn-fill btn-wd"
              disabled={
                this.state.updateSponsor && this.state.updateSponsor.loading
              }
            >
              Save
              {this.state.updateSponsor && this.state.updateSponsor.loading && (
                <p>{this.state.updateSponsor.loading}</p>
              )}
            </button>
            {this.state.updateSponsor && this.state.updateSponsor.loading && (
              <img
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                alt="..."
              />
            )}
          </div>
        </CardFooter>
        {this.state.alert}
      </div>
    );
  }
}

SponsorDetail.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { sponsor } = state;
  return { sponsor };
};
export default connect(mapStateToProps)(SponsorDetail);
