import React, { Component } from "react";
//import { userActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import MailingTable from "./mailingTable.jsx";
import MailingCreate from "./mailingCreate.jsx";

import {
  Card,
  CardBody
  // CardHeader,
  // CardTitle,
  // Button,
  // FormGroup,
  // Row,
  // Col,
  // Input,
  // Label
} from "reactstrap";
class MailingView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleCreate() {}
  render() {
    return (
      <Card>
        <CardBody>
          <MailingCreate />
          <br />
          <Route exact path="/main/mailing" component={MailingTable} />
        </CardBody>
      </Card>
    );
  }
}

MailingView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return state;
};
export default connect(mapStateToProps)(MailingView);
