import firebase from "firebase";
import { authHeader } from "../helpers";
import queryString from "query-string";

export const postService = {
  _getPostByID(postId) {
    if (!postId) return Promise.reject("no post Id");
    const db = firebase.firestore();
    return db
      .collection("posts")
      .doc(postId)
      .get()
      .then(post => ({ ...post.data(), id: post.id }));
  },
  _getPosts(startPoint = new Date(), direction = "next") {
    const db = firebase.firestore();
    return db
      .collection("posts")
      .orderBy("createdAt", direction === "next" ? "desc" : "asc")
      .startAfter(startPoint)
      .limit(this.pageLimit)
      .get()
      .then(docs => {
        const postArr = [];
        docs.forEach(doc => postArr.push({ ...doc.data(), id: doc.id }));
        return direction === "next" ? postArr : postArr.reverse();
      });
  },
  _deletePost(postId) {
    // mocking success delete
    return Promise.resolve({ result: "ok" });
  },
  _hidePost(postId) {
    // mocking success hide
    return Promise.resolve({ result: "ok" });
  },
  _deleteReasonOption() {
    const db = firebase.database();
    return db
      .ref("/wordings/deleteReasons/options")
      .once("value")
      .then(datas => {
        const optionsArr = [];
        datas.forEach(data => {
          optionsArr.push({ value: data.key, label: data.val().zh_TW });
        });
        return optionsArr;
      });
  },
  _hideReasonOption() {
    const db = firebase.database();
    return db
      .ref("/wordings/hideReasons/options")
      .once("value")
      .then(datas => {
        const optionsArr = [];
        datas.forEach(data => {
          optionsArr.push({ value: data.key, label: data.val().zh_TW });
        });
        return optionsArr;
      });
  },
  pageLimit: 30,
  getPostByID(postId) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${host}/api/admin/v1/post/${postId}`, requestOptions).then(
      response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      }
    );
  },
  getPosts(startPoint = new Date().valueOf(), direction = "next") {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      status: "available",
      startPoint,
      order: direction === "next" ? "desc" : "asc",
      limit: this.pageLimit
    });
    return fetch(`${host}/api/admin/v1/posts?${querrys}`, requestOptions)
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      })
      .then(postsArr => {
        return direction === "next" ? postsArr : postsArr.reverse();
      });
  },
  deletePost(postId, reason) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "DELETE",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      reason
    });
    return fetch(
      `${host}/api/admin/v1/post/${postId}?${querrys}`,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  },
  hidePost(postId, reason) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "PUT",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({ reason, isVisible: false })
    };
    return fetch(`${host}/api/admin/v1/post/${postId}`, requestOptions).then(
      response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      }
    );
  },
  deleteReasonOption() {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      type: "delete"
    });
    return fetch(
      `${host}/api/admin/v1/posts/reasons?${querrys}`,
      requestOptions
    )
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      })
      .then(reasons => {
        return Object.keys(reasons).map(reasonKey => ({
          value: reasonKey,
          label: reasons[reasonKey].zh_TW
        }));
      });
  },
  hideReasonOption() {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      type: "hide"
    });
    return fetch(
      `${host}/api/admin/v1/posts/reasons?${querrys}`,
      requestOptions
    )
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      })
      .then(reasons => {
        return Object.keys(reasons).map(reasonKey => ({
          value: reasonKey,
          label: reasons[reasonKey].zh_TW
        }));
      });
  }
};
