import React, { Component } from "react";
// import { render } from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Route } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import DateTimeSelectComponent from "../../components/dateTimeSelectComponent";

import {
  // Card,
  // CardBody,
  // CardHeader,
  CardTitle,
  Button,
  FormGroup,
  Row,
  Col,
  Input,
  ButtonGroup,
  // Label
} from "reactstrap";
import { notificationActions } from "../../actions";
class NotificationCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      content: "",
      zh_TW: "",
      country: {
        value: "TW",
        label: "Taiwan",
      },
      url: "",
      entity_uuid: "",
      error: { show: false },
      sendAt: moment().add(1, "minute"),
    };
  }

  handleDateChange = (date) => {
    this.setState({ sendAt: date.seconds(0) });
  };

  handleCreate() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      console.log(" test Error =>  ", nextProps.error);
      swal("Oops", nextProps.error.error, "error");
      return;
    }

    if (nextProps.notifications || nextProps.jobs) {
      this.setState({
        name: "",
        content: "",
        zh_TW: "",
        country: {
          value: "TW",
          label: "Taiwan",
        },
        type: "link",
        url: "",
        entity_uuid: "",
        error: { show: false },
      });
    }

    if (this.sendingSwal && !nextProps.sending) {
      swal.close();
      this.sendingSwal = undefined;
    }
  }

  submitNoti = (isTesting, isScheduling) => {
    let swalTitle = isTesting
      ? "This is in Test Mode."
      : "Are you sure to send this notification?";
    if (isScheduling) swalTitle = "Are you sure to schedule this notification?";
    let swalSubtitle = isTesting
      ? "Please check your Taptot DEV app."
      : "This cannot be undo!\n請仔細檢查所有文案是否正確";
    if (isScheduling) swalSubtitle = "Please select a scheduling time.";

    this.setState({ error: { show: false } });

    if (this.state.name.length === 0) {
      this.setState({ error: { show: true, reason: "Name cannot be empty " } });
      return;
    }

    if (this.state.content.length === 0) {
      this.setState({
        error: { show: true, reason: "Content(English) cannot be empty " },
      });
      return;
    }

    if (this.state.zh_TW.length === 0) {
      this.setState({
        error: { show: true, reason: "Content(Chinese) cannot be empty " },
      });
      return;
    }

    if (this.isScheduling && this.sendAt < moment()) {
      this.setState({
        error: {
          show: true,
          reason: "Schedule time cannot be earlier than current time ",
        },
      });
      return;
    }

    if (this.state.type === "community") {
      if (!this.state.entity_uuid) {
        this.setState({
          error: {
            show: true,
            reason: "Must seat a community id",
          },
        });
        return;
      }
    }

    if (
      this.state.url &&
      this.state.url.length > 0 &&
      this.state.url.indexOf("http") < 0
    ) {
      let urlStringWithHttp = "https://" + this.state.url;
      this.setState({
        url: urlStringWithHttp,
      });
    }

    let wrapper = document.createElement("div");
    ReactDOM.render(
      <DateTimeSelectComponent
        value={this.state.sendAt}
        onChange={this.handleDateChange}
        isValidDate={this.handleStartValidDate}
      />,
      wrapper
    );
    let el = wrapper.firstChild;

    var self = this;
    this.sendingSwal = swal({
      title: swalTitle,
      text: swalSubtitle,
      content: isScheduling ? el : undefined,
      icon: "warning",
      buttons: [
        {
          text: "Cancel",
          value: null,
          visible: true,
          closeModal: true,
        },
        {
          text: isScheduling ? "Schedule" : "Send",
          value: true,
          visible: true,
          closeModal: false,
        },
      ],
      dangerMode: true,
      buttonsStyling: false,
    }).then(function(confirm) {
      if (confirm) {
        if (isScheduling && self.state.sendAt < moment()) {
          self.setState({
            error: {
              show: true,
              reason: "Schedule time cannot be earlier than current time ",
            },
          });
          swal.close();
          return;
        }

        const { dispatch } = self.props;
        var info = {
          name: self.state.name,
          content: self.state.content,
          zh_TW: self.state.zh_TW,
          country: self.state.country.value,
        };

        if (self.state.type === "link") {
          if (self.state.url && self.state.url.length > 0) {
            info.type = "link";
            info.url = self.state.url;
          }
        } else if (self.state.type === "community") {
          if (self.state.entity_uuid && self.state.entity_uuid.length > 0) {
            info.type = "community";
            info.entity_uuid = self.state.entity_uuid;
          }
        }

        if (isTesting) {
          dispatch(notificationActions.sendTest(info));
          swal.close();
        } else if (isScheduling) {
          if (self.state.sendAt) info.sendAt = self.state.sendAt;
          dispatch(notificationActions.schedule(info));
        } else dispatch(notificationActions.send(info));
      }
    });
  };

  handleSubmit = () => {
    this.submitNoti(false);
  };

  handleTest = () => {
    this.submitNoti(true);
  };

  handleSchedule = () => {
    this.submitNoti(false, true);
  };

  handleTextChange = (e) => {
    const { name, value } = e.target;
    var info = {};
    info[name] = value;
    this.setState(info);
  };

  handleCountryChange = (info) => {
    this.setState({
      country: info,
      banner: {
        ...this.state.banner,
        country: info.value,
      },
    });
  };

  handleCommunityIdChange = (e) => {
    const { name, value } = e.target;
    var info = {};
    info[name] = value;
    this.setState(info);
  };

  onTypeRadioBtnClick = (type) => {
    this.setState({ type });
  };

  render() {
    return (
      <>
        <Row>
          <Col sm="12" md="12">
            {this.state.error.show && (
              <div className="alert alert-warning" role="alert">
                {this.state.error.reason}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm="10" md="6">
            <FormGroup>
              <CardTitle>Name</CardTitle>
              <Input
                type="text"
                name="name"
                className="form-control"
                value={this.state.name}
                onChange={this.handleTextChange}
                placeholder="Name your notification"
              />
              <br />
              <CardTitle>Content</CardTitle>
              <Input
                type="text"
                name="content"
                className="form-control"
                value={this.state.content}
                onChange={this.handleTextChange}
                placeholder="English"
              />
              <br />
              <Input
                type="text"
                name="zh_TW"
                className="form-control"
                value={this.state.zh_TW}
                onChange={this.handleTextChange}
                placeholder="Chinese"
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <CardTitle>Country</CardTitle>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              id="SelectCountry"
              value={this.state.country}
              onChange={this.handleCountryChange}
              options={[
                {
                  value: "TW",
                  label: "Taiwan",
                },
                { value: "US", label: "United States" },
                { value: "ALL", label: "All" },
              ]}
            />
            {/* <br />
            <CardTitle>Web URL</CardTitle>
            <Input
              type="text"
              name="url"
              className="form-control"
              value={this.state.url}
              onChange={this.handleTextChange}
              placeholder="URL (Optional)"
            />
            <br />
            <Button onClick={this.handleTest}>Test</Button>
            <Button onClick={this.handleSubmit} color="warning">
              Submit
            </Button>
            <Button onClick={this.handleSchedule} color="primary">
              Schedule
            </Button> */}
            <br />
            <CardTitle>Type</CardTitle>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => this.onTypeRadioBtnClick("link")}
                active={this.state.type === "link"}
              >
                Web Link
              </Button>
              <Button
                color="primary"
                onClick={() => this.onTypeRadioBtnClick("community")}
                active={this.state.type === "community"}
              >
                Community
              </Button>
            </ButtonGroup>
            {this.state.type === "link" && (
              <>
                <CardTitle>Web URL</CardTitle>
                <Input
                  type="text"
                  name="url"
                  className="form-control"
                  value={this.state.url}
                  onChange={this.handleTextChange}
                  placeholder="URL (Optional)"
                />
              </>
            )}
            {this.state.type === "community" && (
              <>
                <CardTitle>Cummunity Id</CardTitle>
                <Input
                  type="text"
                  name="entity_uuid"
                  className="form-control"
                  value={this.state.entity_uuid}
                  onChange={this.handleCommunityIdChange}
                  placeholder="Community Id"
                />
              </>
            )}
          </Col>

          <Col md="12">
            <br />
            <Button onClick={this.handleTest}>Test</Button>
            <Button onClick={this.handleSubmit} color="warning">
              Submit
            </Button>
            <Button onClick={this.handleSchedule} color="primary">
              Schedule
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

NotificationCreate.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { notification } = state;
  return notification;
};
export default connect(mapStateToProps)(NotificationCreate);
