export const promoCodeConstants = {
  GET_ALL_REQUEST: "PROMOCODE_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "PROMOCODE_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "PROMOCODE_GET_ALL_FAILURE",

  GET_REQUEST: "PROMOCODE_GET_REQUEST",
  GET_SUCCESS: "PROMOCODE_GET_SUCCESS",
  GET_FAILURE: "PROMOCODE_GET_FAILURE",

  GET_USERS_REQUEST: "PROMOCODE_USERS_GET_REQUEST",
  GET_USERS_SUCCESS: "PROMOCODE_USERS_GET_SUCCESS",
  GET_USERS_FAILURE: "PROMOCODE_USERS_GET_FAILURE"
};
