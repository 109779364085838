import React, { Component } from "react";
import { Button } from "reactstrap";
import { zonesService } from "../../services";
import Utility from "../../helpers/utility";
import { Link } from "react-router-dom";

class ZonesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: []
    };
  }
  getZones = () => {
    zonesService.getZonesList().then(data => {
      console.log(data);
      this.setState({ zones: data });
    });
  };
  componentDidMount() {
    this.getZones();
  }
  render() {
    return (
      <div className="content table-responsive table-full-width">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>zone id</th>
              <th>Title</th>
              <th>Created At</th>
              <th>Country</th>
              <th>Start at</th>
              <th>End at </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.zones.map(el => (
              <tr key={el.id}>
                <td>{el.id}</td>
                <td>{el.name.zh_TW}</td>
                <td>{Utility.timestampToFormattedString(el.createdAt)}</td>
                <td>{el.country}</td>
                <td>{Utility.timestampToFormattedString(el.startAt)}</td>
                <td>{Utility.timestampToFormattedString(el.endAt)}</td>
                <td>
                  <Link to={`/main/zones/edit/${el.id}`}>
                    <Button>edit</Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ZonesTable;
