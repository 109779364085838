export const paymentConstants = {

    GET_REQUEST: 'PAYMENT_GET_REQUEST',
    GET_SUCCESS: 'PAYMENT_GET_SUCCESS',
    GET_FAILURE: 'PAYMENT_GET_FAILURE',

    REFUND_REQUEST: 'PAYMENT_REFUND_REQUEST',
    REFUND_SUCCESS: 'PAYMENT_REFUND_SUCCESS',
    REFUND_FAILURE: 'PAYMENT_REFUND_FAILURE'
  
};