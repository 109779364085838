import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import loadingTp from "../../../assets/img/loadingTp.png";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const PostImage = props => {
  const { path } = props;
  const [picUrl, setPicUrl] = useState(loadingTp);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const storageRef = firebase.storage().ref();
    storageRef
      .child(path)
      .getDownloadURL()
      .then(url => {
        setPicUrl(url);
      });
  }, [path]);
  return (
    <div
      style={{
        width: "200px",
        margin: "8px",
        cursor: "pointer"
      }}
      onClick={() => setShowModal(true)}
    >
      <div
        style={{
          width: "200px",
          height: "200px",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${picUrl})`
        }}
      />
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalBody>
          <img src={picUrl} alt="..." />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

PostImage.defaultProps = {
  path: ""
};
PostImage.propTypes = {
  path: PropTypes.string.isRequired
};
export default PostImage;
