import React from "react";
import { Route } from "react-router-dom";
import { PostDetail, PostsGallery } from "./index";
import { Card, CardBody } from "reactstrap";

const PostsView = props => {
  return (
    <Card>
      <CardBody>
        <Route exact path="/main/posts" component={PostsGallery} />
        <Route path="/main/posts/detail/:id" component={PostDetail} />
      </CardBody>
    </Card>
  );
};

export default PostsView;
