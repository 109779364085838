import { chatConstants } from "../constants";

const defaultState = {
  chatRelations: [],
  chatTemplates: [],
  chattingID: [], // if there is multiple means need to turn to different UI,
  multiChattingID: [],
  chatContents: [],
  isLoadingRelations: false,
  isLoadingTemplates: false,
  isLoadingContents: false
};

export function chat(state = defaultState, action) {
  switch (action.type) {
    case chatConstants.GET_CHAT_RELATION:
      return { ...state, isLoadingRelations: true };
    case chatConstants.GET_CHAT_RELATION_SUCCESS:
      return {
        ...state,
        chatRelations: [...state.chatRelations, ...action.relations],
        isLoadingRelations: false
      };
    case chatConstants.GET_CHAT_RELATION_FAIL:
      console.error(chatConstants.GET_CHAT_RELATION_FAIL, action.err);
      return { ...state, isLoadingRelations: false };

    case chatConstants.GET_CHAT_TEMPLATES:
      return { ...state, isLoadingTemplates: true };
    case chatConstants.GET_CHAT_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoadingTemplates: false,
        chatTemplates: action.templates
      };
    case chatConstants.GET_CHAT_TEMPLATES_FAIL:
      console.error(chatConstants.GET_CHAT_TEMPLATES_FAIL, action.err);
      return { ...state, isLoadingTemplates: false };

    case chatConstants.GET_CHAT_CONTENTS:
      return { ...state, isLoadingContents: true };
    case chatConstants.GET_CHAT_CONTENTS_SUCCESS:
      return {
        ...state,
        chatContents: [...action.contents, ...state.chatContents],
        isLoadingContents: false
      };
    case chatConstants.GET_CHAT_CONTENTS_FAIL:
      return { ...state, isLoadingContents: false };

    case chatConstants.TOOGLE_MULTI_CHATS_TARGET_ID:
      const hasMultiChattingTarget = state.multiChattingID.includes(
        action.chattingID
      );
      const newChattingIDArr = hasMultiChattingTarget
        ? [...state.multiChattingID].filter(
            chatID => chatID !== action.chattingID
          )
        : [...state.multiChattingID, action.chattingID];
      return {
        ...state,
        multiChattingID: newChattingIDArr
      };
    case chatConstants.SET_SINGLE_CHAT_TARGET_ID:
      return { ...state, chattingID: [action.chattingID], chatContents: [] };
    case chatConstants.CLEAR_ALL_MULTI_CHATS_TARGET_ID:
      return { ...state, multiChattingID: [] };

    case chatConstants.NEW_MESSAGE_COMMING:
      return {
        ...state,
        chatContents: [...state.chatContents, action.messageObj]
      };
    case chatConstants.A_RELATION_MOVE_TO_TOP:
      return {
        ...state,
        chatRelations: [
          action.relationObj,
          ...state.chatRelations.filter(
            relation => relation.id !== action.relationObj.id
          )
        ]
      };
    case chatConstants.DELETE_A_MESSAGE_SUCCESS:
      return {
        ...state,
        chatContents: state.chatContents.filter(
          chat => chat.id !== action.messageId
        )
      };
    case chatConstants.RESET_CHAT_INIT_STATE:
      return defaultState;

    case chatConstants.DELETE_A_MESSAGE:
    case chatConstants.DELETE_A_MESSAGE_FAIL:
    case chatConstants.ADD_CHAT_TEMPLATE:
    case chatConstants.ADD_CHAT_TEMPLATE_SUCCESS:
    case chatConstants.ADD_CHAT_TEMPLATE_FAIL:
    case chatConstants.UPDATE_CHAT_TEMPLATE:
    case chatConstants.UPDATE_CHAT_TEMPLATE_SUCCESS:
    case chatConstants.UPDATE_CHAT_TEMPLATE_FAIL:
    case chatConstants.DELETE_CHAT_TEMPLATE:
    case chatConstants.DELETE_CHAT_TEMPLATE_SUCCESS:
    case chatConstants.DELETE_CHAT_TEMPLATE_FAIL:
    case chatConstants.SENDING_MESSAGE_TO_A_CAHRT_TARGET:
    case chatConstants.SENDING_MESSAGE_TO_A_CAHRT_TARGET_SUCCESS:
    case chatConstants.SENDING_MESSAGE_TO_A_CAHRT_TARGET_FAIL:
    case chatConstants.BROADCASTING_A_MESSAGE:
    case chatConstants.BROADCASTING_A_MESSAGE_SUCCESS:
    case chatConstants.BROADCASTING_A_MESSAGE_FAIL:
    default:
      return state;
  }
}
