import React, { Component } from "react";
import { Route } from "react-router-dom";
import ZoneTable from "./zoneTable";
import CreateZonesView from "./createZonesView";
import { Card, CardBody } from "reactstrap";

class ZonesView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Card>
        <CardBody>
          <Route exact path="/main/zones" component={ZoneTable} />
          <Route path={`/main/zones/edit/:id`} component={CreateZonesView} />
        </CardBody>
      </Card>
    );
  }
}

export default ZonesView;
