import React, { Component } from "react";
import { sponsorActions } from "../../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class SponsorRequestTable extends Component {
  constructor(props) {
    super(props);
    this.state = { applications: [] };
    const { dispatch } = this.props;
    dispatch(sponsorActions.getAllApplications());
    this.handleCheckDetail = this.handleCheckDetail.bind(this);
  }

  handleCheckDetail(e) {
    e.preventDefault();
    //onClick={this.handleCheckDetail}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sponsor && nextProps.sponsor.applications) {
      var applications = [];
      nextProps.sponsor.applications.forEach(el => {
        el.data.createdAt = new Date(el.data.createdAt._seconds * 1000);
        applications.push(el);
      });

      this.setState({ applications });
    }
  }

  render() {
    return (
      <div className="content table-responsive table-full-width">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Title</th>
              <th>Type</th>
              <th>Stauts</th>
              <th>Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.applications.map(el => {
              console.log(el.data);
              return (
                <tr key={el.id}>
                  <td>{el.data.contactName}</td>
                  <td>{el.data.sponsorType}</td>
                  <td
                    className={el.data.status === "pending" ? "color-red" : ""}
                  >
                    {el.data.status}
                  </td>
                  <td>{el.data.createdAt.toLocaleString()}</td>
                  <td />
                  <td>
                    <Link
                      to={`/main/sponsorRequests/request/${el.data.numeric_id}`}
                    >
                      <button className="btn btn-info btn-fill">Detail</button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

SponsorRequestTable.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { sponsor } = state;
  return { sponsor };
};
export default connect(mapStateToProps)(SponsorRequestTable);
