import React, { Component } from "react";
import { sponsorActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";
import moment from "moment";
import SponsorSearch from "./sponsorSearch";

class SponsorTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: [],
      dropType: "sponsor",
      dropOpen: false,
      NPOs: []
    };
    const { dispatch } = this.props;
    dispatch(sponsorActions.getAllSponsors());
    dispatch(sponsorActions.getAllNPOs());
    this.handleCheckDetail = this.handleCheckDetail.bind(this);
  }

  handleCheckDetail(e) {
    e.preventDefault();
    //onClick={this.handleCheckDetail}
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sponsor && nextProps.sponsor.sponsors) {
      var sponsors = [];
      nextProps.sponsor.sponsors.forEach(el => {
        const timestamp = el.data.createdAt;
        const formatted = moment(timestamp).format("LLLL");
        el.data.formattedCreatedAt = formatted;
        sponsors.push(el);
      });
      this.setState({ sponsors });
    }
    if (nextProps.sponsor && nextProps.sponsor.NPOs) {
      var NPOs = [];
      nextProps.sponsor.NPOs.forEach(el => {
        const timestamp = el.data.createdAt;
        const formatted = moment(timestamp).format("LLLL");
        el.data.formattedCreatedAt = formatted;
        NPOs.push(el);
      });
      this.setState({ NPOs });
    }
  }

  handleToggleCreate = () => {
    this.props.handleToggleCreate();
  };

  toogleDropDown = () => {
    this.setState(preState => ({ ...preState, dropOpen: !preState.dropOpen }));
  };
  dropItemClick = dropType => {
    this.setState({ dropType });
  };

  render() {
    const { dropType } = this.state;

    var vendors = [];

    if (dropType === "sponsor") {
      vendors = this.state.sponsors;
    } else {
      vendors = this.state.NPOs;
    }
    return (
      <div>
        <Dropdown isOpen={this.state.dropOpen} toggle={this.toogleDropDown}>
          <DropdownToggle caret>{this.state.dropType}</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.dropItemClick("sponsor")}>
              Sponsor
            </DropdownItem>
            <DropdownItem onClick={() => this.dropItemClick("NPO")}>
              NPO
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {dropType === "sponsor" && (
          <>
            <SponsorSearch />
            <div className="text-right">
              <Button
                color="primary"
                className="nc-icon nc-simple-add"
                onClick={this.handleToggleCreate}
              />
            </div>
          </>
        )}
        <Table responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Unique</th>
              <th>Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {dropType === "sponsor"
              ? vendors.map(el => (
                  <tr key={el.id}>
                    <td>{el.data.displayName}</td>
                    <td>{el.data.email}</td>
                    <td>{el.data.uniqueID}</td>
                    <td>{el.data.formattedCreatedAt}</td>
                    <td>
                      <Link to={`/main/sponsors/detail/${el.id}`}>
                        <button className="btn btn-info btn-fill">
                          Detail
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))
              : vendors.map(el => (
                  <tr key={el.id}>
                    <td>{el.data.displayName}</td>
                    <td>{el.data.email}</td>
                    <td>{el.data.uniqueID}</td>
                    <td>{el.data.formattedCreatedAt}</td>
                    <td>
                      <Link to={`/main/sponsors/npodetail/${el.id}`}>
                        <button className="btn btn-info btn-fill">
                          Detail
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

SponsorTable.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { sponsor } = state;
  return { sponsor };
};
export default connect(mapStateToProps)(SponsorTable);
