import React, { Component, useState, useEffect } from "react";
import firebase from "firebase";
import moment from "moment";
import { Input, Button } from "reactstrap";

const GetInfoByFbID = props => {
  const { getFBUser } = props;
  const [fbId, setFbId] = useState("");
  const [fbUser, setFbUser] = useState(null);
  return (
    <div>
      <div>search fb voter</div>
      <Input onChange={e => setFbId(e.target.value)} value={fbId} />
      <Button onClick={() => getFBUser(fbId).then(user => setFbUser(user))}>
        search voter
      </Button>
      <Button
        onClick={() => {
          setFbId("");
          setFbUser(null);
        }}
      >
        clear
      </Button>
      {fbUser && <div>{`${fbUser.name} : ${fbUser.email}`}</div>}
    </div>
  );
};
const SerchBeautyCompetitor = props => {
  const { getCompetitor } = props;
  const [comId, setComId] = useState("");
  const [competitor, setCompetitor] = useState(null);
  const [voters, setVoters] = useState([]);
  useEffect(() => {
    console.log("competitor changeeeee!!!!!");
    setVoters(
      competitor
        ? Object.keys(competitor.voters).reduce((acc, fbVoter) => {
            return acc.concat(Array(competitor.voters[fbVoter]).fill(fbVoter));
          }, [])
        : []
    );
  }, [competitor]);
  return (
    <div>
      <div>search competitor</div>
      <Input onChange={e => setComId(e.target.value)} value={comId} />
      <Button
        onClick={() => getCompetitor(comId).then(data => setCompetitor(data))}
      >
        search competitor
      </Button>
      <Button
        onClick={() => {
          setComId("");
          setCompetitor(null);
        }}
      >
        clear
      </Button>
      <div>
        {voters.map((voter, indx) => (
          <div key={indx}>{voter}</div>
        ))}
      </div>
    </div>
  );
};
const MostVoterEmail = props => {
  const { getEmail } = props;
  const [emailNum, setEmailNum] = useState(0);
  return (
    <div>
      <Input onChange={e => setEmailNum(e.target.value)} />
      <Button onClick={() => getEmail(emailNum)}>search</Button>
    </div>
  );
};

class ZeroCarbonFashionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weeks: [
        {
          [moment("5 8", "MM DD").valueOf()]: [],
          [moment("5 9", "MM DD").valueOf()]: [],
          [moment("5 10", "MM DD").valueOf()]: [],
          [moment("5 11", "MM DD").valueOf()]: [],
          [moment("5 12", "MM DD").valueOf()]: [],
          [moment("5 13", "MM DD").valueOf()]: [],
          [moment("5 14", "MM DD").valueOf()]: []
        },
        {
          [moment("5 15", "MM DD").valueOf()]: [],
          [moment("5 16", "MM DD").valueOf()]: [],
          [moment("5 17", "MM DD").valueOf()]: [],
          [moment("5 18", "MM DD").valueOf()]: [],
          [moment("5 19", "MM DD").valueOf()]: [],
          [moment("5 20", "MM DD").valueOf()]: [],
          [moment("5 21", "MM DD").valueOf()]: []
        },
        {
          [moment("5 22", "MM DD").valueOf()]: [],
          [moment("5 22", "MM DD").valueOf()]: [],
          [moment("5 23", "MM DD").valueOf()]: [],
          [moment("5 24", "MM DD").valueOf()]: [],
          [moment("5 25", "MM DD").valueOf()]: [],
          [moment("5 26", "MM DD").valueOf()]: [],
          [moment("5 27", "MM DD").valueOf()]: []
        },
        {
          [moment("5 28", "MM DD").valueOf()]: [],
          [moment("5 29", "MM DD").valueOf()]: [],
          [moment("5 30", "MM DD").valueOf()]: [],
          [moment("5 31", "MM DD").valueOf()]: [],
          [moment("6 1", "MM DD").valueOf()]: [],
          [moment("6 2", "MM DD").valueOf()]: [],
          [moment("6 3", "MM DD").valueOf()]: [],
          [moment("6 4", "MM DD").valueOf()]: []
        }
      ],
      emails: [],
      totalVoting: 0,
      votingPoints: []
    };
  }
  getLuckdrawers = () => {
    const db = firebase.firestore();
    this.state.weeks.forEach((week, weekIndx) => {
      console.log(week);
      Object.keys(week).forEach(day => {
        console.log("-------------");
        console.log(day, new Date(parseInt(day, 10)));
        const today = parseInt(day, 10);
        const nextDay = moment(today)
          .add(1, "day")
          .valueOf();
        const nextSecDay = moment(today)
          .add(2, "day")
          .valueOf();
        // ------
        db.collection("fbVoters")
          .where(`voteTimeLine.${today}`, "==", true)
          .where(`voteTimeLine.${nextDay}`, "==", true)
          .where(`voteTimeLine.${nextSecDay}`, "==", true)
          .get()
          .then(docs => {
            const matchesVoter = [];
            docs.forEach(doc => {
              console.log("opopopopopopopopop");
              console.log(doc.data());
              matchesVoter.push(doc.data());
            });
            this.setState(preState => {
              preState.weeks[weekIndx][day] = matchesVoter;
              return { weeks: preState.weeks };
            });
          });
      });
    });
  };
  getFBUser = id => {
    const db = firebase.firestore();
    return db
      .collection("fbVoters")
      .doc(id)
      .get()
      .then(user => (user.exists ? user.data() : null));
  };
  getCompetitor = id => {
    const db = firebase.firestore();
    return db
      .collection("beautyCompetitor")
      .doc(id)
      .get()
      .then(competitor => (competitor.exists ? competitor.data() : null));
  };
  getEmail = num => {
    const db = firebase.firestore();
    const emailArr = [];
    return db
      .collection("fbVoters")
      .orderBy("accumulateVotes", "desc")
      .limit(isNaN(parseInt(num, 10)) ? 0 : parseInt(num, 10))
      .get()
      .then(docs => {
        console.log("opopopopopopopop");
        console.log(docs);
        docs.forEach(doc => {
          const voter = doc.data();
          if (voter.email)
            emailArr.push({
              name: voter.name,
              email: voter.email,
              id: voter.id
            });
        });
        this.setState({ emails: emailArr });
      });
  };
  getAllVoting = () => {
    const db = firebase.firestore();
    db.collection("beautyCompetitor").onSnapshot(competitors => {
      let acc = 0;
      const competitorArr = [];
      competitors.forEach(competitor => {
        const competitorData = competitor.data();
        competitorArr.push(competitorData);
        acc += competitorData.voting;
      });
      competitorArr.sort((a, b) => b.voting - a.voting);
      const base = competitorArr[0].voting;
      const votingPointsArr = competitorArr.map(competitor => {
        competitor.percentage =
          Math.floor((competitor.voting / base) * 70 * 100) / 100;
        return competitor;
      });
      this.setState({ totalVoting: acc, votingPoints: votingPointsArr });
    });
  };
  componentDidMount() {
    // this.getLuckdrawers();
    this.getAllVoting();
    console.log("jjjjjjjjjjjjjjjjjjj");
    // this.getEmail();
  }
  render() {
    return (
      <div>
        <h3>Total voing By FB Voter: {this.state.totalVoting}</h3>
        {this.state.votingPoints.map(competitor => (
          <div>{`${competitor.name} : ${competitor.percentage}`}</div>
        ))}
        <h3>top Voters email</h3>
        <MostVoterEmail getEmail={this.getEmail} />
        <h5>Total email: {this.state.emails.length}</h5>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>name</th>
              <th>email</th>
            </tr>
          </thead>
          <tbody>
            {this.state.emails.map(email => (
              <tr key={email.id}>
                <td>{email.name}</td>
                <td>{email.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <h3>single voter</h3>
        <GetInfoByFbID getFBUser={this.getFBUser} />
        <br />
        <h3>competitor voter search</h3>
        <SerchBeautyCompetitor getCompetitor={this.getCompetitor} />
        <br />
        <h3>weekly mission</h3>
        <Button onClick={() => this.getLuckdrawers()}>
          see weekly Missions qualifier
        </Button>
        {this.state.weeks.map((week, index) => {
          return (
            <div key={index}>
              <h4>week{index + 1}</h4>
              {Object.keys(week).map(day => {
                return week[day].map(user => <div>{user.id}</div>);
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default ZeroCarbonFashionView;
