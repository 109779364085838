import React, { Component } from "react";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { Input, Button, Card, CardBody } from "reactstrap";
import FileUploader from "react-firebase-file-uploader";
import ReactLoading from "react-loading";
import firebase from "firebase";
import swal from "sweetalert";
import moment from "moment";
import { zonesService } from "../../services";

const countryOption = [
  {
    value: "TW",
    label: "TW"
  },
  {
    value: "US",
    label: "US"
  }
];
class CreateZonesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: countryOption[0],
      startAt: moment()
        .add(15, "minute")
        .valueOf(), // might set as currenet moment
      endAt: moment()
        .add(1, "Month")
        .valueOf(), // might set a month from now
      isVisibleOnBrowse: true,
      isVisibleOnPost: true,
      name: {
        en: "",
        zh_TW: ""
      },
      imageURL: "",
      fileUploading: false
    };
  }
  handleCountryChange = country => {
    this.setState({ country });
  };
  handleDateChange = (date, key) => {
    console.log(date);
    this.setState(preState => {
      if (key === "endAt" && preState.startAt > date.valueOf()) {
        return {
          [key]: moment(preState.startAt)
            .add(1, "month")
            .valueOf()
        };
        // might need to add alert that end date need to be bigger
      }
      return {
        [key]: date.valueOf()
      };
    });
  };
  validateStartDate = currDate => {
    const yesterday = moment().subtract(1, "day");
    return currDate > yesterday;
  };
  validateEndDate = currDate => {
    const startDate = moment(this.state.startAt);
    return currDate > startDate;
  };
  handleNameChange = (value, lang) => {
    this.setState(preState => {
      preState.name[lang] = value;
      return { name: preState.name };
    });
  };
  handleIsVisibleChange = (value, key) => {
    console.log(value);
    console.log(key);
    this.setState({ [key]: value });
  };
  //   image handling
  handleUploadStart = () => {
    this.setState({ fileUploading: true });
    console.log("start!!!!!");
  };
  handleUploadError = () => {
    this.setState({ fileUploading: false });
    swal(
      "Oops!",
      "Upload image fail, might be auth expire please log out and try again",
      "error"
    );
    console.log("errror!!!!!");
  };
  handleUploadSuccess = fileName => {
    this.setState({
      imageURL: `zones_images/${fileName}`,
      fileUploading: false
    });
    console.log("successs?????", fileName);
  };
  //   create zone
  createZone = () => {
    console.log(this.state);
    if (this.state.fileUploading) {
      swal("Oops!", "image is still uploading", "error");
      return;
    }
    if (this.hasEmptyField()) {
      swal("Oops!", "there are fields are empty", "error");
      return;
    }
    const zoneObj = {
      ...this.state,
      country: this.state.country.value,
      endAt: this.state.endAt,
      startAt: this.state.startAt,
      weight: 0
    };
    delete zoneObj.fileUploading;
    zonesService
      .createNewZone(zoneObj)
      .then(data => {
        if (data.result === "ok") {
          swal("Good job!", "You successfully create a zone", "success");
        }
      })
      .catch(() => {
        swal("Oops!", "You fail to create a zone", "error");
      });
    // submit zoneObj
  };
  hasEmptyField = () => {
    let hasEmpty = false;
    Object.keys(this.state).forEach(key => {
      switch (key) {
        case "country":
        case "name":
          hasEmpty = Object.keys(this.state[key]).reduce((acc, cur) => {
            return this.state[key][cur] ? acc : true;
          }, hasEmpty);
          break;
        default:
          if (
            this.state[key] === "" ||
            this.state[key] === undefined ||
            this.state[key] === null
          )
            hasEmpty = true;
          break;
      }
    });
    console.log("hasss emprtyy??", hasEmpty);
    return hasEmpty;
  };
  // is edit or create
  updateZone = () => {
    if (this.state.fileUploading) {
      swal("Oops!", "image is still uploading", "error");
      return;
    }
    if (this.hasEmptyField()) {
      swal("Oops!", "there are fields are empty", "error");
      return;
    }
    const { match } = this.props;
    const zoneObj = {
      ...this.state,
      country: this.state.country.value,
      endAt: this.state.endAt,
      startAt: this.state.startAt
    };
    delete zoneObj.fileUploading;
    zonesService
      .updateZone(match.params.id, zoneObj)
      .then(data => {
        if (data.result === "ok") {
          swal("Good job!", "You successfully update a zone", "success");
        }
      })
      .catch(() => {
        swal("Oops!", "You fail to update a zone", "error");
      });
  };
  arePageEditOrCreate = () => {
    const { match } = this.props;
    // id edit
    if (match.path === "/main/zones/edit/:id") {
      // get single zone
      zonesService.getSingleZone(match.params.id).then(data => {
        console.log(data);
        this.setState({
          country: countryOption.find(ele => ele.value === data.country),
          startAt: data.startAt,
          endAt: data.endAt,
          isVisibleOnBrowse: data.isVisibleOnBrowse,
          isVisibleOnPost: data.isVisibleOnPost,
          name: data.name,
          imageURL: data.imageURL || ""
        });
      });
    }
  };
  componentDidMount() {
    this.arePageEditOrCreate();
  }
  render() {
    const { match } = this.props;
    const isEdit = match.path === "/main/zones/edit/:id";
    return (
      <Card>
        <CardBody>
          <div>
            Create Zones View
            <div>+++++++++++++++++</div>
            <div>name tw</div>
            <Input
              onChange={e => this.handleNameChange(e.target.value, "zh_TW")}
              value={this.state.name.zh_TW}
            />
            <div>name en</div>
            <Input
              onChange={e => this.handleNameChange(e.target.value, "en")}
              value={this.state.name.en}
            />
            <br />
            <div>country</div>
            <Select
              options={countryOption}
              onChange={this.handleCountryChange}
              value={this.state.country}
            />
            <br />
            <div>startAt</div>
            <ReactDatetime
              value={this.state.startAt}
              onChange={date => this.handleDateChange(date, "startAt")}
              isValidDate={this.validateStartDate}
            />
            <div>endAt</div>
            <ReactDatetime
              value={this.state.endAt}
              onChange={date => this.handleDateChange(date, "endAt")}
              isValidDate={this.validateEndDate}
            />
            <br />
            <div>
              isVisibleOnBrowse
              <Input
                type="checkbox"
                style={{ marginLeft: "10px" }}
                onChange={e =>
                  this.handleIsVisibleChange(
                    e.target.checked,
                    "isVisibleOnBrowse"
                  )
                }
                checked={this.state.isVisibleOnBrowse}
              />
            </div>
            <div>
              isVisibleOnPost
              <Input
                type="checkbox"
                style={{ marginLeft: "10px" }}
                onChange={e =>
                  this.handleIsVisibleChange(
                    e.target.checked,
                    "isVisibleOnPost"
                  )
                }
                checked={this.state.isVisibleOnPost}
              />
            </div>
            <br />
            <div>imageURL</div>
            <label>
              Select image
              <FileUploader
                accept="image/*"
                name="zones_images"
                randomizeFilename
                storageRef={firebase.storage().ref("zones_images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </label>
            {this.state.fileUploading ? (
              <ReactLoading
                type="spinningBubbles"
                color="red"
                height="30px"
                width="30px"
              />
            ) : (
              <Input disabled value={this.state.imageURL} />
            )}
            <div>weight as 0</div>
            {isEdit ? (
              <Button onClick={this.updateZone}>update zone</Button>
            ) : (
              <Button onClick={this.createZone}>create zone</Button>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default CreateZonesView;
