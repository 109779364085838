import { chatConstants } from "../constants";
import { chatService } from "../services";
// import { alertActions } from "./";
import swal from "sweetalert";

export const chatActions = {
  getChatRelations(endpoint = new Date().valueOf()) {
    const request = () => ({ type: chatConstants.GET_CHAT_RELATION });
    const success = relations => ({
      type: chatConstants.GET_CHAT_RELATION_SUCCESS,
      relations
    });
    const fail = err => ({ type: chatConstants.GET_CHAT_RELATION_FAIL, err });
    return dispatch => {
      dispatch(request());
      chatService
        .getChatRelations(endpoint)
        .then(data => {
          dispatch(success(data));
        })
        .catch(err => {
          dispatch(fail(err));
        });
    };
  },
  getChatTemplates() {
    const request = () => ({ type: chatConstants.GET_CHAT_TEMPLATES });
    const success = templates => ({
      type: chatConstants.GET_CHAT_TEMPLATES_SUCCESS,
      templates
    });
    const fail = err => ({ type: chatConstants.GET_CHAT_TEMPLATES_FAIL, err });
    return dispatch => {
      dispatch(request());
      chatService
        .getChatTemplates()
        .then(data => {
          dispatch(success(data));
        })
        .catch(err => {
          dispatch(fail(err));
        });
    };
  },
  creteChatTemplate(message = "") {
    const request = () => ({ type: chatConstants.ADD_CHAT_TEMPLATE });
    const success = () => {
      swal("Good job!", "success create template", "success");
      return {
        type: chatConstants.ADD_CHAT_TEMPLATE_SUCCESS
      };
    };
    const fail = err => {
      swal("Oops", "fail to create template", "error");
      return { type: chatConstants.ADD_CHAT_TEMPLATE_FAIL, err };
    };
    return dispatch => {
      dispatch(request());
      chatService
        .creteChatTemplate(message)
        .then(res => {
          if (res.result === "ok") {
            dispatch(success());
            dispatch(this.getChatTemplates());
          }
        })
        .catch(err => {
          dispatch(fail(err));
        });
    };
  },
  updateChatTemplate(templateId, message = "") {
    const request = () => ({
      type: chatConstants.UPDATE_CHAT_TEMPLATE_SUCCESS
    });
    const success = () => {
      swal("Good job!", "success modify template", "success");
      return { type: chatConstants.UPDATE_CHAT_TEMPLATE_SUCCESS };
    };
    const fail = err => {
      swal("Oops", "fail to modify template", "error");
      return {
        type: chatConstants.UPDATE_CHAT_TEMPLATE_FAIL,
        err
      };
    };
    return dispatch => {
      if (templateId) {
        dispatch(request());
        chatService
          .updateChatTemplate(templateId, message)
          .then(res => {
            if (res.result === "ok") {
              dispatch(success());
              dispatch(this.getChatTemplates());
            }
          })
          .catch(err => {
            dispatch(fail(err));
          });
      } else {
        dispatch(fail("no template ID"));
      }
    };
  },
  deleteChatTemplate(templateId) {
    const request = () => ({ type: chatConstants.DELETE_CHAT_TEMPLATE });
    const success = () => {
      swal("Good job!", "success delete template", "success");
      return {
        type: chatConstants.DELETE_CHAT_TEMPLATE_SUCCESS
      };
    };
    const fail = err => {
      swal("Oops", "fail to delete template", "error");
      return {
        type: chatConstants.DELETE_CHAT_TEMPLATE_FAIL,
        err
      };
    };
    return dispatch => {
      if (templateId) {
        dispatch(request());
        chatService
          .deleteChatTemplate(templateId)
          .then(res => {
            if (res.result === "ok") {
              dispatch(success());
              dispatch(this.getChatTemplates());
            }
          })
          .catch(err => {
            dispatch(fail(err));
          });
      } else {
        dispatch(fail("no template id"));
      }
    };
  },
  getChatContents(chatTargetId = "", endPoint = new Date().valueOf()) {
    const request = () => ({ type: chatConstants.GET_CHAT_CONTENTS });
    const success = contents => ({
      type: chatConstants.GET_CHAT_CONTENTS_SUCCESS,
      contents
    });
    const fail = err => ({ type: chatConstants.GET_CHAT_CONTENTS_FAIL, err });
    return dispatch => {
      dispatch(request());
      chatService
        .getChatsContent(chatTargetId, endPoint)
        .then(chats => {
          dispatch(success(chats));
        })
        .catch(err => {
          dispatch(fail(err));
        });
    };
  },
  toogleMultiChattingID(chatTargetId = "") {
    return dispatch => {
      dispatch({
        type: chatConstants.TOOGLE_MULTI_CHATS_TARGET_ID,
        chattingID: chatTargetId
      });
    };
  },
  setSingleChattingID(chatTargetId = "") {
    return (dispatch, getState) => {
      console.clear();
      console.log(getState());
      const reduxState = getState();
      const formerChatter = reduxState.chat.chattingID[0];
      if (formerChatter) {
        // leave chat room first
        chatService.leaveChatRoom(formerChatter);
      }
      dispatch({
        type: chatConstants.SET_SINGLE_CHAT_TARGET_ID,
        chattingID: chatTargetId
      });
      dispatch(this.getChatContents(chatTargetId));
    };
  },
  clearAllMultiChattingID() {
    return dispatch => {
      dispatch({ type: chatConstants.CLEAR_ALL_MULTI_CHATS_TARGET_ID });
    };
  },
  sendMessage2aChatTarget(chatTarget, message, type) {
    const request = () => ({
      type: chatConstants.SENDING_MESSAGE_TO_A_CAHRT_TARGET
    });
    const success = () => ({
      type: chatConstants.SENDING_MESSAGE_TO_A_CAHRT_TARGET_SUCCESS
    });
    const fail = err => {
      swal("Oops", "fail to send a message", "error");
      console.error(err);
      return { type: chatConstants.SENDING_MESSAGE_TO_A_CAHRT_TARGET_FAIL };
    };
    return dispatch => {
      dispatch(request());
      if (chatTarget) {
        chatService
          .submitMessage(chatTarget, message, type)
          .then(res => {
            if (res.result === "ok") {
              dispatch(success());
            }
          })
          .catch(err => {
            dispatch(fail(err));
          });
      } else {
        dispatch(fail("no chatting ID"));
      }
    };
  },
  broadcastingMessage2ChatTargets(chatTargets = [], message, type) {
    const request = () => ({
      type: chatConstants.BROADCASTING_A_MESSAGE
    });
    const success = () => {
      swal("Success", "success broadcast a message", "success");
      return {
        type: chatConstants.BROADCASTING_A_MESSAGE_SUCCESS
      };
    };
    const fail = err => {
      swal("Oops", "fail to send a message", "error");
      console.error(err);
      return { type: chatConstants.BROADCASTING_A_MESSAGE_FAIL };
    };
    return dispatch => {
      dispatch(request());
      if (chatTargets.length > 0) {
        chatService
          .broadcastMessage(chatTargets, message, type)
          .then(res => {
            if (res.result === "ok") {
              dispatch(success());
            }
          })
          .catch(err => {
            dispatch(fail(err));
          });
      } else {
        dispatch(fail("no chatting ID"));
      }
    };
  },
  newMessageComming(messageObj) {
    return (dispatch, getState) => {
      const reduxState = getState();
      const { chatContents } = reduxState.chat;
      if (chatContents.length > 0) {
        if (
          chatContents[chatContents.length - 1].createdAt < messageObj.createdAt
        ) {
          dispatch({
            type: chatConstants.NEW_MESSAGE_COMMING,
            messageObj: messageObj
          });
        } else {
          console.error("new message has already exist");
        }
      }
    };
  },
  relationMoving2Top(relationObj) {
    return dispatch => {
      dispatch({
        type: chatConstants.A_RELATION_MOVE_TO_TOP,
        relationObj
      });
    };
  },
  deleteAMessage(messageId) {
    const request = () => ({
      type: chatConstants.DELETE_A_MESSAGE
    });
    const success = () => {
      swal("Success", "success delete a message", "success");
      return {
        type: chatConstants.DELETE_A_MESSAGE_SUCCESS,
        messageId: messageId
      };
    };
    const fail = err => {
      swal("Oops", "fail to delete a message", "error");
      console.error(err);
      return { type: chatConstants.DELETE_A_MESSAGE_FAIL };
    };
    return (dispatch, getState) => {
      const reduxState = getState();
      dispatch(request);
      chatService
        .deleteMessage(reduxState.chat.chattingID[0], messageId)
        .then(res => {
          if (res.result === "ok") {
            dispatch(success());
          }
        })
        .catch(err => {
          dispatch(fail(err));
        });
    };
  },
  reset2InitState() {
    return dispatch => {
      dispatch({ type: chatConstants.RESET_CHAT_INIT_STATE });
    };
  }
};
