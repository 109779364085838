import React, { Component } from "react";
// import { render } from "react-dom";
// import { connect } from "react-redux";
// import { paymentActions } from "../actions";
// import { userActions } from "../actions";
// import { Link } from "react-router-dom";
// import { UserContent } from "../components";
import { Table, Input, FormGroup, Label } from "reactstrap";

//TRS1529746712560
export default class TransactionCancelOptions extends Component {
  constructor(props) {
    super(props);

    this.options = {
      isDeletingPost: false,
      isHidingPost: true,
      isReturningTP: true,
      isRefund: true,
      raters: ["taker", "giver"],
      reason: "mutualAgreement"
    };

    this.state = this.options;

    //mutualAgreement
    //giverNoResponse
    //takerNoResponse
    //ambiguousPostings
    //shippingException
    //furtherNotice
    //noReason
  }

  handleChange(e) {
    const { id, checked, value, name } = e.target;

    if (name === "reasonRadio") {
      //this.setState({reason:value});
      this.options.reason = value;
    } else if (name === "rate") {
      if (checked) {
        if (this.options.raters.indexOf(value) < 0) {
          this.options.raters.push(value);
        }
      } else {
        let index = this.options.raters.indexOf(value);
        if (index >= 0) {
          this.options.raters.splice(index, 1);
        }
      }
    } else {
      this.options[id] = checked;
    }

    this.setState(this.options);
    this.props.onOptionChange(this.options);
  }

  render() {
    return (
      <div className="text-left">
        <div className="form-group">
          <label htmlFor="reason">Reason:</label>
          <Table>
            <tbody>
              <tr>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="reasonRadio"
                      value="mutualAgreement"
                      checked={this.options.reason === "mutualAgreement"}
                      onChange={this.handleChange.bind(this)}
                    />
                    Mutual Agreement
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="reasonRadio"
                      value="giverNoResponse"
                      checked={this.options.reason === "giverNoResponse"}
                      onChange={this.handleChange.bind(this)}
                    />
                    Giver No Response
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="reasonRadio"
                      value="takerNoResponse"
                      checked={this.options.reason === "takerNoResponse"}
                      onChange={this.handleChange.bind(this)}
                    />
                    Taker No Response
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="reasonRadio"
                      value="takerNoTake"
                      checked={this.options.reason === "takerNoTake"}
                      onChange={this.handleChange.bind(this)}
                    />
                    Taker No Take
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="reasonRadio"
                      value="ambiguousPostings"
                      checked={this.options.reason === "ambiguousPostings"}
                      onChange={this.handleChange.bind(this)}
                    />
                    Ambiguous Posting
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="reasonRadio"
                      value="shippingException"
                      checked={this.options.reason === "shippingException"}
                      onChange={this.handleChange.bind(this)}
                    />
                    Shipping Exceptions
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="reasonRadio"
                      value="furtherNotice"
                      checked={this.options.reason === "furtherNotice"}
                      onChange={this.handleChange.bind(this)}
                    />
                    Further Notice
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="reasonRadio"
                      value="noReason"
                      checked={this.options.reason === "noReason"}
                      onChange={this.handleChange.bind(this)}
                    />
                    No Reason
                  </label>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <label htmlFor="action">Actions:</label>
        <FormGroup check>
          <Label check>
            <input
              className="form-check-input"
              type="checkbox"
              value="isDeletingPost"
              checked={this.options.isDeletingPost}
              id="isDeletingPost"
              onChange={this.handleChange.bind(this)}
            />
            <span className="form-check-sign" />
            Delete Post
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value="isHidingPost"
              checked={this.options.isHidingPost}
              id="isHidingPost"
              onChange={this.handleChange.bind(this)}
            />
            <span className="form-check-sign" />
            Hide Post
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value="isReturningTP"
              checked={this.options.isReturningTP}
              id="isReturningTP"
              onChange={this.handleChange.bind(this)}
            />
            <span className="form-check-sign" />
            Return Take Point
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value="isRefund"
              checked={this.options.isRefund}
              id="isRefund"
              onChange={this.handleChange.bind(this)}
            />
            <span className="form-check-sign" />
            Refund Shipping Fees
          </Label>
        </FormGroup>
        <label>Who can rate:</label>
        <div className="form-check form-check-inline">
          <FormGroup check inline>
            <Label check>
              <Input
                className="form-check-input"
                type="checkbox"
                value="taker"
                name="rate"
                id="isRateTaker"
                checked={!(this.options.raters.indexOf("taker") < 0)}
                onChange={this.handleChange.bind(this)}
              />
              <span className="form-check-sign" />
              Taker
            </Label>
          </FormGroup>
        </div>
        <div className="form-check form-check-inline">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                value="giver"
                name="rate"
                id="isRateGiver"
                checked={!(this.options.raters.indexOf("giver") < 0)}
                onChange={this.handleChange.bind(this)}
              />
              <span className="form-check-sign" />
              Giver
            </Label>
          </FormGroup>
        </div>
        <div className="form-check form-check-inline">
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                value="designator"
                name="rate"
                id="isRateDesignator"
                checked={!(this.options.raters.indexOf("designator") < 0)}
                onChange={this.handleChange.bind(this)}
              />{" "}
              <span className="form-check-sign" />
              Designator
            </Label>
          </FormGroup>
        </div>
      </div>
    );
  }
}
