import React from "react";
import { connect } from "react-redux";
import {
  // Card,
  // CardImg,
  // CardText,
  // CardBody,
  CardTitle,
  // CardSubtitle,
  Row,
  Col,
  // Container,
  // CardFooter,
  // CardHeader,
  Button,
  ButtonGroup
} from "reactstrap";
// import moment, { months } from "moment";
// import DatePicker from "react-datepicker";
import CountCard from "./cards/countCard";
import CategoryInfoCard from "./cards/categoryInfoCard";
import ReactDatetime from "react-datetime";
import { dashboardActions } from "../actions";

const CountryEnum = {
  ALL: 0,
  TW: 1,
  US: 2
};

class DashboardView extends React.Component {
  constructor(props) {
    super(props);
    var date = new Date();
    date.setDate(date.getDay() - 7);

    this.state = {
      startDate: date,
      endDate: new Date(),
      rSelected: "all",
      country: CountryEnum.ALL
    };
    this.generateRefs();
    this.handleSearch = this.handleSearch.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  generateRefs = () => {
    this.total = React.createRef();
    this.shipping = React.createRef();
    this.freeShipping = React.createRef();
    this.familyMart = React.createRef();
    this.palmBoxTakerPay = React.createRef();
    this.palmBoxGiverPay = React.createRef();
    this.meetup = React.createRef();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.dashboard.categoryInfos) {
      this.setState({ categoryInfos: nextProps.dashboard.categoryInfos });
    }
  }

  componentDidMount() {
    this.requestRefsData();
    const { dispatch } = this.props;
    dispatch(
      dashboardActions.getCategoryInfos(
        this.state.startDate,
        this.state.endDate,
        this.state.country
      )
    );
  }
  handleStartDateChange(date) {
    this.setState({ startDate: date.toDate() });
  }
  handleEndDateChange(date) {
    this.setState({ endDate: date.toDate() });
  }
  handleSearch() {
    this.requestRefsData();
  }

  requestRefsData = () => {
    this.total.requestData();
    this.shipping.requestData();
    this.freeShipping.requestData();
    this.familyMart.requestData();
    this.palmBoxTakerPay.requestData();
    this.palmBoxGiverPay.requestData();
    this.meetup.requestData();
  };

  onRadioBtnClick = rSelected => {
    if (this.state.rSelected !== rSelected) {
      this.setState({ rSelected });
    }
  };

  onCountryRadioBtnClick = country => {
    this.setState({ country });
  };

  showDetail = () => {
    this.familyMart.requestDetailData();
  };

  render() {
    return (
      <div>
        <div className="content">
          <Row>
            <Col md="3">
              <CardTitle>From</CardTitle>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Start Date"
                }}
                value={this.state.startDate}
                timeFormat={false}
                onChange={this.handleStartDateChange}
              />
            </Col>
            <Col md="3">
              <CardTitle>To</CardTitle>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "End Date"
                }}
                value={this.state.endDate}
                timeFormat={false}
                onChange={this.handleEndDateChange}
              />
            </Col>
            <Col md="6">
              <CardTitle>
                <ButtonGroup>
                  <Button
                    color="primary"
                    onClick={() => this.onCountryRadioBtnClick(CountryEnum.ALL)}
                    active={this.state.country === CountryEnum.ALL}
                  >
                    All
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.onCountryRadioBtnClick(CountryEnum.TW)}
                    active={this.state.country === CountryEnum.TW}
                  >
                    Taiwan
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.onCountryRadioBtnClick(CountryEnum.US)}
                    active={this.state.country === CountryEnum.US}
                  >
                    United States
                  </Button>
                </ButtonGroup>
                <hr />
                <ButtonGroup>
                  <Button
                    color="primary"
                    onClick={() => this.onRadioBtnClick("all")}
                    active={this.state.rSelected === "all"}
                  >
                    All
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.onRadioBtnClick("completed")}
                    active={this.state.rSelected === "completed"}
                  >
                    Completed
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.onRadioBtnClick("cancelled")}
                    active={this.state.rSelected === "cancelled"}
                  >
                    Cancelled
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.onRadioBtnClick("processing")}
                    active={this.state.rSelected === "processing"}
                  >
                    On Going
                  </Button>
                </ButtonGroup>
              </CardTitle>
              <Button outline color="secondary" onClick={this.handleSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <div className="content">
          <Row>
            <Col md="4">
              <CountCard
                title="Total Transaction"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                status={this.state.rSelected}
                country={this.state.country}
                ref={component => {
                  this.total = component
                    ? component.getWrappedInstance()
                    : this.total;
                }}
              />
            </Col>
            <Col md="4">
              <CountCard
                title="Shipping(Taker Pays)"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                type="standard_shipping"
                status={this.state.rSelected}
                country={this.state.country}
                ref={component => {
                  this.shipping = component
                    ? component.getWrappedInstance()
                    : this.shipping;
                }}
              />
            </Col>
            <Col md="4">
              <CountCard
                title="Shipping(Giver Pays)"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                type="offer_free_shipping"
                status={this.state.rSelected}
                country={this.state.country}
                ref={component => {
                  this.freeShipping = component
                    ? component.getWrappedInstance()
                    : this.freeShipping;
                }}
              />
            </Col>
          </Row>
          {(this.state.country | CountryEnum.TW) === CountryEnum.TW ? (
            <Row>
              <Col md="4">
                <CountCard
                  title="Family Mart"
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  type="familyMart"
                  status={this.state.rSelected}
                  country={this.state.country}
                  ref={component => {
                    this.familyMart = component
                      ? component.getWrappedInstance()
                      : this.familyMart;
                  }}
                  showDetail={() => {
                    return this.state.rSelected === "cancelled"
                      ? this.showDetail
                      : null;
                  }}
                />
              </Col>
              <Col md="4">
                <CountCard
                  title="PalmBox(Taker Pays)"
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  type="taker_pays_palmbox"
                  status={this.state.rSelected}
                  country={this.state.country}
                  ref={component => {
                    this.palmBoxTakerPay = component
                      ? component.getWrappedInstance()
                      : this.palmBoxTakerPay;
                  }}
                />
              </Col>
              <Col md="4">
                <CountCard
                  title="PalmBox(Giver Pays)"
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  type="giver_pays_palmbox"
                  status={this.state.rSelected}
                  country={this.state.country}
                  ref={component => {
                    this.palmBoxGiverPay = component
                      ? component.getWrappedInstance()
                      : this.palmBoxGiverPay;
                  }}
                />
              </Col>
            </Row>
          ) : (
            <Row />
          )}
          <Row>
            <Col md="4">
              <CountCard
                title="Meetup"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                type="meetup"
                status={this.state.rSelected}
                country={this.state.country}
                ref={component => {
                  this.meetup = component
                    ? component.getWrappedInstance()
                    : this.total;
                }}
              />
            </Col>
          </Row>
          {this.state.categoryInfos && (
            <Row>
              <Col>
                <CardTitle>Categories Info</CardTitle>
              </Col>
            </Row>
          )}

          <Row>
            {this.state.categoryInfos &&
              this.state.categoryInfos.map(el => (
                <Col md="4" key={el.category}>
                  <CategoryInfoCard
                    title={el.category}
                    total={el.total}
                    taken={el.taken}
                    taker={el.taker}
                    giver={el.giver}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </div>
    );
  }
}
//taker_pays_palmbox
//familyMart
//offer_free_shipping
/*
pending_giver
pending_taker
processing
completed
canceled
 */
function mapStateToProps(state) {
  const { dashboard } = state;
  return { dashboard };
}

export default connect(mapStateToProps)(DashboardView);
