import React from "react";
import { connect } from "react-redux";
import {
  Card,
  // CardImg,
  // CardText,
  CardBody,
  CardTitle,
  // CardSubtitle,
  Button,
  Row,
  Col,
  // Container,
  // CardFooter,
  CardHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  Label
} from "reactstrap";
// import moment, { months } from "moment";
// import DatePicker from "react-datepicker";
import { dashboardActions } from "../../actions";
import { PulseLoader } from "react-spinners";
// import ReactLoading from "react-loading";
import { css } from "react-emotion";

class CountCard extends React.Component {
  constructor(props) {
    super(props);
    this.state =
      props.dashboard.counts && props.dashboard.counts[props.type]
        ? {
            ...props.dashboard.counts[props.type],
            popoverOpen: false,
            toolTipID: "Popover1"
          }
        : {
            count: 0,
            loading: true,
            popoverOpen: false,
            toolTipID: "Popover1"
          };
    this.override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
      text-align: center;
    `;
  }

  requestData = () => {
    if (this.state.popoverOpen === true) this.setState({ popoverOpen: false });
    if (this.state.detail) this.setState({ detail: undefined });

    const { startDate, endDate, type, dispatch, status, country } = this.props;
    dispatch(
      dashboardActions.getTransactionCount(
        startDate,
        endDate,
        type,
        status,
        country
      )
    );
  };

  requestDetailData = () => {
    if (this.state.detail) return;
    const { startDate, endDate, type, dispatch, status, country } = this.props;
    dispatch(
      dashboardActions.getDetailedTransactionCount(
        startDate,
        endDate,
        type,
        status,
        country
      )
    );
  };

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
    if (!this.state.popoverOpen) this.requestDetailData();
  };

  componentWillReceiveProps(nextProps) {
    let dashboard = nextProps.dashboard;
    if (dashboard && dashboard.counts) {
      var countInfo = this.props.type
        ? dashboard.counts[this.props.type]
        : dashboard.counts["total"];
      if (countInfo) {
        if (this.props.status === nextProps.status && nextProps.showDetail) {
          this.showDetail = nextProps.showDetail();
        }
        this.setState({
          count: countInfo.count ? countInfo.count : 0,
          loading: countInfo.loading
        });
      }
    }

    if (dashboard && dashboard.details) {
      var detailInfo = this.props.type
        ? dashboard.details[this.props.type]
        : dashboard.details["total"];
      if (detailInfo) {
        this.setState({ detail: detailInfo });
      }
    }
  }

  handleShowDetail = () => {
    this.showDetail();
  };

  componentDidUpdate() {}

  componentDidMount() {}

  render() {
    const { loading, detail } = this.state;
    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-center">
            {this.props.title}{" "}
            {this.showDetail != null && (
              <span>
                <Button
                  id={this.state.toolTipID}
                  className="btn-round btn-icon"
                  onClick={this.toggle}
                >
                  <i className="ti-help" />
                </Button>
                <Popover
                  placement="right"
                  isOpen={this.state.popoverOpen}
                  toggle={this.toggle}
                  target={this.state.toolTipID}
                >
                  <PopoverHeader>Detail</PopoverHeader>
                  <PopoverBody>
                    {detail && (
                      <div>
                        {detail.loading ? (
                          <PulseLoader
                            className={this.override}
                            sizeUnit={"px"}
                            margin="2px"
                            size={10}
                            color={"#eee"}
                            loading={detail.loading}
                          />
                        ) : (
                          <div className="text-center">
                            <Row>
                              <Col md="12">
                                {detail.giverExpire !== undefined && (
                                  <div>
                                    <Label>Giver Expires</Label>
                                    <p>{detail.giverExpire}</p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                {detail.giverFamilyMartNoDropOff !==
                                  undefined && (
                                  <div>
                                    <Label>Giver No Drop Off</Label>
                                    <p>{detail.giverFamilyMartNoDropOff}</p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                {detail.mutualAgreement !== undefined && (
                                  <div>
                                    <Label>Mutual Agreement</Label>
                                    <p>{detail.mutualAgreement}</p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                {detail.takerNoTake !== undefined && (
                                  <div>
                                    <Label>Taker No Take</Label>
                                    <p>{detail.takerNoTake}</p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                {detail.takerCancel !== undefined && (
                                  <div>
                                    <Label>Taker Cancel</Label>
                                    <p>{detail.takerCancel}</p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                {detail.takerCancelDesignate !== undefined && (
                                  <div>
                                    <Label>Taker Cancel Designate</Label>
                                    <p>{detail.takerCancelDesignate}</p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    )}
                  </PopoverBody>
                </Popover>
              </span>
            )}
          </CardTitle>
        </CardHeader>
        <CardBody>
          {loading ? (
            <PulseLoader
              className={this.override}
              sizeUnit={"px"}
              margin="2px"
              size={10}
              color={"#eee"}
              loading={loading}
            />
          ) : (
            <p className="text-center h5">{this.state.count}</p>
          )}
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const { dashboard } = state;
  return { dashboard };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { withRef: true }
)(CountCard);
