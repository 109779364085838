import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import { transactionService } from "../services";

class TransactionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id
    };
  }

  componentDidMount() {
    if (this.props.imageURL) {
      var storage = firebase.storage();
      storage
        .ref(this.props.imageURL)
        .getDownloadURL()
        .then(httpURL => {
          this.setState({ httpURL: httpURL });
        });
    }

    if (this.state.id) {
      transactionService.get(this.state.id).then(transaction => {
        this.setState({ transaction });
      });
    }
  }

  render() {
    return (
      <span>
        <div>
          <label>Transaction Number:</label>{" "}
          {this.state.transaction && this.state.transaction.data && (
            <>{this.state.transaction.data.numeric_id}</>
          )}
        </div>
        <div>
          <label>Post Item:</label>{" "}
          {this.state.transaction && this.state.transaction.data && (
            <>{this.state.transaction.data.item_title}</>
          )}
        </div>
      </span>
    );
  }
}

// height={this.state.height}

TransactionInfo.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(TransactionInfo);
