// import { authHeader, setToken } from "../helpers";
import { authHeader } from "../helpers";
import firebase from "firebase/app";
import "firebase/auth";

export const userService = {
  search,
  login,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  getTPLogs,
  getSuspendedActivities,
  getWarnedActivities,
  resumeWarnedUser,
  resumeSuspendedUser
};

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

function login(username, password) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(username, password)
    .then(
      response => {
        return firebase.auth().currentUser.getIdToken();
      },
      error => {}
    )
    .then(idToken => {
      const payload = JSON.parse(b64DecodeUnicode(idToken.split(".")[1]));
      if (!payload["adminAccount"]) {
        firebase
          .auth()
          .signOut()
          .then(function() {
            // Sign-out successful.
          })
          .catch(function(error) {
            // An error happened.
          });
        return Promise.reject("not Admin");
      } else {
        localStorage.setItem(
          "role",
          JSON.stringify({
            role: payload.role,
            level: payload.accessLevel,
            country: payload.country
          })
        );
        return Promise.resolve(idToken);
      }
    })
    .then(
      res => {
        localStorage.setItem("user", res);
        return Promise.resolve(firebase.auth().currentUser);
      },
      error => {}
    );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("role");
  firebase
    .auth()
    .signOut()
    .then(function() {
      // Sign-out successful.
    })
    .catch(function(error) {
      // An error happened.
    });
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch("/users", requestOptions).then(handleResponse);
}

function getById(id) {
  var host = window.location.protocol + "//" + window.location.host;

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id })
  };

  var queryString = "/api/admin/v1/user/" + id;

  return fetch(host + queryString, requestOptions)
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
        //return {id:id, data:{displayName:'Ryan Chen', imageURL:'http://creativeedtech.weebly.com/uploads/4/1/6/3/41634549/published/avatar.png?1487742111'}};
      }
      return response.json();
    })
    .then(data => {
      var newData = { id: id, data: data };
      return Promise.resolve(newData);
    });
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch("/users/register", requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };

  return fetch("/users/" + user.id, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };

  return fetch("/users/" + id, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  if (!response.ok) {
    return Promise.reject(response.status);
  }

  return response.json();
}

function search(keyword) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ keyword })
  };
  return fetch(host + "/api/admin/v1/user/search", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }

      return response.json();
    }
  );
}

function getTPLogs(id) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(host + `/api/admin/v1/user/${id}/tpLogs`, requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function getSuspendedActivities() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  return fetch(
    host + `/api/admin/v1/user/suspendedActivities`,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function getWarnedActivities() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };
  console.log(" calling api in service ");
  return fetch(
    host + `/api/admin/v1/user/warnedActivities`,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function resumeWarnedUser(activityId, userId) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ activityId })
  };
  return fetch(
    host + `/api/admin/v1/user/${userId}/resumeWarning`,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function resumeSuspendedUser(activityId, userId) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ activityId })
  };
  return fetch(
    host + `/api/admin/v1/user/${userId}/resumeWarning`,
    requestOptions
  ).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}
