import { transactionConstants } from "../constants";

function reviseTransactionWordings(transaction) {
  if (transaction.data.method === "standard_shipping") {
    // make copy of user without 'deleting:true' property
    transaction.data.method = "Standard Shipping";
  } else if (transaction.data.method === "offer_free_shipping") {
    transaction.data.method = "Free Shipping";
  } else if (transaction.data.method === "meetup") {
    transaction.data.method = "Meet Up";
  }

  if (transaction.data.status === "pending_giver") {
    // make copy of user without 'deleting:true' property
    transaction.data.status = "Pending for giver's comfirmation";
  } else if (transaction.data.status === "pending_taker") {
    transaction.data.status = "Pending for taker's comfirmation";
  } else if (transaction.data.status === "processing") {
    transaction.data.status = "Processing";
  } else if (transaction.data.status === "completed") {
    transaction.data.status = "Completed";
  } else if (transaction.data.status === "cancelled") {
    transaction.data.status = "Cancelled";
  }

  return transaction;
}

export function transactions(state = {}, action) {
  switch (action.type) {
    case transactionConstants.GET_REQUEST:
      return {
        loading: true
      };
    case transactionConstants.GET_SUCCESS:
      var newTransaction = reviseTransactionWordings(action.transaction);
      return {
        ...state,
        transaction: newTransaction
      };
    case transactionConstants.GET_FAILURE:
      return {
        error: action.error
      };
    case transactionConstants.CANCEL_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state
      };
    case transactionConstants.CANCEL_SUCCESS:
      // remove deleted user from state
      var newTransaction2 = reviseTransactionWordings(action.transaction);
      return {
        ...state,
        transaction: newTransaction2
      };
    case transactionConstants.CANCEL_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state
      };
    case transactionConstants.COMPLETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state
      };
    case transactionConstants.COMPLETE_SUCCESS:
      // remove deleted user from state
      var newTransaction3 = reviseTransactionWordings(action.transaction);
      return {
        ...state,
        transaction: newTransaction3
      };
    case transactionConstants.COMPLETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state
      };
    case transactionConstants.SEARCH_REQUEST:
      // remove deleted user from state
      return {
        loading: true,
        transactions: []
      };
    case transactionConstants.SEARCH_SUCCESS:
      var isEmpty = false;
      if (action.transactions.length === 0) isEmpty = true;

      return {
        loading: false,
        isEmpty: isEmpty,

        transactions: action.transactions.map(transaction => {
          if (transaction.data.method === "standard_shipping") {
            // make copy of user without 'deleting:true' property
            transaction.data.method = "Standard Shipping";
          } else if (transaction.data.method === "offer_free_shipping") {
            transaction.data.method = "Free Shipping";
          } else if (transaction.data.method === "meetup") {
            transaction.data.method = "Meet Up";
          }

          if (transaction.data.status === "pending_giver") {
            // make copy of user without 'deleting:true' property
            transaction.data.status = "Pending for giver's comfirmation";
          } else if (transaction.data.status === "pending_taker") {
            transaction.data.status = "Pending for taker's comfirmation";
          } else if (transaction.data.status === "processing") {
            transaction.data.status = "Processing";
          } else if (transaction.data.status === "completed") {
            transaction.data.status = "Completed";
          } else if (transaction.data.status === "cancelled") {
            transaction.data.status = "Cancelled";
          }

          return transaction;
        })
      };

    case transactionConstants.UPDATE_ADDRESSINFO_REQUEST:
      return {
        ...state,
        updateAddressInfo: { loading: true }
      };
    case transactionConstants.UPDATE_ADDRESSINFO_SUCCESS:
      return {
        ...state,
        updateAddressInfo: { result: action.result }
      };
    case transactionConstants.UPDATE_ADDRESSINFO_FAILURE:
      return {
        ...state,
        updateAddressInfo: {
          loading: true,
          error: "Updating address info failed."
        }
      };

    default:
      //var trans = [{"id":"0F2ehO4SK8lUmVL49ULC","data":{"charge":100,"country":"TW","item":"buRUgOnVPCfoLbKFZw8f","pickup_info":{"state":"高雄市","alias":null,"contactPhone":"0976484848","street":"苦苦","fullName":"黑色素庫","fullAddress":"800 高雄市 新興區 苦苦","detail":null,"postCode":"800","locality":"新興區","address":"800 高雄市 新興區 苦苦"},"method":"Standard Shipping","shipping_info":{"fullAddress":"112 台北市 北投區 sgdhdhhd","postCode":"112","locality":"北投區","address":"112 台北市 北投區 sgdhdhhd","state":"台北市","alias":"my home","contactPhone":"0976184845","street":"sgdhdhhd","fullName":"svddh"},"item_size":"s1","users":{"JdsGNcaPDzOwo3onZD27Cgu8oeM2":1514732408280,"FL5aaKttkWXJvjzim4zOpNosCZp2":1514732408280},"designator":"JdsGNcaPDzOwo3onZD27Cgu8oeM2","active":{},"status":"Cancelled","cancelled":{"JdsGNcaPDzOwo3onZD27Cgu8oeM2":1514818860484,"FL5aaKttkWXJvjzim4zOpNosCZp2":1514818860484},"payer":"FL5aaKttkWXJvjzim4zOpNosCZp2","taker":"FL5aaKttkWXJvjzim4zOpNosCZp2","read":{"JdsGNcaPDzOwo3onZD27Cgu8oeM2":true,"FL5aaKttkWXJvjzim4zOpNosCZp2":true},"currency":"NTD","createdAt":1514732408280,"giver":"JdsGNcaPDzOwo3onZD27Cgu8oeM2","numeric_id":"TRS1514732408280","imageURL":"post_images/buRUgOnVPCfoLbKFZw8f/20171231_224313291486755-s.jpg","updatedAt":1514818860484,"item_title":"xhxh"}}];
      //return { transactions : [{title:'shit', status : 'ok'}, {title:'eat', status : 'cancelled'},{title:'holly', status : 'cancelled'}]};
      //return { transactions : [{"id":"00tzYSsnhJEp48U04ImK","data":{"shipping_info":{"state":"台北市","alias":"Office","contactPhone":"0986219685","street":"合江街102巷2號2樓","fullName":"Michael","fullAddress":"104 台北市 中山區 合江街102巷2號2樓","postCode":"91790","locality":"中山區","stateAbbrev":"CA","address":"104 台北市 中山區 合江街102巷2號2樓"},"serviceCode":"usps_priority_mail","users":{"eORoOE5kPLeZFcgy3qJSehIIKeu2":1515059058600,"KQSWJ9aKegTP9xdvgTJ85Ze6Q2D3":1515059058600,"JdsGNcaPDzOwo3onZD27Cgu8oeM2":1515059058600},"item_weight":{"value":1,"unit":"LB"},"active":{},"status":"pending_giver","payer":"eORoOE5kPLeZFcgy3qJSehIIKeu2","taker":"eORoOE5kPLeZFcgy3qJSehIIKeu2","read":{"JdsGNcaPDzOwo3onZD27Cgu8oeM2":true,"eORoOE5kPLeZFcgy3qJSehIIKeu2":true,"KQSWJ9aKegTP9xdvgTJ85Ze6Q2D3":true},"createdAt":1515059058600,"giver":"KQSWJ9aKegTP9xdvgTJ85Ze6Q2D3","numeric_id":"TRS1515059058600","carrier":"USPS","packageType":"flat_rate_padded_envelope","imageURL":"post_images/RZt8dF4SfVHWsWJOkd5l/20180103_1755331614174794-s.jpg","updatedAt":1515145500773,"item_title":"設KK可靠全球","country":"US","item":"RZt8dF4SfVHWsWJOkd5l","pickup_info":{"stateAbbrev":"CA","address":"100 台北市中正區樹皮膚","state":"台北市","alias":null,"contactPhone":"5949458845","street":"樹皮膚","fullName":"機百人","fullAddress":"100 台北市中正區樹皮膚","detail":null,"postCode":"91601","locality":"中正區"},"method":"standard_shipping"}}]};
      return { transactions: [] };
  }
}
