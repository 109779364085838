import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import SponsorTable from "./sponsorTable.jsx";
import SponsorDetail from "./sponsorDetail";
import SponsorCreate from "./sponsorCreate";
import {
  Card,
  CardBody,
  CardTitle,
  // Button,
  // FormGroup,
  // Row,
  // Col,
  // Input,
  Modal
} from "reactstrap";

class SponsorsView extends Component {
  constructor(props) {
    super(props);
    this.state = { isCreatingSponsor: false };
  }

  handleCreate() {}

  handleToggleCreate = () => {
    this.setState({ isCreatingSponsor: !this.state.isCreatingSponsor });
  };

  /** 
   * <Route
              exact
              path="/main/sponsors"
              component={() => {
                return (
                  <SponsorTable handleToggleCreate={this.handleToggleCreate} />
                );
              }}
            />
  */

  sponsorTable = () => {
    return <SponsorTable handleToggleCreate={this.handleToggleCreate} />;
  };

  render() {
    return (
      <div className="content">
        <Card>
          <CardBody>
            <CardTitle>Sponsors</CardTitle>
            <Route exact path="/main/sponsors" render={this.sponsorTable} />
            <Route path="/main/sponsors/detail/:id" component={SponsorDetail} />

            <Route
              path="/main/sponsors/npodetail/:id"
              render={props => <SponsorDetail {...props} isNPO={true} />}
            />
          </CardBody>
        </Card>
        <Modal
          isOpen={this.state.isCreatingSponsor}
          toggle={this.handleToggleCreate}
        >
          <SponsorCreate handleToggleCreate={this.handleToggleCreate} />
        </Modal>
      </div>
    );
  }
}

SponsorsView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  //const { lo } = state.banners;
  return state;
};
export default connect(mapStateToProps)(SponsorsView);
