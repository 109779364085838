import { dashboardConstants } from "../constants";
import { dashboardService } from "../services";
import { alertActions, userActions } from "./";

export const dashboardActions = {
  getTransactionCount,
  getDetailedTransactionCount,
  getCategoryInfos
};

function getTransactionCount(startDate, endDate, type, status, country) {
  return dispatch => {
    var method = type ? type : "total";
    dispatch(request(method));
    dashboardService
      .getTransactoinCount(startDate, endDate, type, status, country)
      .then(
        result => {
          dispatch(success(result, method));
        },
        error => {
          dispatch(failure(error, method));
          dispatch(alertActions.error(error));
          if (error === 401) {
            dispatch(userActions.logout());
          }
        }
      );
  };

  function request(method) {
    return { type: dashboardConstants.GET_TRANSACTION_COUNT_REQUEST, method };
  }
  function success(result, method) {
    return {
      type: dashboardConstants.GET_TRANSACTION_COUNT_SUCCESS,
      result,
      method
    };
  }
  function failure(error) {
    return { type: dashboardConstants.GET_TRANSACTION_COUNT_FAILURE, error };
  }
}

function getDetailedTransactionCount(
  startDate,
  endDate,
  type,
  status,
  country
) {
  return dispatch => {
    var method = type ? type : "total";
    dispatch(request(method));
    dashboardService
      .getDetailTransactionCount(startDate, endDate, type, status, country)
      .then(
        result => {
          dispatch(success(result, method));
        },
        error => {
          dispatch(failure(error, method));
          dispatch(alertActions.error(error));
          if (error === 401) {
            dispatch(userActions.logout());
          }
        }
      );
  };

  function request(method) {
    return {
      type: dashboardConstants.GET_DETAILED_TRANSACTION_COUNT_REQUEST,
      method
    };
  }
  function success(result, method) {
    return {
      type: dashboardConstants.GET_DETAILED_TRANSACTION_COUNT_SUCCESS,
      result,
      method
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.GET_DETAILED_TRANSACTION_COUNT_FAILURE,
      error
    };
  }
}

function getCategoryInfos(startDate, endDate, country) {
  return dispatch => {
    dispatch(request());
    dashboardService.getCategoryInfos(startDate, endDate, country).then(
      result => {
        dispatch(success(result));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        if (error === 401) {
          dispatch(userActions.logout());
        }
      }
    );
  };

  function request() {
    return { type: dashboardConstants.GET_CATEGORY_INFOS_REQUEST };
  }
  function success(result) {
    return {
      type: dashboardConstants.GET_CATEGORY_INFOS_SUCCESS,
      result
    };
  }
  function failure(error) {
    return { type: dashboardConstants.GET_CATEGORY_INFOS_FAILURE, error };
  }
}
