import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import { userService } from "../services";

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      title: props.title
    };
  }

  componentDidMount() {
    if (this.props.imageURL) {
      var storage = firebase.storage();
      storage
        .ref(this.props.imageURL)
        .getDownloadURL()
        .then(httpURL => {
          this.setState({ httpURL: httpURL });
        });
    }
    if (this.state.id) {
      userService.getById(this.state.id).then(user => {
        this.setState({ user });
      });
    }
  }

  render() {
    return (
      <span>
        <label>User involved:</label>{" "}
        {this.state.user && (
          <>
            <img
              alt="..."
              src={this.state.user.data.imageURL}
              height={60}
              className="rounded-circle img-no-padding img-responsive"
            />{" "}
            {this.state.user.data.displayName}
            <label> @{this.state.user.data.uniqueID}</label>
          </>
        )}
      </span>
    );
  }
}

// height={this.state.height}

UserInfo.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(UserInfo);
