import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { transactionActions } from "../actions";
import EmptyContent from "../components/emptyContent.jsx";
import TransactionsTable from "./transactionsTable.jsx";
import TransactionDetail from "./transactionDetail.jsx";
import { Route } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert
} from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "react-select";

//TRS1514732408280
class TransactionsView extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      keyword: "",
      openedCollapses: [],
      conditions: {},
      alert: { show: false, title: "" },
      statusSelectValue: null,
      methodSelectValue: null
    };
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      conditions: {},
      statusSelectValue: null,
      methodSelectValue: null
    });
  }

  handleSearch(e) {
    e.preventDefault();
    if (this.props.location.pathname !== "/main/transactions") {
      this.context.router.history.push("/main/transactions");
    }

    const { keyword, conditions } = this.state;
    const { dispatch } = this.props;
    if (keyword && keyword.length > 0) {
      dispatch(transactionActions.search(keyword));
    } else {
      if (
        !conditions.startAt ||
        !conditions.endAt ||
        !conditions.status ||
        !conditions.method ||
        !conditions.country
      ) {
        this.setState({
          alert: { show: true, title: "Please fill up all the conditions" }
        });
        return;
      }
      if (this.state.alert.show === true)
        this.setState({ alert: { show: false, title: "" } });
      dispatch(transactionActions.search(null, conditions));
    }
  }

  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };

  handleStartDateChange = date => {
    const { conditions } = this.state;
    conditions.startAt = date.toDate().getTime();
    this.setState({ conditions: conditions, keyword: "" });
  };
  handleEndDateChange = date => {
    const { conditions } = this.state;
    conditions.endAt = date.toDate().getTime();
    this.setState({ conditions: conditions, keyword: "" });
  };

  handleCheckDetail(e) {
    e.preventDefault();
  }

  handleCountryChange = e => {
    const { conditions } = this.state;
    conditions.country = e.target.value;
    this.setState({ conditions: conditions, keyword: "" });
  };

  render() {
    const { keyword } = this.state;
    const { loading } = this.props;
    const { isEmpty } = this.props;
    // const { isDetail } = this.props;
    // const { match } = this.props;

    return (
      <div>
        <Card>
          <CardBody>
            {this.state.alert.show === true && (
              <UncontrolledAlert color="warning" fade={false}>
                <span>
                  <b>Warning - </b>
                  {this.state.alert.title}
                </span>
              </UncontrolledAlert>
            )}
            <div
              aria-multiselectable={true}
              className="card-collapse"
              id="accordion"
              role="tablist"
            >
              <Card className="card-plain">
                <Row>
                  <Col md="4" xs="12" sm="6">
                    <CardHeader role="tab">
                      <span
                        aria-expanded={this.state.openedCollapses.includes(
                          "collapseOne"
                        )}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        onClick={() => this.collapsesToggle("collapseOne")}
                      >
                        Conditional search
                        <i className="nc-icon nc-minimal-down" />
                      </span>
                    </CardHeader>
                  </Col>
                </Row>
                <Collapse
                  role="tabpanel"
                  isOpen={this.state.openedCollapses.includes("collapseOne")}
                >
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="3">
                          <label>From</label>
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "Start Date"
                              }}
                              name="start"
                              value={this.state.conditions.startAt}
                              timeFormat={false}
                              onChange={this.handleStartDateChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <label>To</label>
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{
                                className: "form-control",
                                placeholder: "End Date"
                              }}
                              name="end"
                              value={this.state.conditions.endAt}
                              timeFormat={false}
                              onChange={this.handleEndDateChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="checkbox-radios" md="2" sm="10">
                          <label>Country</label>
                          <div>
                            <div className="form-check-radio form-check-inline">
                              <Label className="form-check-label">
                                <Input
                                  type="radio"
                                  name="country"
                                  id="country_TW"
                                  value="TW"
                                  checked={
                                    this.state.conditions.country === "TW"
                                  }
                                  onChange={this.handleCountryChange}
                                />
                                TW
                                <span className="form-check-sign" />
                              </Label>
                            </div>
                            <div className="form-check-radio form-check-inline">
                              <Label className="form-check-label">
                                <Input
                                  type="radio"
                                  name="country"
                                  id="country_US"
                                  value="US"
                                  checked={
                                    this.state.conditions.country === "US"
                                  }
                                  onChange={this.handleCountryChange}
                                />
                                US
                                <span className="form-check-sign" />
                              </Label>
                            </div>
                          </div>
                        </Col>
                        <Col md="3">
                          <label>Status</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="status"
                            value={this.state.statusSelectValue}
                            onChange={target => {
                              const { conditions } = this.state;
                              conditions.status = target.value;
                              this.setState({
                                conditions: conditions,
                                statusSelectValue: target,
                                keyword: ""
                              });
                            }}
                            options={[
                              { value: "completed", label: "Completed" },
                              { value: "processing", label: "Processing" },
                              { value: "cancelled", label: "Cancelled" }
                            ]}
                            placeholder="Select"
                          />
                        </Col>
                        <Col md="3">
                          <label>Shipping Method</label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="method"
                            value={this.state.methodSelectValue}
                            onChange={target => {
                              const { conditions } = this.state;
                              conditions.method = target.value;
                              this.setState({
                                conditions: conditions,
                                methodSelectValue: target,
                                keyword: ""
                              });
                            }}
                            options={[
                              {
                                value: "standard_shipping",
                                label: "Shipping(Taker Pays)"
                              },
                              {
                                value: "offer_free_shipping",
                                label: "Shipping(Giver Pays)"
                              },
                              { value: "familyMart", label: "Family Mart" },
                              {
                                value: "taker_pays_palmbox",
                                label: "Palm Box(Taker Pays)"
                              },
                              {
                                value: "giver_pays_palmbox",
                                label: "Palm Box(Giver Pays)"
                              },
                              {
                                value: "meetup",
                                label: "Meet up"
                              }
                            ]}
                            placeholder="Select"
                          />
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Collapse>
              </Card>
            </div>
            <Form>
              <Row>
                <Col className="col-vin-alignself">
                  <input
                    type="text"
                    className="form-control border-input"
                    name="keyword"
                    value={keyword}
                    placeholder="EX: Order number, Tracking number"
                    onChange={this.handleChange}
                  />
                </Col>
                <Col>
                  <button
                    type="submit"
                    onClick={this.handleSearch}
                    className="btn btn-info btn-fill btn-wd"
                  >
                    Search
                  </button>
                  {loading && (
                    <img
                      alt="..."
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                    />
                  )}
                </Col>
              </Row>
            </Form>
            <Route
              exact
              path="/main/transactions"
              component={TransactionsTable}
            />
            <Route
              path={`/main/transactions/:id`}
              component={TransactionDetail}
            />
            {isEmpty ? <EmptyContent /> : ""}
          </CardBody>
        </Card>
      </div>
    );
  }
}
// <Route path='/transaction/:id' component={TransactionDetail} />
TransactionsView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  //return { transactions: state.transactions };
  const { loading, transactions, isEmpty } = state.transactions;
  return {
    loading,
    isEmpty,
    transactions
  };
};
export default connect(mapStateToProps)(TransactionsView);
