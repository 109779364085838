import { userConstants, authConstants } from "../constants";

var user = localStorage.getItem("user");

const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return { loggedIn: false };
    case authConstants.FETCH_SUCCESS:
      return { ...state, role: action.result };
    default:
      return state;
  }
}
