import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import swal from "sweetalert";
import { connect } from "react-redux";
import { chatActions } from "../../actions";
import { DragSource } from "react-dnd";

const dragSpec = {
  beginDrag: (props, monitor, component) => {
    console.log({ ...props.template });
    return { ...props.template };
  }
};
const dragCollect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};
const ChatTemplateCell = props => {
  const { template, dispatch, connectDragSource, isDragging } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [templateMessage, setTemplateMessage] = useState(template.message);
  useEffect(() => {
    if (!modalVisible) setTemplateMessage(template.message);
  }, [modalVisible]);
  return connectDragSource(
    <div
      style={{
        padding: "4px",
        border: "1px solid #ccc",
        margin: "4px",
        backgroundColor: isDragging ? "green" : ""
        // display: "flex",
        // justifyContent: "space-between",
        // alignItems: "center",
      }}
    >
      <span style={{ wordBreak: "break-all" }}>{template.message}</span>
      <div>
        <Button onClick={() => setModalVisible(true)}>edit</Button>
        <Button
          onClick={e => {
            e.stopPropagation();
            dispatch(chatActions.deleteChatTemplate(template.id));
          }}
        >
          Delete
        </Button>
      </div>
      <Modal
        isOpen={modalVisible}
        toggle={() => setModalVisible(!modalVisible)}
      >
        <ModalBody>
          <textarea
            cols="55"
            rows="5"
            onChange={e => setTemplateMessage(e.target.value)}
            value={templateMessage}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (templateMessage) {
                const { dispatch } = props;
                dispatch(
                  chatActions.updateChatTemplate(template.id, templateMessage)
                );
                setModalVisible(false);
              } else {
                swal("Oops!", "You need to put some wordings", "error");
              }
            }}
          >
            update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const HOC_pipe = [
  connect(),
  DragSource("chatTemplateMessage", dragSpec, dragCollect)
];

export default HOC_pipe.reduce(
  (acc, curFunc) => curFunc(acc),
  ChatTemplateCell
);
