import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import SponsorRequestTable from "./sponsorRequestsTable.jsx";
import SponsorRequestDetail from "./sponsorRequestDetail.jsx";

class SponsorRequestsView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCreate() {}

  render() {
    return (
      <div className="card">
        <div className="card-content">
          <Route
            path={`/main/sponsorRequests/request/:id`}
            component={SponsorRequestDetail}
          />
          <div className="toolbar" />
          <div className="header">
            <Route
              exact
              path="/main/sponsorRequests"
              component={SponsorRequestTable}
            />
          </div>
        </div>
      </div>
    );
  }
}

SponsorRequestsView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  //const { lo } = state.banners;
  return state;
};
export default connect(mapStateToProps)(SponsorRequestsView);
