import CardProto from "../views/editHomePage/fakeData/CardProto.json";
import CurrentContent from "../views/editHomePage/fakeData/CurrentContent.json";
import CategoryConfig from "../views/editHomePage/fakeData/CategoryConfig.json";
import firebase from "firebase";
import { authHeader } from "../helpers";

export const editHomePageService = {
  _getCardProtos() {
    return new Promise((resolve, reject) => {
      resolve(CardProto);
    }).then(data => {
      return Object.keys(data)
        .map(key => {
          data[key].id = key;
          return data[key];
        })
        .sort((a, b) => b.weight - a.weight);
    });
  },
  _getCurrentHomePageContent() {
    return new Promise((resolve, reject) => {
      resolve(CurrentContent);
    }).then(data => {
      Object.keys(data).forEach(lang => {
        data[lang] = Object.keys(data[lang])
          .map(key => {
            data[lang][key].id = key;
            return data[lang][key];
          })
          .sort((a, b) => b.weight - a.weight);
      });
      return data;
    });
  },
  _getZones() {
    const db = firebase.firestore();
    const zoneRef = db.collection("zones");
    return zoneRef.get().then(docs => {
      const zoneArr = {
        TW: [],
        US: []
      };
      docs.forEach(doc => {
        const docData = doc.data();
        docData.id = doc.id;
        zoneArr[docData.country].push(docData);
      });
      return zoneArr;
    });
  },
  _getCategories() {
    return new Promise((resolve, reject) => {
      resolve(CategoryConfig);
    }).then(data => {
      console.log(data);
      const CategoryArr = [];
      data.zh_TW.forEach((category, index) => {
        if (category.code !== "000") {
          category.title = {
            en: data.en[index].name,
            zh_TW: category.name
          };
          CategoryArr.push(category);
        }
      });
      return CategoryArr;
    });
  },
  _saveCardOrder(cardOrder) {
    // need to save order
  },
  getCategories() {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${host}/api/admin/v1/homepage/categories`, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log(data);
        const CategoryArr = [];
        data.zh_TW.forEach((category, index) => {
          if (category.code !== "000") {
            category.title = {
              en: data.en[index].name,
              zh_TW: category.name
            };
            CategoryArr.push(category);
          }
        });
        return CategoryArr;
      });
  },
  getZones() {
    const host = window.location.protocol + "//" + window.location.host;
    const zoneArr = {
      TW: [],
      US: []
    };
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${host}/api/admin/v1/homepage/zones`, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(data => {
        Object.keys(data).forEach(zoneID => {
          data[zoneID].id = zoneID;
          zoneArr[data[zoneID].country].push(data[zoneID]);
        });
        return zoneArr;
      });
  },
  getCardProtos() {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${host}/api/admin/v1/homepage/prototype`, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(data => {
        // if there is difference between US and TW might add judgement
        return data.TW;
      })
      .then(data => {
        return Object.keys(data)
          .map(key => {
            data[key].id = key;
            return data[key];
          })
          .sort((a, b) => b.weight - a.weight);
      });
  },
  getCurrentHomePageContent() {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${host}/api/admin/v1/homepage/current`, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(data => {
        Object.keys(data).forEach(lang => {
          data[lang] = Object.keys(data[lang])
            .map(key => {
              data[lang][key].id = key;
              return data[lang][key];
            })
            .sort((a, b) => b.weight - a.weight);
        });
        return data;
      });
  },
  saveCardOrder(cardOrder) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(cardOrder)
    };
    return fetch(`${host}/api/admin/v1/homepage/current`, requestOptions).then(
      response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      }
    );
  }
};
