import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Media, Row } from "reactstrap";
// import firebase from "firebase/app";
// import { userService } from "../services";
import { userActions } from "../actions";
import ReactLoading from "react-loading";
import moment from "moment";
import UserInfoVertical from "./userInfoItemVertical.jsx";
import { Button } from "reactstrap";

class SuspendedUserListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activities: []
    };
  }

  componentDidMount() {
    // if (this.props.imageURL) {
    //   var storage = firebase.storage();
    //   storage
    //     .ref(this.props.imageURL)
    //     .getDownloadURL()
    //     .then(httpURL => {
    //       this.setState({ httpURL: httpURL });
    //     });
    // }
    // if (this.state.id) {
    //   userService.getById(this.state.id).then(user => {
    //     this.setState({ user });
    //   });
    // }
    const { dispatch } = this.props;
    dispatch(userActions.getSuspendedActivities());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activities) {
      this.setState({ loading: false, activities: nextProps.activities });
    }
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <div className="loading-center">
            <ReactLoading
              type="spinningBubbles"
              color="#eee"
              height="30px"
              width="30px"
            />
          </div>
        ) : (
          <div className="content table-responsive table-full-width">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>User</th>
                  <th>Reason</th>
                  <th>Suspended By</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.activities.map(el => (
                  <tr key={el.createdAt}>
                    <td>{moment(el.createdAt).format("LLL")}</td>
                    <td>
                      <UserInfoVertical id={el.actionID} />
                    </td>
                    <td>{el.reason}</td>
                    <td>
                      {el.adminId === "system" ? (
                        "System"
                      ) : (
                        <UserInfoVertical id={el.adminId} />
                      )}
                    </td>
                    {/* <td>
                      <Button onClick={this.didClickResume}>Resume</Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
}

// height={this.state.height}

SuspendedUserListView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { suspended } = state.users;
  return suspended ? suspended : {};
};

export default connect(mapStateToProps)(SuspendedUserListView);
