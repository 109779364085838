import React, { Component } from "react";
// import { render } from "react-dom";
// import { userActions } from "../actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Route } from "react-router-dom";
// import BannerTable from "./bannerTable.jsx";
import moment from "moment";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import { bannerActions } from "../actions";
import ReactLoading from "react-loading";
import firebase from "firebase/app";
import "firebase/storage";
import FileUploader from "react-firebase-file-uploader";
import Utility from "../helpers/utility";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import $ from "jquery";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Row,
  Col,
  Input
} from "reactstrap";

class BannerCreateView extends Component {
  constructor(props) {
    super(props);
    this.bannerId = undefined;

    this.state = {
      banner: {
        name: "",
        action: "webView",
        country: "TW",
        isVisible: false,
        startDate: moment()
          .startOf("hour")
          .add(1, "hour"),
        endDate: moment()
          .startOf("hour")
          .add(1, "hour")
          .add(1, "day"),
        metadata: {
          en: { description: "", imageURL: "", link: "", title: "" },
          zh_TW: { description: "", imageURL: "", link: "", title: "" }
        }
      },
      currentCountry: { label: "Taiwan", value: "TW" },
      currentLanguage: { label: "Chinese(Traditional)", value: "zh_TW" },
      isActionUser: false,
      error: { show: false, reason: "" }
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleIsVisibleChange = this.handleIsVisibleChange.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleUserIDChange = this.handleUserIDChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);

    if (this.props.match.params.id) {
      if (this.props.banners && this.props.banners.length > 0) {
        var banners = this.props.banners.filter(el => {
          return el.id === this.props.match.params.id;
        });
        if (banners.length > 0) {
          this.bannerId = banners[0].id;
          var banner = banners[0].data;
          banner.createdAt = Utility.dateFromServer(banner.startDate);
          banner.startDate = moment(Utility.dateFromServer(banner.startDate));
          banner.endDate = moment(Utility.dateFromServer(banner.endDate));
          this.state.banner = banner;
          if (!this.state.banner.name) {
            this.state.banner.name = banner.metadata.zh_TW.title;
          }
        }
      } else {
        const { dispatch } = this.props;
        dispatch(bannerActions.get(this.props.match.params.id));
      }
    }
  }

  handleCreate() {}

  handleTextChange(e) {
    const { name, value } = e.target;
    var banner = this.state.banner;
    if (name === "name") {
      banner.name = value;
    } else {
      banner.metadata[this.state.currentLanguage.value][name] = value;
    }
    this.setState({ banner: banner });
  }

  handleUserIDChange(e) {
    const { value } = e.target;
    var banner = this.state.banner;
    banner.actionID = value;
    this.setState({ banner: banner });
  }

  handleCountryChange(info) {
    this.setState({
      currentCountry: info,
      banner: {
        ...this.state.banner,
        country: info.value
      }
    });
  }

  handleTypeChange(e) {
    $(".btn-group > .btn").removeClass("active");
    $(e.target).addClass("active");
    if (e.target.value === "sponsor" || e.target.value === "user") {
      this.setState({ isActionUser: true });
    } else {
      this.setState({ isActionUser: false });
    }
    if (e.target.value === "sponsor") {
      this.setState({
        banner: {
          ...this.state.banner,
          action: "user",
          condition: "sponsor"
        }
      });
    } else {
      this.setState({
        banner: {
          ...this.state.banner,
          action: e.target.value
        }
      });
    }
  }

  setType = type => {
    this.handleTypeChange($(`[name="${type}]"`));
  };

  handleStartDateChange(date) {
    this.setState({
      ...this.state,
      banner: {
        ...this.state.banner,
        startDate: date
      }
    });
  }

  handleEndDateChange(date) {
    this.setState({
      ...this.state,
      banner: {
        ...this.state.banner,
        endDate: date
      }
    });
  }

  handleStartValidDate = date => {
    var now = moment().subtract(1, "day");
    return date > now;
  };

  handleEndValidDate = date => {
    var tomorrow = moment().add(1, "day"); //.subtract( 1, 'day' );
    return date > tomorrow;
  };

  handleIsVisibleChange(isVisible) {
    this.setState({
      ...this.state,
      banner: {
        ...this.state.banner,
        isVisible: isVisible
      }
    });
  }

  handleLanguageChange(value) {
    this.setState({
      currentLanguage: value
    });
  }

  //handle file upload
  handleUploadStart() {
    this.setState({ fileUploading: true });
  }

  handleUploadError() {
    this.setState({ fileUploading: false });
  }

  handleUploadSuccess(fileName) {
    var banner = this.state.banner;
    var anotherLang = "en";
    if (this.state.currentLanguage.value === "en") {
      anotherLang = "zh_TW";
    }

    banner.metadata[this.state.currentLanguage.value].imageURL =
      "banner_images/official/" + fileName;

    if (
      !banner.metadata[anotherLang].imageURL ||
      banner.metadata[anotherLang].imageURL === ""
    ) {
      banner.metadata[anotherLang].imageURL =
        "banner_images/official/" + fileName;
    }

    this.setState({ fileUploading: false, banner: banner });

    firebase
      .storage()
      .ref("images/official")
      .child(fileName)
      .getDownloadURL()
      .then(url => {});
  }

  handleProgress() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      var newBanner;
      if (!nextProps.banner) {
        newBanner = this.state.banner;
      } else if (nextProps.banner.id) {
        newBanner = nextProps.banner.data;
        //newBanner.createdAt = Utility.dateFromServer(newBanner.createdAt);
        newBanner.startDate = moment(
          Utility.dateFromServer(newBanner.startDate)
        );
        newBanner.endDate = moment(Utility.dateFromServer(newBanner.endDate));
        newBanner.createdAt = moment(
          Utility.dateFromServer(newBanner.createdAt)
        );

        this.bannerId = nextProps.banner.id;
      }
      if (!newBanner.name) {
        newBanner.name = newBanner.metadata.zh_TW.title;
      }
      this.setState({ banner: newBanner, loading: nextProps.loading });
    }
  }

  handleClickSave() {
    this.setState({ loading: true, error: { show: false, reason: "" } });
    const banner = this.state.banner;
    var reason = undefined;
    if (!banner.name || banner.name === "") {
      reason = "Name cannot be empty";
    }
    var primaryLang = "zh_TW";
    if (banner.country === "TW") {
      primaryLang = "zh_TW";
    } else if (banner.country === "US") {
      primaryLang = "en";
    }
    if (banner.metadata[primaryLang].imageURL.length === 0) {
      reason = "Image URL can't be empty";
    }
    if (banner.action === "webView" || banner.action === "browser") {
      if (banner.metadata[primaryLang].link.length === 0) {
        reason = "Link URL can't be empty";
      }
    }
    if (banner.action === "user" && banner.actionID.length === 0) {
      reason = "User ID can't be empty";
    }

    if (!this.bannerId) {
      if (banner.startDate < moment()) {
        reason = "Start Date cannot greater than current time.";
      }
    }

    if (reason) {
      this.setState({ loading: false, error: { show: true, reason: reason } });
      return;
    }

    const { dispatch } = this.props;
    if (this.bannerId)
      dispatch(bannerActions.update(this.bannerId, this.state.banner));
    else dispatch(bannerActions.create(this.state.banner));
  }

  render() {
    const { loading, isActionUser, error, fileUploading } = this.state;
    return (
      <Card>
        <CardBody>
          <div className="toolbar" />
          <Row>
            <Col sm="10" md="6">
              <FormGroup>
                <CardTitle>Name</CardTitle>
                <Input
                  type="text"
                  name="name"
                  className="form-control"
                  value={this.state.banner.name}
                  onChange={this.handleTextChange}
                />
              </FormGroup>
            </Col>
            <Col md="4" />
          </Row>
          <Row>
            <Col md="6">
              <CardTitle>Type</CardTitle>
              <div className="btn-group">
                <button
                  type="button"
                  className={`btn btn-default ${
                    this.state.banner.action === "webView" ? "active" : ""
                  }`}
                  value="webView"
                  onClick={this.handleTypeChange}
                >
                  Web View
                </button>
                <button
                  type="button"
                  className={`btn btn-default ${
                    this.state.banner.action === "browser" ? "active" : ""
                  }`}
                  value="browser"
                  onClick={this.handleTypeChange}
                >
                  Browser
                </button>
                <button
                  type="button"
                  className={`btn btn-default ${
                    this.state.banner.action === "user" ? "active" : ""
                  }`}
                  value="user"
                  onClick={this.handleTypeChange}
                >
                  User
                </button>
                <button
                  type="button"
                  className={`btn btn-default ${
                    this.state.banner.action === "sponsor" ? "active" : ""
                  }`}
                  value="sponsor"
                  onClick={this.handleTypeChange}
                >
                  Sponsor
                </button>
              </div>
            </Col>
            <Col md="6">
              <FormGroup>
                <CardTitle>Country</CardTitle>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  id="SelectCountry"
                  value={this.state.currentCountry}
                  onChange={this.handleCountryChange}
                  options={[
                    {
                      value: "TW",
                      label: "Taiwan"
                    },
                    { value: "US", label: "United States" }
                  ]}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <div className="col-md-3">
              <h4 className="card-title">Start Date</h4>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Start Date"
                }}
                value={this.state.banner.startDate}
                onChange={this.handleStartDateChange}
                isValidDate={this.handleStartValidDate}
                timeConstraints={{
                  minutes: { step: 30 }
                }}
              />
            </div>
            <div className="col-md-3">
              <h4 className="card-title">End Date</h4>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "End Date"
                }}
                value={this.state.banner.endDate}
                onChange={this.handleEndDateChange}
                isValidDate={this.handleEndValidDate}
                timeConstraints={{
                  minutes: { step: 30 }
                }}
              />
            </div>
            <div className="col-md-6">
              <h4 className="card-title">Running</h4>
              <Switch
                onChange={this.handleIsVisibleChange}
                checked={this.state.banner.isVisible}
                id="normal-switch"
              />
            </div>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <form
                id="allInputsFormValidation"
                className="form-horizontal"
                action=""
                method="post"
                noValidate=""
              >
                <CardTitle>Information</CardTitle>
                {error.show && (
                  <div className="alert alert-warning" role="alert">
                    {error.reason}
                  </div>
                )}
                {isActionUser && (
                  <fieldset>
                    <div className="form-group">
                      <label className="col-sm-2 col-md-2 control-label">
                        User ID
                      </label>
                      <div className="col-sm-10 col-md-6">
                        <input
                          type="text"
                          name="actionID"
                          className="form-control"
                          value={this.state.banner.actionID}
                          onChange={this.handleUserIDChange}
                        />
                      </div>
                    </div>
                  </fieldset>
                )}
                <fieldset>
                  <div className="form-group">
                    <label className="col-sm-2 col-md-2 control-label">
                      Language
                    </label>
                    <div className="col-sm-10 col-md-6">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        id="language"
                        value={this.state.currentLanguage}
                        onChange={this.handleLanguageChange}
                        options={[
                          {
                            value: "zh_TW",
                            label: "Chinese(Traditional)"
                          },
                          { value: "en", label: "English" }
                        ]}
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset>
                  <div className="form-group">
                    <label className="col-sm-2 col-md-2 control-label">
                      Description
                    </label>
                    <div className="col-sm-10 col-md-6">
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        value={
                          this.state.banner.metadata[
                            this.state.currentLanguage.value
                          ].description
                        }
                        onChange={this.handleTextChange}
                      />
                    </div>
                    <div className="col-md-4" />
                  </div>
                </fieldset>
                <br />
                <fieldset>
                  <div className="form-group">
                    <label className="col-sm-2 col-md-2 control-label">
                      ImageURL
                    </label>
                    <div className="col-sm-10 col-md-6">
                      <input
                        type="url"
                        name="imageURL"
                        className="form-control"
                        url="true"
                        value={
                          this.state.banner.metadata[
                            this.state.currentLanguage.value
                          ].imageURL
                        }
                        onChange={this.handleTextChange}
                        disabled
                      />
                    </div>
                    <div className="col-md-4">
                      <label>
                        Select image
                        <FileUploader
                          accept="image/*"
                          name="banner"
                          randomizeFilename
                          storageRef={firebase
                            .storage()
                            .ref("banner_images/official")}
                          onUploadStart={this.handleUploadStart.bind(this)}
                          onUploadError={this.handleUploadError.bind(this)}
                          onUploadSuccess={this.handleUploadSuccess.bind(this)}
                          onProgress={this.handleProgress.bind(this)}
                        />
                      </label>

                      {fileUploading && (
                        <ReactLoading
                          type="spinningBubbles"
                          color="#eee"
                          height="30px"
                          width="30px"
                        />
                      )}
                    </div>
                  </div>
                </fieldset>
                <br />
                <fieldset>
                  <div className="form-group">
                    <label className="col-sm-2 col-md-2 control-label">
                      Link
                    </label>
                    <div className="col-sm-10 col-md-6">
                      <input
                        type="url"
                        name="link"
                        className="form-control"
                        url="true"
                        value={
                          this.state.banner.metadata[
                            this.state.currentLanguage.value
                          ].link
                        }
                        onChange={this.handleTextChange}
                      />
                    </div>
                    <div className="col-md-4" />
                  </div>
                </fieldset>
                <br />
                <fieldset>
                  <div className="form-group">
                    <label className="col-sm-2 col-md-2 control-label">
                      Title
                    </label>
                    <div className="col-sm-10 col-md-6">
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={
                          this.state.banner.metadata[
                            this.state.currentLanguage.value
                          ].title
                        }
                        onChange={this.handleTextChange}
                      />
                    </div>
                    <div className="col-md-4" />
                  </div>
                </fieldset>
              </form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="submit"
                onClick={this.handleClickSave}
                className="pull-right btn btn-info btn-fill btn-wd"
              >
                Save
              </Button>
              {loading && (
                <ReactLoading
                  type="spinningBubbles"
                  color="#eee"
                  height="30px"
                  width="30px"
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

BannerCreateView.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  // const { loading, banner, success } = state.banner;
  var newProp = state.banner;
  return newProp;
};
export default connect(mapStateToProps)(BannerCreateView);
