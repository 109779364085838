import React, { Component } from "react";
// import { render } from "react-dom";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { transactionActions } from "../actions";
// import { userActions } from "../actions";
import { Link } from "react-router-dom";
import { UserContent } from "../components";
import TransactionEcanOrder from "./transactionEcanOrder.jsx";
import TransactionPayment from "./transactionPayment.jsx";
import TransactionCancelOptions from "./transactionCancelOptions.jsx";
import TransactionRating from "./transactionRating.jsx";
import AddressInfoModal from "./AddressInfoModal.jsx";
import StorageImage from "./StorageImage.jsx";
import swal from "sweetalert";
import moment from "moment";
import { paymentActions } from "../actions";
import {
  Row,
  Col,
  Label,
  FormGroup,
  Form,
  Card,
  CardBody,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";

class TransactionDetail extends Component {
  constructor(props) {
    super(props);
    let defaultCancelOptions = {
      isDeletingPost: false,
      isHidingPost: true,
      isReturningTP: true,
      isRefund: true,
      raters: ["taker", "giver"],
      reason: "mutualAgreement"
    };

    this.state = {
      tranasction: {},
      cancelOptions: defaultCancelOptions,
      editShippingInfoModal: false,
      editPickupInfoModal: false,
      updateAddressInfo: {}
    };

    if (this.props.transactions && this.props.transactions.length > 0) {
      var transactions = this.props.transactions.filter(el => {
        return el.data.numeric_id === this.props.match.params.id;
      });

      if (transactions.length > 0) {
        this.state = {
          transaction: transactions[0],
          cancelOptions: defaultCancelOptions,
          editShippingInfoModal: false,
          editPickupInfoModal: false,
          updateAddressInfo: {}
        };
      }
    } else if (this.props.transaction) {
      this.state = {
        transaction: this.props.transaction,
        cancelOptions: defaultCancelOptions,
        editShippingInfoModal: false,
        editPickupInfoModal: false,
        updateAddressInfo: {}
      };
    } else {
      if (this.props.match.params && this.props.match.params.id) {
        const { dispatch } = this.props;
        dispatch(transactionActions.get(this.props.match.params.id));
      }
    }

    this.handleOnClickRefund = this.handleOnClickRefund.bind(this);
    this.handleOnClickCancel = this.handleOnClickCancel.bind(this);
    this.handleOnClickComplete = this.handleOnClickComplete.bind(this);
  }

  componentWillUnmount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.transaction) {
      this.setState({ transaction: nextProps.transaction });
    }

    if (nextProps.updateAddressInfo) {
      if (nextProps.updateAddressInfo.result) {
        let result = nextProps.updateAddressInfo.result;
        if (result.updatedEcanOrder) {
          this.setState({
            editShippingInfoModal: false,
            editPickupInfoModal: false,
            updateAddressInfo: {},
            updatedEcanOrder: result.updatedEcanOrder
          });
        } else {
          const { updatedEcanOrder, ...state } = this.state;
          this.setState({
            ...state,
            editShippingInfoModal: false,
            editPickupInfoModal: false,
            updateAddressInfo: {}
          });
        }
      } else {
        this.setState({ updateAddressInfo: nextProps.updateAddressInfo });
      }
    }
  }

  handleOptions(options) {
    this.setState({ cancelOptions: options });
  }

  handleOnClickComplete(e) {
    e.preventDefault();
    var self = this;
    let wrapper = document.createElement("div");
    ReactDOM.render(
      <TransactionCancelOptions
        onOptionChange={this.handleOptions.bind(this)}
      />,
      wrapper
    );
    swal({
      title: "Are you sure to Complete this order?",
      text: "This cannot be undone",
      icon: "warning",
      buttons: [
        {
          text: "Cancel",
          value: null,
          visible: true,
          //className: "btn btn-success btn-fill",
          closeModal: true
        },
        {
          text: "Confirm",
          value: true,
          visible: true,
          //className: "btn btn-danger btn-fill",
          closeModal: false
        }
      ],
      dangerMode: true,
      buttonsStyling: false
    }).then(function(confirm) {
      if (confirm) {
        const { transaction } = self.state;

        const { dispatch } = self.props;

        dispatch(transactionActions.complete(transaction));
      }
    });
  }

  handleOnClickCancel(e) {
    e.preventDefault();
    // const { cancelOptions } = this.state;

    var self = this;
    let wrapper = document.createElement("div");
    ReactDOM.render(
      <TransactionCancelOptions
        onOptionChange={this.handleOptions.bind(this)}
      />,
      wrapper
    );
    let el = wrapper.firstChild;

    swal({
      title: "Are you sure to cancel this order?",
      text: "This cannot be undone",
      icon: "warning",
      content: el,
      buttons: [
        {
          text: "Cancel",
          value: null,
          visible: true,
          //className: "btn btn-success btn-fill",
          closeModal: true
        },
        {
          text: "Confirm",
          value: true,
          visible: true,
          //className: "btn btn-danger btn-fill",
          closeModal: false
        }
      ],
      dangerMode: true,
      buttonsStyling: false
    }).then(function(confirm) {
      if (confirm) {
        const { transaction, cancelOptions } = self.state;

        const { dispatch } = self.props;

        dispatch(transactionActions.cancel(transaction, cancelOptions));
      }
    });
  }

  handleOnClickRefund(tradId) {
    if (tradId) {
      var self = this;
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: [
          {
            text: "Cancel",
            value: null,
            visible: true,
            //className: "btn btn-success btn-fill",
            closeModal: true
          },
          {
            text: "Refund",
            value: true,
            visible: true,
            //className: "btn btn-danger btn-fill",
            closeModal: false
          }
        ],
        dangerMode: true,
        buttonsStyling: false
      }).then(function(confirm) {
        if (confirm) {
          const { dispatch } = self.props;

          dispatch(paymentActions.refund(tradId));
        }
      });
    }
  }

  toggleEditShippingInfo = () => {
    this.setState(prevState => ({
      editShippingInfoModal: !prevState.editShippingInfoModal
    }));
  };

  toggleEditPickupInfo = () => {
    this.setState(prevState => ({
      editPickupInfoModal: !prevState.editPickupInfoModal
    }));
  };

  handleClickSaveAddressInfo = (type, addressInfo, updateEcanOrder) => {
    const { dispatch } = this.props;
    const { transaction } = this.state;
    if (
      updateEcanOrder &&
      transaction.data.country == "TW" &&
      transaction.data.pickupDate
    ) {
      dispatch(
        transactionActions.updateAddressInfo(
          transaction.id,
          type,
          addressInfo,
          transaction.data.pickupDate
        )
      );
    } else {
      dispatch(
        transactionActions.updateAddressInfo(transaction.id, type, addressInfo)
      );
    }
  };

  render() {
    const { transaction } = this.state;
    if (!transaction) {
      return <div />;
    }

    var data = transaction.data;

    var pickupInfo = data.pickup_info ? (
      <Col>
        <FormGroup>
          <h6>
            Pickup Info
            <span>
              <Button color="link" onClick={this.toggleEditPickupInfo}>
                <i className="ti-pencil-alt" />
              </Button>
            </span>
          </h6>
          <p>{data.pickup_info.fullName}</p>
          <p>{data.pickup_info.contactPhone}</p>
          <p className="form-control-static"> {data.pickup_info.fullAddress}</p>
        </FormGroup>
        <AddressInfoModal
          title="Edit Pickup Info"
          type="pickup"
          toggle={this.toggleEditPickupInfo}
          save={this.handleClickSaveAddressInfo}
          isOpen={this.state.editPickupInfoModal}
          addressInfo={data.pickup_info}
          country={data.country}
          error={this.state.updateAddressInfo.error}
          loading={this.state.updateAddressInfo.loading}
        />
      </Col>
    ) : (
      ""
    );

    var shippingInfo = data.shipping_info ? (
      <Col>
        <FormGroup>
          <h6>
            Shipping Info
            <span>
              <Button color="link" onClick={this.toggleEditShippingInfo}>
                <i className="ti-pencil-alt" />
              </Button>
            </span>
          </h6>
          <p>{data.shipping_info.fullName}</p>
          <p>{data.shipping_info.contactPhone}</p>
          <p className="form-control-static">
            {data.shipping_info.fullAddress}
          </p>
        </FormGroup>
        <AddressInfoModal
          title="Edit Shipping Info"
          type="shipping"
          toggle={this.toggleEditShippingInfo}
          save={this.handleClickSaveAddressInfo}
          isOpen={this.state.editShippingInfoModal}
          addressInfo={data.shipping_info}
          country={data.country}
          error={this.state.updateAddressInfo.error}
          loading={this.state.updateAddressInfo.loading}
        />
      </Col>
    ) : (
      ""
    );
    var meetupInfo = data.meetup_info ? (
      <Col>
        <FormGroup>
          <h6>Meetup Info</h6>
          <p className="form-control-static">{data.meetup_info.fullAddress}</p>
        </FormGroup>
      </Col>
    ) : (
      ""
    );

    var cancelButton =
      data.status === "Completed" || data.status === "Cancelled" ? (
        ""
      ) : (
        <button
          className="btn btn-danger btn-fill btn-wd pull-right"
          onClick={this.handleOnClickCancel}
        >
          Cancel Order
        </button>
      );

    var completeButton =
      data.status !== "Processing" ? (
        ""
      ) : (
        <button
          className="btn btn-primary btn-fill btn-wd pull-right"
          onClick={this.handleOnClickComplete}
        >
          Complete Order
        </button>
      );

    const timestamp = data.createdAt;
    const formatted = moment(timestamp).format("LLLL");

    return (
      <div>
        <div className="header">
          <Row className="row-vin-padding12">
            <Col>
              <h4>{this.props.match.params.id}</h4>
              <Label>{data.status}</Label>
            </Col>
          </Row>
          <Row className="row-vin-padding12">
            <Col>
              Created time : <Label>{formatted}</Label>
            </Col>
          </Row>
          <Row className="row-vin-padding12">
            <Col>
              Last updated :{" "}
              <Label>{moment(data.updatedAt).format("LLLL")}</Label>
            </Col>
          </Row>
        </div>
        <div className="content">
          <Form>
            <Row className="row-vin-padding12">
              <Col>
                <h5>Detail</h5>
              </Col>
            </Row>

            <Row className="row-vin-padding12">
              <Col>
                <FormGroup>
                  <h6>
                    Item
                    {/* <span>
                      <Button color="link">
                        <i className="ti-eye" />
                      </Button>
                    </span> */}
                  </h6>
                  <p className="form-control-static">
                    <StorageImage imageURL={data.imageURL} height="160px" />
                  </p>
                  <div>{data.item_title}</div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <h6>Country</h6>
                  <p className="form-control-static"> {data.country}</p>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <h6>Method</h6>
                  <p className="form-control-static">{data.method}</p>
                </FormGroup>
              </Col>
            </Row>

            <Row className="row-vin-padding12">
              {data.tracking_number && (
                <Col>
                  <FormGroup>
                    <h6>Tracking number</h6>
                    <p className="form-control-static">
                      {data.tracking_number}
                    </p>
                  </FormGroup>
                </Col>
              )}

              {data.invoiceNumber && (
                <Col>
                  <FormGroup>
                    <h6>invoiceNumber</h6>
                    <p className="form-control-static"> {data.invoiceNumber}</p>
                  </FormGroup>
                </Col>
              )}

              {data.shippingStatus && (
                <Col>
                  <FormGroup>
                    <h6>Shipping Status</h6>
                    <p className="form-control-static">{data.shippingStatus}</p>
                  </FormGroup>
                </Col>
              )}
            </Row>

            <Row className="row-vin-padding12">
              <Col>
                <FormGroup>
                  <h6>Giver</h6>
                  <UserContent id={data.giver} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <h6>Taker</h6>
                  <UserContent id={data.taker} />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <h6>Designator</h6>
                  <UserContent id={data.designator} />
                </FormGroup>
              </Col>
            </Row>

            <Row className="row-vin-padding12">
              {pickupInfo}
              {shippingInfo}
              {meetupInfo}
            </Row>

            <Row className="row-vin-padding12">
              <Col>
                <FormGroup>
                  <h6>Charge</h6>
                  <div>
                    {data.charge}
                    {data.currency}
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <h6>Payer</h6>
                  <UserContent id={data.payer} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <form>
            <TransactionEcanOrder
              transaction={transaction}
              updatedEcanOrder={this.state.updatedEcanOrder}
            />
            <TransactionPayment
              transaction={transaction}
              onClick={e => this.handleOnClickRefund(e)}
            />
            <TransactionRating transaction={transaction} />
            {data.history && data.history.length > 0 && (
              <Card className="card-timeline card-plain">
                <CardBody>
                  <ul className="timeline timeline-simple">
                    {data.history.map(element => (
                      <li className="timeline-inverted" key={element.createdAt}>
                        <div className="timeline-badge danger">
                          <i className="nc-icon nc-single-copy-04" />
                        </div>
                        <div className="timeline-panel">
                          <div className="timeline-heading">
                            <Badge color="danger" pill>
                              {element.status}
                            </Badge>
                          </div>
                          <h6>
                            <i className="ti-time" />
                            {moment(element.createdAt).format("LLLL")}
                          </h6>
                        </div>
                      </li>
                    ))}
                  </ul>
                </CardBody>
              </Card>
            )}
            <div className="text-center">
              <Link to="/main/transactions">
                <button className="btn btn-info btn-fill btn-wd pull-left">
                  Back
                </button>
              </Link>
              {cancelButton}
              {completeButton}
            </div>
            <div className="text-right" />
            <div className="clearfix" />
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { transactions, transaction, updateAddressInfo } = state.transactions;
  return {
    transactions,
    transaction,
    updateAddressInfo
  };
};
export default connect(mapStateToProps)(TransactionDetail);
