import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
            </ul>
          </nav>
          <div className="copyright pull-right">
            Copyright &copy;{" "}
            <script>document.write(new Date().getFullYear())</script>Yoger Inc.
          </div>
        </div>
      </footer>
    );
  }
}
