import React, { useState } from "react";
import { Label, Input, Button, Table } from "reactstrap";
import { sponsorService } from "../services";
import { Link } from "react-router-dom";
import moment from "moment";

const SponsorSearch = props => {
  const [keyWord, setKeyWord] = useState("");
  const [sponsors, setSponsors] = useState([]);
  return (
    <div>
      <Label for="sponsorSearch">search sponsor name</Label>
      <Input
        name="sponsorSearch"
        id="sponsorSearch"
        placeholder="search sponsor"
        value={keyWord}
        onChange={e => setKeyWord(e.target.value)}
      />
      <Button
        onClick={() => {
          if (keyWord) {
            sponsorService
              .searchSponsor(keyWord)
              .then(data => setSponsors(data));
          } else {
            setSponsors([]);
          }
        }}
      >
        search
      </Button>
      {sponsors.length > 0 && (
        <Table responsive style={{ backgroundColor: "yellow" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>UniqueId</th>
              <th>Date</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {sponsors.map(el => (
              <tr key={el.id}>
                <td>{el.data.displayName}</td>
                <td>{el.data.email}</td>
                <td>{el.data.uniqueID}</td>
                <td>{moment(el.data.createdAt).format("LLLL")}</td>
                <td>
                  <Link to={`/main/sponsors/detail/${el.id}`}>
                    <button className="btn btn-info btn-fill">Detail</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default SponsorSearch;
