export const bannerConstants = {
  CREATE_REQUEST: "BANNER_CREATE_REQUEST",
  CREATE_SUCCESS: "BANNER_CREATE_SUCCESS",
  CREATE_FAILURE: "BANNER_CREATE_FAILURE",

  UPDATE_REQUEST: "BANNER_UPDATE_REQUEST",
  UPDATE_SUCCESS: "BANNER_UPDATE_SUCCESS",
  UPDATE_FAILURE: "BANNER_UPDATE_FAILURE",

  GET_ALL_REQUEST: "BANNER_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "BANNER_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "BANNER_GET_ALL_FAILURE",

  GET_REQUEST: "BANNER_GET_REQUEST",
  GET_SUCCESS: "BANNER_GET_SUCCESS",
  GET_FAILURE: "BANNER_GET_FAILURE"
};
