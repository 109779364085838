export const dashboardConstants = {
  GET_TRANSACTION_COUNT_REQUEST: "GET_TRANSACTION_COUNT_REQUEST",
  GET_TRANSACTION_COUNT_SUCCESS: "GET_TRANSACTION_COUNT_SUCCESS",
  GET_TRANSACTION_COUNT_FAILURE: "GET_TRANSACTION_COUNT_FAILURE",

  GET_DETAILED_TRANSACTION_COUNT_REQUEST:
    "GET_DETAILED_TRANSACTION_COUNT_REQUEST",
  GET_DETAILED_TRANSACTION_COUNT_SUCCESS:
    "GET_DETAILED_TRANSACTION_COUNT_SUCCESS",
  GET_DETAILED_TRANSACTION_COUNT_FAILURE:
    "GET_DETAILED_TRANSACTION_COUNT_FAILURE",

  GET_CATEGORY_INFOS_REQUEST: "GET_CATEGORY_INFOS_REQUEST",
  GET_CATEGORY_INFOS_SUCCESS: "GET_CATEGORY_INFOS_SUCCESS",
  GET_CATEGORY_INFOS_FAILURE: "GET_CATEGORY_INFOS_FAILURE"
};
