import { authHeader } from "../helpers";
export const bannerService = {
  create,
  getAll,
  get,
  update
};

function create(banner) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ banner })
  };
  return fetch(host + "/api/admin/v1/banner", requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function update(id, banner) {
  var host = window.location.protocol + "//" + window.location.host;
  if (banner.data) {
    banner = banner.data;
  }
  banner.id = id;
  if (
    banner.startDate instanceof Object &&
    banner.startDate.hasOwnProperty("_seconds")
  ) {
    let timestamp = banner.startDate;
    banner.startDate = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    );
  }
  if (
    banner.endDate instanceof Object &&
    banner.endDate.hasOwnProperty("_seconds")
  ) {
    let timestamp = banner.endDate;
    banner.endDate = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    );
  }

  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ banner })
  };
  return fetch(host + "/api/admin/v1/banner", requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.status);
    }
    return response.json();
  });
}

function getAll() {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(host + "/api/admin/v1/banners", requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}

function get(id) {
  var host = window.location.protocol + "//" + window.location.host;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" }
  };

  return fetch(host + "/api/admin/v1/banner/" + id, requestOptions).then(
    response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    }
  );
}
