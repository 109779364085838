import React, { Component } from "react";

export default class EmptyContent extends Component {
  render() {
    return (
      <div className="card">
        <br />
        <p className="text-center">No Result, Please try another keyword</p>
        <br />
      </div>
    );
  }
}
