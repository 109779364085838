import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import { withRouter } from "react-router-dom";
import loadingTp from "../../../assets/img/loadingTp.png";

const PostSquare = props => {
  const { post } = props;
  const [picUrl, setPicUrl] = useState(loadingTp);
  useEffect(() => {
    const storageRef = firebase.storage().ref();
    storageRef
      .child(post.mediumThumbnailURL || post.imageURL)
      .getDownloadURL()
      .then(url => {
        setPicUrl(url);
      });
  }, [post.mediumThumbnailURL || post.imageURL]);
  return (
    <div
      style={{ width: "200px", margin: "8px", cursor: "pointer" }}
      // onClick={() => props.history.push(`/main/posts/detail/${post.id}`)}
      onClick={() => window.open(`#/main/posts/detail/${post.id}`)}
    >
      <div
        style={{
          width: "200px",
          height: "200px",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${picUrl})`
        }}
      />
      {!props.notitle && <div>{post.title}</div>}
    </div>
  );
};

PostSquare.defaultProps = {
  post: {},
  notitle: false
};
PostSquare.propTypes = {
  post: PropTypes.object.isRequired,
  notitle: PropTypes.bool.isRequired
};
export default withRouter(PostSquare);
