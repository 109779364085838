import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import firebase from "firebase/app";

class UserImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height,
      id: props.key
    };
  }

  componentDidMount() {
    if (this.props.imageURL) {
      var storage = firebase.storage();
      storage
        .ref(this.props.imageURL)
        .getDownloadURL()
        .then(httpURL => {
          this.setState({ httpURL: httpURL });
        });
    }
  }

  render() {
    return (
      <img
        alt="..."
        src={this.state.httpURL}
        height={this.state.height}
        className="rounded-circle img-no-padding img-responsive"
      />
    );
  }
}

// height={this.state.height}

UserImage.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(UserImage);
