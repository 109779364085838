import firebase from "firebase";
import { authHeader } from "../helpers";
import queryString from "query-string";
//
// ─── IF THERE IS A LOWDASH BEFORE FUNTION NAME MEANS IT IS FOR DEVELOPE ─────────────
//

export const communitiesService = {
  // need to add paging
  _getCommunities(
    status = 1,
    privacy = 1,
    startPoint = new Date().valueOf(),
    direction = "next"
  ) {
    const db = firebase.firestore();
    return (
      db
        .collection("communities")
        .where("status", "==", status)
        .where("privacy", "==", privacy)
        // .orderBy("admin", "asc")
        .orderBy("createdAt", direction === "next" ? "desc" : "asc")
        .startAfter(startPoint)
        .limit(this.pageLimit)
        .get()
        .then(docs => {
          const commuitiesArr = [];
          docs.forEach(doc => {
            const docObj = doc.data();
            if (docObj.admin) commuitiesArr.push({ ...docObj, id: doc.id });
          });
          return direction === "next" ? commuitiesArr : commuitiesArr.reverse();
        })
    );
  },
  _getCommunityByID(communityId) {
    if (!communityId) return Promise.reject("no connunity ID");
    const db = firebase.firestore();
    return db
      .collection("communities")
      .doc(communityId)
      .get()
      .then(doc => {
        return { ...doc.data(), id: doc.id };
      });
  },
  _getCommunityPosts(communityId, startPoint = new Date(), direction = "next") {
    if (!communityId) return Promise.reject("no connunity ID");
    const db = firebase.firestore();
    return db
      .collection("posts")
      .where("communityId", "==", communityId) //AQOxYfUJNP0rwuiQzDjP
      .orderBy("createdAt", direction === "next" ? "desc" : "asc")
      .startAfter(startPoint)
      .limit(this.pageLimit)
      .get()
      .then(docs => {
        const postsArr = [];
        docs.forEach(doc => {
          const docObj = doc.data();
          postsArr.push({ ...docObj, id: doc.id });
        });
        return direction === "next" ? postsArr : postsArr.reverse();
      });
  },
  _deleteCommunity(communityId) {
    // mocking success delete
    return Promise.resolve({ result: "ok" });
  },
  /**
   *  Only for dev for fake posts
   *  will be useless in production
   */
  _getPosts4Dev(communityId, startPoint = new Date(), direction = "next") {
    const db = firebase.firestore();
    return db
      .collection("posts")
      .orderBy("createdAt", direction === "next" ? "desc" : "asc")
      .startAfter(startPoint)
      .limit(this.pageLimit)
      .get()
      .then(docs => {
        const postArr = [];
        docs.forEach(doc => postArr.push({ ...doc.data(), id: doc.id }));
        return direction === "next" ? postArr : postArr.reverse();
      });
  },
  statusConverter(status) {
    switch (status) {
      case 0:
        return "pending";
      case 1:
        return "approved";
      case 2:
        return "declined";
      case 3:
        return "archived";
      default:
        console.error("detect unknown community status");
        return "unknown";
    }
  },
  privacyConverter(privacy) {
    switch (privacy) {
      case 0:
        return "private";
      case 1:
        return "public";
      default:
        console.error("detect unknown community privacy");
        return "unknown";
    }
  },
  statusOptions() {
    return [
      {
        value: 0,
        label: "pending"
      },
      {
        value: 1,
        label: "approved"
      },
      {
        value: 2,
        label: "declined"
      },
      {
        value: 3,
        label: "archived"
      }
    ];
  },
  privacyOptions() {
    return [
      {
        value: 0,
        label: "private"
      },
      {
        value: 1,
        label: "public"
      }
    ];
  },
  pageLimit: 30,
  getCommunities(
    status = 1,
    privacy = 1,
    startPoint = new Date().valueOf(),
    direction = "next"
  ) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      status,
      privacy,
      startPoint,
      order: direction === "next" ? "desc" : "asc",
      limit: this.pageLimit
    });
    return fetch(`${host}/api/admin/v1/communities?${querrys}`, requestOptions)
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      })
      .then(communitiesArr => {
        return direction === "next" ? communitiesArr : communitiesArr.reverse();
      });
  },
  getCommunityByID(communityId) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(
      `${host}/api/admin/v1/community/${communityId}`,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  },
  deleteCommunity(communityId, reason) {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "DELETE",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      reason
    });
    return fetch(
      `${host}/api/admin/v1/community/${communityId}?${querrys}`,
      requestOptions
    ).then(response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    });
  },
  getCommunityPosts(communityId, startPoint = new Date(), direction = "next") {
    const host = window.location.protocol + "//" + window.location.host;
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    const querrys = queryString.stringify({
      status: "available",
      communityId,
      startPoint,
      order: direction === "next" ? "desc" : "asc",
      limit: this.pageLimit
    });
    return fetch(`${host}/api/admin/v1/posts?${querrys}`, requestOptions)
      .then(response => {
        if (!response.ok) {
          return Promise.reject(response.status);
        }
        return response.json();
      })
      .then(postsArr => {
        return direction === "next" ? postsArr : postsArr.reverse();
      });
  }
};
